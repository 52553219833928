import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppGlobals } from '../app.global';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class DashboardService {

  baseURLc4life: string;

  private paramSource = new BehaviorSubject(null);
  sharedParam = this.paramSource.asObservable();

  constructor(protected http: HttpClient, private appGlobal: AppGlobals) {

    this.baseURLc4life = this.appGlobal.baseApiUrl;

  }

  clearScaEvent(body, scaId):any {

    return this.http.post(`${this.baseURLc4life}sca-event/clear/${scaId}`,
      body);

  }

  createScaEvent(body):any {

    return this.http.post(`${this.baseURLc4life}sca-event/create`,
      body);

  }

  getNearBySCAEvent(body):any {

    return this.http.post(`${this.baseURLc4life}sca-event/get-nearby`,
      body);

  }

  getAllSCAEvent():any {
    return this.http.get(`${this.baseURLc4life}sca-event/get/non-expired-sca-v2?page=${0}&size=${500}`);

  }

  getSpecificAED(deviceSerialNumber):any {
    return this.http.get(`${this.baseURLc4life}aed/v2/get-specific-cabinet?deviceSerialNumber=${deviceSerialNumber}`);

  }

  getSCAEvent(eventId):any {

    return this.http.get(`${this.baseURLc4life}sca-event/get/${eventId}`);

  }

  getGADetails():any {

    return this.http.get(`${this.baseURLc4life}GA/get/all-ga-users`);

  }

  getGAById(gaId):any {

    return this.http.get(`${this.baseURLc4life}GA/get/ga-user/${gaId}`);

  }

  updateGAById(gaId, body):any {

    return this.http.post(`${this.baseURLc4life}GA/update/ga-info/${gaId}`, body);

  }

  getScaSummary():any {

    return this.http.get(`${this.baseURLc4life}sca-event/get/sca-summary`);

  }

  getAllScaEventsByAgencyId():any {

    return this.http.get(`${this.baseURLc4life}sca-event/get/sca-list`);

  }

  getCabinetAed(body) {

    return this.http.post(`${this.baseURLc4life}aed/v2/cabinet-filter`, body);

  }

  changeParam(param: any[]) {
    console.log(param)
    this.paramSource.next(param)
  }

}
