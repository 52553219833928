<mat-sidenav-container class="tp-container" fullscreen>
  <mat-tab-group
    #tabGroup
    [selectedIndex]="currentTabIndex"
    (selectedTabChange)="onTabChange($event)"
  >
    <mat-tab
      [routerLinkActive]="['is-active']"
      [routerLinkActiveOptions]="{ exact: true }"
      label="{{ 'cfl.cabinetRegistrationComponent.form.Calender' | translate }}"
    >
    </mat-tab>
    <mat-tab
      [routerLinkActive]="['is-active']"
      [routerLinkActiveOptions]="{ exact: true }"
      label="{{
        'cfl.settingsComponent.tab.fobList.label.fobList' | translate
      }}"
    >
    </mat-tab>
    <mat-tab
      [routerLinkActive]="['is-active']"
      [routerLinkActiveOptions]="{ exact: true }"
      label="{{
        'cfl.settingsComponent.tab.inspectionInterval.label.inspectionInterval'
          | translate
      }}"
    >
    </mat-tab>
    <mat-tab
      *ngIf="hasPrivilege(['ROOT_ADMIN'])"
      [routerLinkActive]="['is-active']"
      [routerLinkActiveOptions]="{ exact: true }"
      label="{{ 'cfl.sideNav.menu.remoteUpdate' | translate }}"
    >
    </mat-tab>
    <mat-tab
      *ngIf="hasPrivilege(['ROOT_ADMIN'])"
      [routerLinkActive]="['is-active']"
      [routerLinkActiveOptions]="{ exact: true }"
      label="{{ 'cfl.sideNav.menu.admin' | translate }}"
    >
    </mat-tab>
    <mat-tab
      *ngIf="hasPrivilege(['ACCOUNT_ADMIN', 'ACCOUNT_STANDARD'])"
      [routerLinkActive]="['is-active']"
      [routerLinkActiveOptions]="{ exact: true }"
      label="{{ 'cfl.sideNav.menu.users' | translate }}"
    >
    </mat-tab>
    <mat-tab *ngIf="hasPrivilege(['ROOT_ADMIN'])">
      <ng-template mat-tab-label>
        <span mat-icon-button [matMenuTriggerFor]="menu">
          {{
            "cfl.settingsComponent.tab.language.label.configurations"
              | translate
          }}
        </span>
        <mat-menu #menu="matMenu">
          <a class="outlineNone">
            <span
              (click)="changeSelectedTab()"
              id="EscalationConfiguration"
              class="sub-side-menu-item"
              style="font-size: small"
              >Escalation Process</span
            >
          </a>
        </mat-menu>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
  <router-outlet></router-outlet>
</mat-sidenav-container>
