<div class="main">
  <div class="form-top-margin">
    <div class="heading">{{ "cfl.sideNav.menu.editAgency" | translate }}</div>
  </div>
  <div class="form-top-margin text-center">
    <img src="../assets/images/edit.png" alt="image" />
  </div>
</div>
<div class="form-side-margin editAgency">
  <form [formGroup]="agencyRegistrationForm" class="example-form">
    <div class="row">
      <div class="col-md-6 mt-4">
        <span class="form-field-width">
          <mat-form-field appearance="outline" class="width500">
            <mat-label
              >{{ "cfl.form.dialog.component.name.placeholder" | translate }}*
            </mat-label>
            <input
              matInput
              placeholder="{{
                'cfl.form.dialog.component.name.placeholder' | translate
              }}"
              formControlName="name"
              (keypress)="omitSpecialChar($event)"
              maxlength="20"
            />
          </mat-form-field>
          <mat-error
            *ngIf="
              getagencyRegistrationFormControls.name.invalid &&
              getagencyRegistrationFormControls.name.touched
            "
          >
            {{ getNameErrorMessages() }}
          </mat-error>
        </span>
      </div>
      <div class="col-md-6 mt-4">
        <span class="form-field-width">
          <mat-form-field appearance="outline" class="width500">
            <mat-label>
              {{
                "cfl.agencyRegisterationComponent.form.field.country.placeholder"
                  | translate
              }}*
            </mat-label>
            <mat-select
              [compareWith]="compareFunction"
              formControlName="country"
              (selectionChange)="onChangeCountry()"
            >
              <mat-option
                *ngFor="let country of countries"
                [value]="country.name"
              >
                {{ country.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error
            *ngIf="
              getagencyRegistrationFormControls.country.invalid &&
              getagencyRegistrationFormControls.country.touched
            "
          >
            {{ getselectedcErrorMessages() }}
          </mat-error>
        </span>
      </div>
      <div class="col-md-6 mt-4">
        <span class="form-field-width">
          <mat-form-field appearance="outline" class="width500">
            <mat-label
              >{{
                "cfl.agencyRegisterationComponent.form.field.province.placeholder"
                  | translate
              }}*</mat-label
            >
            <input
              matInput
              placeholder="{{
                'cfl.agencyRegisterationComponent.form.field.province.placeholder'
                  | translate
              }}"
              formControlName="province"
            />
          </mat-form-field>
          <mat-error
            *ngIf="
              getagencyRegistrationFormControls.province.invalid &&
              getagencyRegistrationFormControls.province.touched
            "
          >
            {{ getprovinceErrorMessages() }}
          </mat-error>
        </span>
      </div>
      <div class="col-md-6 mt-4">
        <span class="form-field-width">
          <mat-form-field appearance="outline" class="width500">
            <mat-label
              >{{
                "cfl.agencyRegisterationComponent.form.field.city.placeholder"
                  | translate
              }}*</mat-label
            >
            <input
              matInput
              placeholder="{{
                'cfl.agencyRegisterationComponent.form.field.city.placeholder'
                  | translate
              }}"
              formControlName="city"
            />
          </mat-form-field>
          <mat-error
            *ngIf="
              getagencyRegistrationFormControls.city.invalid &&
              getagencyRegistrationFormControls.city.touched
            "
          >
            {{ getcityErrorMessages() }}</mat-error
          >
        </span>
      </div>
      <div class="col-md-6 mt-4">
        <span class="form-field-width">
          <mat-form-field appearance="outline" class="width500">
            <mat-label
              >{{
                "cfl.agencyRegisterationComponent.form.field.zipCode.placeholder"
                  | translate
              }}*</mat-label
            >
            <input
              matInput
              placeholder="{{
                'cfl.agencyRegisterationComponent.form.field.zipCode.placeholder'
                  | translate
              }}"
              id="zipCode"
              formControlName="zipCode"
              maxlength="10"
            />
          </mat-form-field>
          <mat-error
            *ngIf="
              getagencyRegistrationFormControls.zipCode.invalid &&
              getagencyRegistrationFormControls.zipCode.touched
            "
          >
            {{ getzipCodeErrorMessages() }}
          </mat-error>
        </span>
      </div>
      <div class="col-md-6 mt-4">
        <span class="form-field-width">
          <mat-form-field appearance="outline" class="width500">
            <mat-label
              >{{
                "cfl.agencyRegisterationComponent.form.field.street.placeholder"
                  | translate
              }}*</mat-label
            >
            <input
              matInput
              placeholder="{{
                'cfl.agencyRegisterationComponent.form.field.street.placeholder'
                  | translate
              }}"
              id="street"
              formControlName="street"
              minlength="2"
              maxlength="1000"
            />
          </mat-form-field>
          <mat-error
            *ngIf="
              getagencyRegistrationFormControls.street.invalid &&
              getagencyRegistrationFormControls.street.touched
            "
          >
            {{ getstreetErrorMessages() }}</mat-error
          >
        </span>
      </div>
      <div class="col-md-6 mt-4">
        <span class="form-field-width">
          <mat-form-field appearance="outline" class="width500">
            <mat-label
              >{{
                "cfl.agencyRegisterationComponent.form.field.mailingEmailAddress.placeholder"
                  | translate
              }}*
            </mat-label>
            <input
              matInput
              placeholder="{{
                'cfl.agencyRegisterationComponent.form.field.mailingAddress.placeholder'
                  | translate
              }}"
              id="mailingAdress"
              formControlName="mailingAdress"
              minlength="2"
              maxlength="320"
            />
          </mat-form-field>
          <mat-error
            *ngIf="
              getagencyRegistrationFormControls.mailingAdress.invalid &&
              getagencyRegistrationFormControls.mailingAdress.touched
            "
          >
            {{ getmailingAdressErrorMessages() }}
          </mat-error>
        </span>
      </div>
      <div class="col-md-6 mt-4">
        <span class="form-field-width">
          <mat-form-field appearance="outline" class="width500">
            <mat-label
              >{{
                "cfl.agencyRegisterationComponent.form.field.phone.placeholder"
                  | translate
              }}*</mat-label
            >
            <input
              matInput
              placeholder="{{
                'cfl.agencyRegisterationComponent.form.field.phone.placeholder'
                  | translate
              }}"
              minlength="6"
              id="phoneNumber"
              formControlName="phoneNumber"
              (keypress)="numberOnly($event)"
              (input)="phoneNumberChanged()"
            />
          </mat-form-field>
          <mat-error
            *ngIf="
              getagencyRegistrationFormControls.phoneNumber.invalid &&
              getagencyRegistrationFormControls.phoneNumber.touched
            "
          >
            {{ getphoneNumberErrorMessages() }}
          </mat-error>
        </span>
      </div>
      <div class="col-md-6 mt-4">
        <span class="form-field-width">
          <mat-form-field appearance="outline" class="width500">
            <mat-label
              >{{
                "cfl.agencyRegisterationComponent.form.field.fax.placeholder"
                  | translate
              }}*</mat-label
            >
            <input
              matInput
              placeholder="{{
                'cfl.agencyRegisterationComponent.form.field.fax.placeholder'
                  | translate
              }}"
              id="faxNumber"
              formControlName="faxNumber"
              (keypress)="numberOnly($event)"
              (input)="faxNumberChanged()"
            />
          </mat-form-field>
          <div class="errorDivFormField">
            <mat-error
              *ngIf="
                getagencyRegistrationFormControls.faxNumber.invalid &&
                getagencyRegistrationFormControls.faxNumber.touched
              "
            >
              {{ getfaxNumberErrorMessages() }}
            </mat-error>
          </div>
        </span>
      </div>
      <div class="col-md-6 mt-4">
        <span class="form-field-width">
          <mat-form-field appearance="outline" class="width500">
            <mat-label
              >{{
                "cfl.agencyRegisterationComponent.form.field.website.placeholder"
                  | translate
              }}*</mat-label
            >
            <input
              matInput
              placeholder="{{
                'cfl.agencyRegisterationComponent.form.field.website.placeholder'
                  | translate
              }}"
              id="website"
              formControlName="website"
              maxlength="255"
            />
          </mat-form-field>
          <mat-error
            *ngIf="
              getagencyRegistrationFormControls.website.invalid &&
              getagencyRegistrationFormControls.website.touched
            "
          >
            {{ getwebsiteErrorMessages() }}</mat-error
          >
        </span>
      </div>
      <div class="col-md-6 mt-4">
        <span class="form-field-width">
          <mat-form-field appearance="outline" class="width500">
            <mat-label>{{ "cfl.SelectThresholdOfDays" | translate }}</mat-label>
            <mat-select
              id="thresholdOfDaysToNotify"
              formControlName="thresholdOfDaysToNotify"
            >
              <!-- <mat-option
                >{{
                  "cfl.agencyEditComponent.option.none" | translate
                }}*</mat-option
              > -->
              <mat-option value="30">30</mat-option>
              <mat-option value="60">60</mat-option>
              <mat-option value="90">90</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error
            *ngIf="
              getagencyRegistrationFormControls.thresholdOfDaysToNotify
                .invalid &&
              getagencyRegistrationFormControls.thresholdOfDaysToNotify.touched
            "
          >
            {{ getThresholdOfDaysToNotifyError() }}</mat-error
          >
        </span>
      </div>
      <div class="col-md-6 mt-4">
        <span class="form-field-width">
          <mat-form-field appearance="outline" class="width500">
            <mat-label
              >{{
                "cfl.agencyRegisterationComponent.form.field.tob.placeholder"
                  | translate
              }}*</mat-label
            >
            <mat-select id="selectedtob" formControlName="selectedtob">
              <mat-option>None</mat-option>
              <mat-option value="option1">{{
                "cfl.agencyEditComponent.option.type1" | translate
              }}</mat-option>
              <mat-option value="option2">{{
                "cfl.agencyEditComponent.option.type2" | translate
              }}</mat-option>
              <mat-option value="option3">{{
                "cfl.agencyEditComponent.option.type3" | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error
            *ngIf="
              getagencyRegistrationFormControls.selectedtob.invalid &&
              getagencyRegistrationFormControls.selectedtob.touched
            "
          >
            {{ getselectedtobErrorMessages() }}
          </mat-error>
        </span>
      </div>
      <div class="col-md-6 mt-4">
        <span class="form-field-width">
          <mat-form-field appearance="outline" class="width500">
            <mat-label
              >{{
                "cfl.agencyRegisterationComponent.form.field.jcountry.placeholder"
                  | translate
              }}*</mat-label
            >
            <mat-select
              [compareWith]="compareFunction"
              id="agencyJurisdictionCountry"
              formControlName="agencyJurisdictionCountry"
              (selectionChange)="onChangeJurisdicationCountry($event)"
            >
              <mat-option
                *ngFor="let country of countries"
                [value]="country.iso2"
              >
                {{ country.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error
            *ngIf="
              getagencyRegistrationFormControls.agencyJurisdictionCountry
                .invalid &&
              getagencyRegistrationFormControls.agencyJurisdictionCountry
                .touched
            "
          >
            {{ getselectedCountryErrorMessages() }}</mat-error
          >
        </span>
      </div>
      <div class="col-md-6 mt-4">
        <span class="form-field-width">
          <mat-form-field appearance="outline" class="width500">
            <mat-label
              >{{
                "cfl.agencyRegisterationComponent.form.field.jcity.placeholder"
                  | translate
              }}*</mat-label
            >
            <mat-select
              formControlName="agencyJurisdictionCity"
              (selectionChange)="onChangeJurisdicationCity($event)"
            >
              <mat-option
                *ngFor="let city of jurisdictionCities"
                [value]="city.name"
              >
                {{ city.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error
            *ngIf="
              getagencyRegistrationFormControls.agencyJurisdictionCity
                .invalid &&
              getagencyRegistrationFormControls.agencyJurisdictionCity.touched
            "
          >
            {{ getagencyJurisdictionCityErrorMessages() }}
          </mat-error>
        </span>
      </div>
      <div class="col-md-6 mt-4">
        <div class="row">
          <div class="col-md-4" class="form-top-margin">
            {{
              "cfl.agencyRegisterationComponent.form.field.logo.placeholder"
                | translate
            }}*
            <input
              matSuffix
              type="file"
              (change)="onFileSelected($event)"
              formControlName="logo"
            />
            <mat-error *ngIf="errorFileSize">{{
              "cfl.error.fileSize" | translate
            }}</mat-error>
            <mat-error *ngIf="errorFileType">{{
              "cfl.error.fileType" | translate
            }}</mat-error>
          </div>
          <div class="col-md-8">
            <div
              style="padding-left: 0px; cursor: pointer"
              class="form-top-margin"
            >
              <a>
                <em (click)="openModal()" class="material-icons">
                  picture_in_picture
                </em></a
              >&nbsp;
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 mt-4"></div>
    </div>
  </form>
  <div class="form-top-margin text-center mt-3">
    <button
      mat-raised-button
      (click)="onSubmit()"
      class="primary-button center"
    >
      {{ "cfl.form.submit" | translate }}
    </button>
  </div>
</div>
<br /><br />
