import { EventEmitter, Injectable } from "@angular/core";
import { IFilter } from "../../shared/Interface/IFilter";

@Injectable({
  providedIn: "root",
})
export class FilterService {
  constructor() {}
  filterEmitter: EventEmitter<Object> = new EventEmitter<Object>();
  removeFilter: EventEmitter<Object> = new EventEmitter<Object>();
  selectedFilter = [];
  dashboardFilters: IFilter;
  aedData = [];
  filteredData = [];
}
