import {
  Component,
  OnInit,
  NgZone,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ElementRef,
  EventEmitter,
} from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Subscription, from } from "rxjs";
import { Map, latLngBounds, LatLng, icon, marker, tileLayer } from "leaflet";
import { MarkerClusterGroup } from "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet/dist/leaflet.css";
import { AuthService } from "../core/services/auth.service";
import { AlertService } from "../core/services/alert.service";
import { DashboardService } from "./dashboard.service";
import { AgencyService } from "../agency/agency.service";
import { AedService } from "../aed/aedservice";
import { SharedService } from "../core/services/shared.service";
import { DeviceDetailComponent } from "../device-detail/device-detail.component";
import { PopupAEDsComponent } from "./popup-aeds/popup-aeds.component";
import { PopupsearchComponent } from "./popupsearch/popupsearch.component";
import { SharedInitialMapService } from "../core/services/shared-initial-map.service";
import { HandleEmitterService } from "../core/services/handle-emitter.service";
import { IFilter } from "../shared/Interface/IFilter";
import { Filter } from "../shared/model/Filter";
import { SharedMapService } from "../core/services/shared-map.service";
import * as moment from "moment";
import * as $ from "jquery";
import { DashboardFilterComponent } from "../shared/modal/dashboard-filter/dashboard-filter.component";
import { FilterMenuComponent } from "./filter-menu/filter-menu.component";
import { Router } from "@angular/router";
import { UserService } from "../settings/users/user.service";
import { FilterService } from "../core/services/filter.service";
import {
  Namings,
  CabinetTypes,
  AedState,
  CabinetStates,
  doorStates,
} from "../namings";
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit, OnDestroy {
  @Input() _postsArray: Array<any> = [];
  map;
  markerIcon;
  markerAed;
  endTime;
  agencyLocation;
  searching;
  markerCluster;
  shadowIcons;
  firstCabinet = true;
  onchangeMap = false;
  RealTimeAction = false;
  filterHover = false;
  isFilter = false;
  ngxLoading = false;
  path = "../assets/images/world.png";
  Collection: any;
  CabinetNumber: any;
  SpecificIndexAED: number;
  MarkerIconAED: string;
  form: FormGroup;
  subscription: Subscription;
  specificAED: any = [];
  CheckAedState = [];
  CheckCabinetState = [];
  CheckWarning = [];
  CheckAccessible = [];
  filterCabLocationsArray: any = [];
  colour: any = [];
  ClusterAEDInformation: Array<any> = [];
  ClassAEDInformation: Array<any> = [];
  cabLocationsArray: any = [];
  AedStates: Array<any> = [
    { name: AedState.ready, value: AedState.ready, id: "02" },
    { name: AedState.notready, value: AedState.notready, id: "03" },
    { name: AedState.noaed, value: AedState.noaed, id: "04" },
  ];
  CabinetState: Array<any> = [
    { name: CabinetStates.enabled, value: CabinetStates.enabled, id: "05" },
    { name: CabinetStates.disabled, value: CabinetStates.disabled, id: "06" },
    {
      name: CabinetStates.temporary_disabled,
      value: CabinetStates.temporary_disabled,
      id: "07",
    },
    {
      name: CabinetStates.decomissioned,
      value: CabinetStates.decomissioned,
      id: "08",
    },
  ];
  Warning: Array<any> = [
    { name: "TRUE", value: "true", id: "10" },
    { name: "FALSE", value: "false", id: "11" },
  ];
  Accessible: Array<any> = [
    { name: "ACCESSIBLE", value: "true", id: "13" },
    { name: "NOT ACCESSIBLE", value: "false", id: "14" },
  ];
  doorStates: any = [
    { id: 0, name: doorStates.all, friendlyName: doorStates.all },
    { id: 1, name: doorStates.open, friendlyName: doorStates.open },
    { id: 2, name: doorStates.closed, friendlyName: doorStates.closed },
  ];
  activationStates: any = [
    { id: 0, name: "All" },
    { id: 1, name: "ARMED" },
    { id: 2, name: "DISARMED" },
  ];
  centerCords = {
    lat: +localStorage.getItem("currentLat"),
    lng: +localStorage.getItem("currentLng"),
  };
  bounds = latLngBounds([]);
  startTime = moment(new Date(), "hh:mm:ss a");
  zoomLevel = 10;

  // @Input() _postsArray: Array<any> = [];
  @Output() public found = new EventEmitter<any>();
  @ViewChild("mapContainer") gmap: ElementRef;
  @ViewChild(FilterMenuComponent, { static: false }) child: FilterMenuComponent;

  dashboardFilter: IFilter;
  filter = new Filter();
  searchForm: FormGroup;
  tempPostdat: any[];
  tempAedData: any[];
  constructor(
    private dashboardService: DashboardService,
    private authService: AuthService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private agencyService: AgencyService,
    private aedService: AedService,
    private csc: SharedService,
    private zone: NgZone,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private SharedMapService: SharedMapService,
    private InitialMapservice: SharedInitialMapService,
    private fb: FormBuilder,
    private sharedService: SharedService,
    public filterService: FilterService,
    private emitter: HandleEmitterService,
    public router: Router,
    public userService: UserService,
    public alert: AlertService
  ) {
    this.matIconRegistry.addSvgIcon(
      "menu",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/images/menu.svg"
      )
    );
    this.form = this.fb.group({
      checkArray: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.filterService.dashboardFilters = new Filter();
    this.filterService.selectedFilter = [];
    this.searchForm = this.fb.group({
      textsearch: [""],
    });
    if (localStorage.getItem("agencyId")) {
      this.subscription = this.authService
        .getRealTimeUpdate()
        .subscribe((data: any) => {
          let loginAgency = localStorage.getItem("agencyId");
          let notificationData = data.payload.data();
          if (
            notificationData.message &&
            notificationData.agencyId == loginAgency
          ) {
            this.aedrealTimeReflection(notificationData);
          }
        });
    }

    this.getData();
    this.emitter.ee.subscribe(() => {
      this.getData();
    });

    this.filterService.filterEmitter.subscribe((data) => {
      console.log("this.onchangeMap", this.onchangeMap);
      if (data.filteredData.length == 0) {
        this.alertService.showFailure("No Data");
      }
      this._postsArray = data.filteredData;
      this.map.remove();
      if (this.onchangeMap) {
        this.MapDetailed();
        this.buildCabMarkers();
        return;
      }
      this.MapSimple();
      this.buildCabMarkers();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  openDialog(): void {
    this.dialog.open(DashboardFilterComponent, {
      panelClass: "filter-dialog-container",
      width: "500px",
      position: { top: "30px" },
      data: { aedData: this.filterService.aedData },
    });
  }

  aedrealTimeReflection(aedData) {
    let index = this._postsArray.findIndex(
      (a) => a.aedSerialNumber == aedData.aedSerial
    );
    if (index >= 0) {
      let specificAed = this._postsArray[index];
      let statuses = JSON.parse(aedData.status);
      const qrFlag = specificAed.qrFlag;
      const doorStatus = statuses.doorStatus as string;
      const cabinetType = statuses.cabinetType as string;
      const accessibility = statuses.accessibility;
      const cabStatus = statuses.cabinetStatus as string;
      const aedStatus = statuses.aedStatus as string;
      const warningStatus = statuses.warning as string;
      const forceUpdate = statuses.forceUpdated;

      this.setIcon(
        accessibility,
        doorStatus,
        cabStatus,
        cabinetType,
        aedStatus,
        warningStatus,
        qrFlag,
        forceUpdate
      );

      let markerIcon = icon({
        iconSize: [60, 51],
        iconAnchor: [10, 41],
        popupAnchor: [2, -40],
        shadowAnchor: [4, 62],
        shadowSize: [50, 64],
        iconUrl: this.MarkerIconAED,
        shadowUrl: this.shadowIcons,
      });

      this.ClassAEDInformation[index].setIcon(markerIcon);
      let popupContent = '<ul class="iconListDashboard">';
      this.colour.forEach(
        (path) => (popupContent += `<li><img src="${path}"/></li>`)
      );
      popupContent += "</ul>";
      this.ClassAEDInformation[index].setPopupContent(popupContent);
    }
  }

  getData() {
    this.SharedMapService.currentMessage.subscribe((data: any) => {
      let isServerCall = true;
      this.onchangeMap = data.checked;
      if (this.filterService.selectedFilter.length > 0) {
        this._postsArray = this.filterService.filteredData;
        isServerCall = false;
      }
      this.onChange(data, isServerCall);
    });
    this.sharedService.agencySelectedData = localStorage.getItem("agencyName");
    this.centerCords = {
      lat: +localStorage.getItem("currentLat"),
      lng: +localStorage.getItem("currentLng"),
    };
    setTimeout(() => {
      this.firstCabinet = true;
      // received whenever change is published from firebase
      this.authService.getCabinetPolicies().subscribe((doc: any) => {
        var values = doc?.Device_Serial_Number;
        this.endTime = moment(new Date(), "hh:mm:ss a");
        // so that the deleteAllMarkers() should not be called on first page load
        if (!this.firstCabinet) {
          // if diff>1 than call delete all markers to avoid multiple calls if
          // multiple notification is generated at a time for an aed
          if (this.endTime.diff(this.startTime, "seconds") > 3) {
            this.startTime = this.endTime;
            this.endTime = moment(new Date(), "hh:mm:ss a");
            this.RealTimeAction = true;
            this.CabinetNumber = values;
            this.dashboardService
              .getSpecificAED(this.CabinetNumber)
              .subscribe((res) => {
                this.specificAED = res.body;
                this.buildCabMarkers();
              });
          }
        }
        this.firstCabinet = false;
      });
    }, 10000);
    this.ngxLoading = true;
  }

  //////////////////////////////////////////////////set Icons////////////////////////////////////////////////
  setIcon(
    accessibility,
    doorStatus,
    cabStatus,
    cabinetType,
    aedStatus,
    warningStatus,
    qrFlag,
    forceUpdate
  ) {
    let colour = "../assets/map-icons/";
    this.colour = [];
    let aedMarker = "";
    let shadowIcon = "";

    // New icons mapping
    // end
    // if (qrFlag) {
    //   colour = "../assets/map-icons/Qr_flag.svg";
    //   this.MarkerIconAED = aedMarker;
    //   this.colour.push(colour);
    // }
    if (cabStatus === CabinetStates.enabled) {
      if (cabinetType === CabinetTypes.monitored) {
        if (aedStatus === AedState.ready || aedStatus === AedState.present) {
          aedMarker = "../assets/map-icons/C4L_ReadyDas.svg";
          this.MarkerIconAED = aedMarker;
          if (forceUpdate) {
            colour = "../assets/map-icons/Ready_ForceUpdate.svg";
            this.colour.push(colour);
          }

          // if (warningStatus) {
          //   aedMarker = "../assets/map-icons/warning.svg";
          //   this.MarkerIconAED = aedMarker;
          // }
          // if (qrFlag) {
          //   colour = "../assets/map-icons/Qr_flag.svg";
          //   this.colour.push(colour);
          // }

          // if (qrFlag) {
          //   colour = "../assets/map-icons/Qr_flag.svg";
          //   this.colour.push(colour);
          //   this.MarkerIconAED = aedMarker;
          // }

          // if (qrFlag) {
          //   shadowIcon = "../assets/map-icons/animated.gif";
          //   this.shadowIcons = shadowIcon;
          //   aedMarker = "../assets/map-icons/C4L_ReadyDas.svg";
          //   this.MarkerIconAED = aedMarker;
          //   colour = "../assets/map-icons/Qr_flag.svg";
          //   this.colour.push(colour);
          // } else {
          //   aedMarker = "../assets/map-icons/C4L_ReadyDas.svg";
          //   this.MarkerIconAED = aedMarker;
          // }
        } else if (aedStatus === AedState.notready) {
          aedMarker = "../assets/map-icons/Not_ReadyDas.svg";
          this.MarkerIconAED = aedMarker;
          if (forceUpdate) {
            colour = "../assets/map-icons/NotReady_ForceUpdate.svg";
            this.colour.push(colour);
          }
          // if (qrFlag) {
          //   colour = "../assets/map-icons/Qr_flag.svg";
          //   this.colour.push(colour);
          // }
        } else if (aedStatus === AedState.noaed) {
          aedMarker = "../assets/map-icons/C4l_Missing.svg";
          this.MarkerIconAED = aedMarker;

          if (forceUpdate) {
            colour = "../assets/map-icons/Missing_ForceUpdate.svg";
            this.colour.push(colour);
          }
        }
      } else {
        aedMarker = "../assets/map-icons/NonC4l_Ready.svg";
        this.MarkerIconAED = aedMarker;
        this.shadowIcons = "";
      }
      if (qrFlag) {
        colour = "../assets/map-icons/Qr_flag.svg";
        this.colour.push(colour);
      }
      if (doorStatus === "OPEN") {
        colour = "../assets/map-icons/Door_Open.svg";
        this.colour.push(colour);
      }
      if (!accessibility) {
        colour = "../assets/map-icons/In_Accessible.svg";
        this.colour.push(colour);
      }
      if (warningStatus) {
        colour = "../assets/map-icons/warningIcon.svg";
        this.colour.push(colour);
      }
    } else if (
      cabStatus === CabinetStates.disabled ||
      cabStatus === CabinetStates.decomissioned ||
      cabStatus === CabinetStates.temporary_disabled
    ) {
      aedMarker = "../assets/map-icons/disable.svg";
      this.MarkerIconAED = aedMarker;
      this.shadowIcons = "";
    }
  }

  fetchAgencyCabMarkers() {
    let agencyId = localStorage.getItem("agencyId");
    this.aedService.getAedsByAgencyID(agencyId).subscribe(
      (data: any) => {
        this.cabLocationsArray = [];
        // this.agencyService.setMonitoredAed(0);
        // this.agencyService.setNonMonitoredAed(0);
        data.body.forEach((d) => {
          this.cabLocationsArray.push({
            lat: d.latitude,
            lng: d.longitude,
            aedStatus: d.aedStatus,
            aedSerialNumber: d.aedSerialNumber,
            cabinetType: d.cabinetType,
            cabStatus: d.cabinetStatus,
            warningStatus: d.warningStatus,
            accessibilityStatus: d.accessibleStatus,
            doorStatus: d.doorStatus,
            forceUpdate: d.forceUpdate,
            friendlyName: d.friendlyName,
            qrFlag: d.qrFlag,
            cabinetSerial: d.cabinetSerial,
          });
        });
        this.ngxLoading = false;
        this._postsArray = this.cabLocationsArray;
        this.csc.getAllAeds(this._postsArray);
        this.tempAedData = this._postsArray;
        this.buildCabMarkers();
      },
      () => {
        this.ngxLoading = false;
        this.csc.getAllAeds(this._postsArray);
      }
    );
  }

  setMapView(location) {
    this.ngxLoading = false;
    this.agencyLocation = location;
    this.map.setView(new LatLng(location.latitude, location.longitude));
  }

  //////////////////////////////////////////////////setting markers////////////////////////////////////////////////
  buildCabMarkers() {
    this.map.maxZoom = 10;
    this.markerCluster = new MarkerClusterGroup({
      spiderfyOnMaxZoom: false,
      removeOutsideVisibleBounds: true,
    });
    const qrFlag = this.specificAED.qrFlag;
    this.ClassAEDInformation = [];
    if (this.RealTimeAction === true) {
      this.realTimeRefliction();
    } else {
      for (let i = 0; i < this._postsArray?.length; i++) {
        const aedSerialNumber = this._postsArray[i].aedSerialNumber as number;
        const cabinetType = this._postsArray[i].cabinetType as string;
        const lat = this._postsArray[i].lat as number;
        const lng = this._postsArray[i].lng as number;
        const doorStatus = this._postsArray[i].doorStatus as string;
        const accessibility = this._postsArray[i].accessibilityStatus;
        const cabStatus = this._postsArray[i].cabStatus as string;
        const aedStatus = this._postsArray[i].aedStatus as string;
        const warningStatus = this._postsArray[i].warningStatus as string;
        const forceUpdate = this._postsArray[i].forceUpdate;
        const qrFlag = this._postsArray[i].qrFlag;
        let Aedhovertext =
          "Serial: " +
          aedSerialNumber +
          "\nF-name: " +
          this._postsArray[i].friendlyName;
        this.setIcon(
          accessibility,
          doorStatus,
          cabStatus,
          cabinetType,
          aedStatus,
          warningStatus,
          qrFlag,
          forceUpdate
        );
        this.markerIcon = icon({
          iconSize: [60, 51],
          iconAnchor: [10, 41],
          popupAnchor: [2, -40],
          iconUrl: this.MarkerIconAED,
        });

        this.markerAed = marker(new LatLng(lat, lng), {
          title: Aedhovertext,
          icon: this.markerIcon,
        });

        if (this.colour !== "") {
          let popupContent = '<ul class="iconListDashboard">';
          this.colour.forEach(
            (path) => (popupContent += `<li><img src="${path}"/></li>`)
          );
          popupContent += "</ul>";
          this.markerAed.bindPopup(popupContent, {
            closeOnClick: false,
            autoClose: false,
            closeButton: false,
            closeOnEscapeKey: false,
            autoPan: false,
          });

          this.bounds.extend([lat, lng]);
          this.markerAed.on("add", function (e) {
            e.target.openPopup();
          });
        }

        this.markerAed.on("click", function (e) {
          e.target.openPopup();
        });

        this.markerAed.on("click", (data) => {
          let aedSerialNumber = data.target.options.title
            .split("\n")[0]
            .split("Serial:")[1]
            .trim();
          this.showAEDDetails(aedSerialNumber);
        });
        this.ClassAEDInformation.push(this.markerAed);
        this.markerCluster.addLayer(this.markerAed);
      }

      this.markerCluster.on("clusterclick", (a) => {
        if (a.layer._zoom >= 18) {
          a.layer.zoomToBounds({ padding: [20, 20] });
          this.ShowPopupAEDs(a);
        }
      });

      this.map.addLayer(this.markerCluster);
      let coordinates = this.getagencyMapCordinates();
      console.log("coordinates----------------", coordinates);
      this.setMapView(coordinates);

      this.filterService.aedData =
        this.filterService.selectedFilter.length == 0
          ? this._postsArray
          : this.filterService.aedData;
    }
  }

  getagencyMapCordinates() {
    let coordinates = {
      latitude: localStorage.getItem("currentLat"),
      longitude: localStorage.getItem("currentLng"),
    };
    return coordinates;
  }

  ///////////////////////////////////////////////// RealTime Data and Icons Reflection////////////////////////////////////////////////
  realTimeRefliction() {
    const qrFlag = this.specificAED.qrFlag;
    this.SpecificIndexAED = this._postsArray.findIndex(
      (index) => index.CabinetSerialNumber === this.CabinetNumber
    );
    const doorStatus = this.specificAED.doorStatus as string;
    const cabinetType = this.specificAED.cabinetType as string;
    const accessibility = this.specificAED.inAccessible as string;
    const cabStatus = this.specificAED.cabinetState as string;
    const aedStatus = this.specificAED.aedStatus as string;
    const warningStatus = this.specificAED.warning as string;
    const forceUpdate = this.specificAED.forceUpdate;
    this.setIcon(
      accessibility,
      doorStatus,
      cabStatus,
      cabinetType,
      aedStatus,
      warningStatus,
      qrFlag,
      forceUpdate
    );

    if (qrFlag === false) {
      //Bind pop up hide
      this.ClassAEDInformation[this.SpecificIndexAED].unbindPopup();
      this.map.closePopup();
      //end hide popup

      let popupContent = '<ul class="iconListDashboard">';
      this.colour.forEach(
        (path) => (popupContent += `<li><img src="${path}"/></li>`)
      );
      popupContent += "</ul>";
      this.ClassAEDInformation[this.SpecificIndexAED]
        .bindPopup(popupContent, {
          closeOnClick: false,
          autoClose: false,
          closeButton: false,
          closeOnEscapeKey: false,
        })
        .openPopup();
      this.ClassAEDInformation[this.SpecificIndexAED].on("add", function (e) {
        e.target.openPopup();
      });
      this.ClassAEDInformation[this.SpecificIndexAED].on("click", function (e) {
        e.target.openPopup();
      });

      setTimeout(() => {
        const markerIcon2 = icon({
          iconSize: [60, 51],
          iconAnchor: [10, 41],
          popupAnchor: [2, -40],
          shadowAnchor: [4, 62],
          shadowSize: [50, 64],
          iconUrl: this.MarkerIconAED,
          shadowUrl: this.shadowIcons,
        });

        this.ClassAEDInformation[this.SpecificIndexAED].setIcon(markerIcon2);
      }, 5000);
      setTimeout(() => {
        this.ClassAEDInformation[this.SpecificIndexAED].unbindPopup();
        this.map.closePopup();
        this.markerAed.remove().shadowUrl;
        const markerIcon2 = icon({
          iconSize: [60, 51],
          iconAnchor: [10, 41],
          iconUrl: "../assets/map-icons/disable.svg",
        });
        this.ClassAEDInformation[this.SpecificIndexAED].setIcon(markerIcon2);
      }, 10000);
    } else {
      const markerIcon2 = icon({
        iconSize: [60, 51],
        iconAnchor: [10, 41],
        popupAnchor: [2, -40],
        // specify the path here
        iconUrl: this.MarkerIconAED,
      });
      this.ClassAEDInformation[this.SpecificIndexAED].unbindPopup();
      this.ClassAEDInformation[this.SpecificIndexAED].setIcon(markerIcon2);
      this.map.closePopup();
      if (this.colour !== "") {
        let popupContent = '<ul class="iconListDashboard">';
        this.colour.forEach(
          (path) => (popupContent += `<li><img src="${path}"/></li>`)
        );
        popupContent += "</ul>";
        this.ClassAEDInformation[this.SpecificIndexAED]
          .bindPopup(popupContent, {
            closeOnClick: false,
            autoClose: false,
            closeButton: false,
            closeOnEscapeKey: false,
          })
          .openPopup();

        this.ClassAEDInformation[this.SpecificIndexAED].on("add", function (e) {
          e.target.openPopup();
        });
        this.ClassAEDInformation[this.SpecificIndexAED].on(
          "click",
          function (e) {
            e.target.openPopup();
          }
        );
      }

      this.markerCluster.addLayer(
        this.ClassAEDInformation[this.SpecificIndexAED]
      );
    }
    this.markerCluster.on("clusterclick", (a) => {
      if (a.layer._zoom >= 18) {
        this.ShowPopupAEDs(a);
      }
    });
    (this._postsArray[this.SpecificIndexAED].name = this.specificAED.name),
      (this._postsArray[this.SpecificIndexAED].lat = this.specificAED.latitude),
      (this._postsArray[this.SpecificIndexAED].lng =
        this.specificAED.longitude),
      (this._postsArray[this.SpecificIndexAED].aedStatus =
        this.specificAED.aedStatus),
      (this._postsArray[this.SpecificIndexAED].aedSerialNumber =
        this.specificAED.aedSerialNumber),
      (this._postsArray[this.SpecificIndexAED].cabinetType =
        this.specificAED.cabinetType),
      (this._postsArray[this.SpecificIndexAED].aedState =
        this.specificAED.aedStatus),
      (this._postsArray[this.SpecificIndexAED].cabinetState =
        this.specificAED.cabinetState),
      (this._postsArray[this.SpecificIndexAED].warning =
        this.specificAED.warning),
      (this._postsArray[this.SpecificIndexAED].inaccessibilityStatus =
        this.specificAED.inAccessible),
      (this._postsArray[this.SpecificIndexAED].doorStatus =
        this.specificAED.doorStatus),
      (this._postsArray[this.SpecificIndexAED].activationStatus =
        this.specificAED.activationStatus),
      (this._postsArray[this.SpecificIndexAED].installerEmail =
        this.specificAED.installerEmail),
      (this._postsArray[this.SpecificIndexAED].primaryAdultPadExpiry =
        this.specificAED.primaryAdultPadExpiry),
      (this._postsArray[this.SpecificIndexAED].primaryBatteryExpiry =
        this.specificAED.primaryBatteryExpiry),
      (this._postsArray[this.SpecificIndexAED].primaryChildPadExpiry =
        this.specificAED.primaryChildPadExpiry),
      (this._postsArray[this.SpecificIndexAED].secondaryAdultPadExpiry =
        this.specificAED.secondaryAdultPadExpiry),
      (this._postsArray[this.SpecificIndexAED].secondaryBatteryExpiry =
        this.specificAED.secondaryBatteryExpiry),
      (this._postsArray[this.SpecificIndexAED].secondaryChildPadExpiry =
        this.specificAED.secondaryChildPadExpiry),
      (this._postsArray[this.SpecificIndexAED].aedImage =
        this.specificAED.aedImage),
      (this._postsArray[this.SpecificIndexAED].picOne =
        this.specificAED.picOne),
      (this._postsArray[this.SpecificIndexAED].picTwo =
        this.specificAED.picTwo),
      (this._postsArray[this.SpecificIndexAED].picThree =
        this.specificAED.picThree);
  }

  //////////////////////////////////////////////// Device Click Popup ////////////////////////////////////////////////
  showAEDDetails(aedid) {
    this.zone.run(() => {
      this.dialog.closeAll();
      const deviceDetailDialog = this.dialog.open(DeviceDetailComponent, {
        height: "90%",
        maxWidth: "500px",
        disableClose: true,
        position: { right: "0" },
        // width: '150%',
        data: { aedid },
        panelClass: "custom-modalbox",
      });
    });
  }

  //////////////////////////////////////////////////Show cluster aeds popup////////////////////////////////////////////////
  ShowPopupAEDs(a) {
    console.log("this._postsArray", this._postsArray);
    this.ClusterAEDInformation = [];
    this.zone.run(() => {
      let marker = [];
      marker = a.layer.dragging._marker._markers;
      marker.forEach((element) => {
        console.log("marker", element);
        let aedserial = element.options.title
          .split("\n")[0]
          .toString()
          .split("Serial:")[1]
          .toString()
          .trim();

        var aedInformation = this._postsArray.find(function (post, index) {
          if (post.aedSerialNumber == aedserial) {
            return true;
          }
        });

        this.ClusterAEDInformation.push(aedInformation);

        console.log(this.ClusterAEDInformation);
      });
      let deviceDetailDialog = this.dialog.open(PopupAEDsComponent, {
        panelClass: "popup-aeds-modal",
        height: "70%",
        maxWidth: "400px",

        position: { right: "0" },
        data: { information: this.ClusterAEDInformation },
      });
      deviceDetailDialog.afterClosed().subscribe((x) => {
        deviceDetailDialog.componentInstance.ClusterAED = [];
      });
    });
  }

  //////////////////////////////////////////////////Search popup open////////////////////////////////////////////////
  openSearchPop() {
    this.dialog.open(PopupsearchComponent, {
      minHeight: "320px",
      maxHeight: "600px",
      width: "500px",
      panelClass: "custom-modalbox",
      data: { information: this.map },
    });
  }

  //////////////////////////////////////////////////simple map design////////////////////////////////////////////////
  //maps categories
  // http://leaflet-extras.github.io/leaflet-providers/preview/

  MapSimple() {
    let tiles = tileLayer(
        "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
        {
          attribution: " ",
          noWrap: true,
        }
      ),
      center = new LatLng(this.centerCords.lat, this.centerCords.lng);
    this.map = new Map("map", {
      center: center,
      zoom: 10,
      minZoom: 1,
      layers: [tiles],
    });
    this.InitialMapservice.getInitialMap(this.map);
    // Here the events for zooming and dragging
  }

  //////////////////////////////////////////////////detailed map design////////////////////////////////////////////////
  MapDetailed() {
    let tiles = tileLayer(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          attribution: " ",
          noWrap: true,
        }
      ),
      center = new LatLng(this.centerCords.lat, this.centerCords.lng);

    this.map = new Map("map", { center: center, zoom: 10, layers: [tiles] });
    this.InitialMapservice.getInitialMap(this.map);
  }

  //////////////////////////////////////////////////change map design////////////////////////////////////////////////
  onChange(value, isServerCall) {
    if (this.map) {
      this.map.remove();
    }
    this.onchangeMap = value.checked;
    if (this.onchangeMap) {
      this.MapDetailed();
    } else {
      this.MapSimple();
    }
    isServerCall ? this.fetchAgencyCabMarkers() : this.buildCabMarkers();
  }

  // ////////////////////////////////////////////////filter aed code start ////////////////////////////////////////////////
  removeCheckBox(id) {
    const checkArray: FormArray = this.form.get("checkArray") as FormArray;
    let i: number = 0;

    checkArray.controls.forEach((item: FormControl) => {
      if (item.value.id === id) {
        let value = checkArray.removeAt(i);
        this.CheckAedState.forEach((element) => {
          if (element.value === value) {
            this.CheckAedState.pop();
          }
        });
        this.CheckCabinetState.forEach((element) => {
          if (element.value === value) {
            this.CheckCabinetState.pop();
          }
        });
        this.CheckWarning.forEach((element) => {
          if (element.value === value) {
            this.CheckWarning.pop();
          }
        });
        this.CheckAccessible.forEach((element) => {
          if (element.value === value) {
            this.CheckAccessible.pop();
          }
        });
        return;
      }
      i++;
    });

    this.Collection = checkArray.value;
    $(`#${id}`).prop("checked", false);
  }

  onCheckboxChange(e, id) {
    this.Collection = [];
    this.CheckAedState = [];
    this.CheckCabinetState = [];
    this.CheckWarning = [];
    this.CheckAccessible = [];

    const checkArray: FormArray = this.form.get("checkArray") as FormArray;
    if (e.target.checked) {
      let obj = {
        name: e.target.name,
        id: id,
        value: e.target.value,
      };
      checkArray.push(new FormControl(obj));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value.value == e.target.value) {
          let value = checkArray.removeAt(i);
          this.CheckAedState.forEach((element) => {
            if (element.value === value) {
              this.CheckAedState.pop();
            }
          });
          this.CheckCabinetState.forEach((element) => {
            if (element.value === value) {
              this.CheckCabinetState.pop();
            }
          });
          this.CheckWarning.forEach((element) => {
            if (element.value === value) {
              this.CheckWarning.pop();
            }
          });
          this.CheckAccessible.forEach((element) => {
            if (element.value === value) {
              this.CheckAccessible.pop();
            }
          });

          return;
        }
        i++;
      });
    }
    this.Collection = checkArray.value;
    for (let entry of this.Collection) {
      if (this.AedStates.filter((e) => e.name === entry.name).length > 0) {
        if (!this.CheckAedState.includes(entry.name)) {
          this.CheckAedState.push(entry.name);
        }
      } else if (
        this.CabinetState.filter((e) => e.name === entry.name).length > 0
      ) {
        if (!this.CheckCabinetState.includes(entry.name)) {
          this.CheckCabinetState.push(entry.name);
        }
      } else if (this.Warning.filter((e) => e.name === entry.name).length > 0) {
        if (!this.CheckWarning.includes(entry.name)) {
          this.CheckWarning.push(entry.value);
        }
      } else if (
        this.Accessible.filter((e) => e.name === entry.name).length > 0
      ) {
        if (!this.CheckAccessible.includes(entry.name)) {
          this.CheckAccessible.push(entry.value);
        }
      }
    }
  }

  deleteGroup() {
    let checkArray: FormArray = this.form.get("checkArray") as FormArray;
    checkArray.clear();
    this.ngxLoading = true;
    if (this.Collection != undefined) {
      this.Collection.forEach((element) => {
        $(`#${element.id}`).prop("checked", false);
      });
    }

    this.Collection = [];
    let body: any = {};
    body.agencyId = localStorage.getItem("agencyId");
    this.dashboardService.getCabinetAed(body).subscribe(
      (res: any) => {
        this.ngxLoading = false;
        this._postsArray = [];
        this.filterCabLocationsArray = [];
        res.body.forEach((d) => {
          if (d.cabinetState !== CabinetStates.installation) {
            this.filterCabLocationsArray.push({
              name: d.name,
              lat: d.latitude,
              lng: d.longitude,
              aedStatus: d.aedStatus,
              aedSerialNumber: d.aedSerialNumber,
              CabinetSerialNumber: d.cabinetSerialNumber,
              cabinetType: d.cabinetType,
              aedState: d.aedState,
              cabStatus: d.cabinetState,
              warningStatus: d.warning,
              inaccessibilityStatus: d.inAccessibility,
              doorStatus: d.doorStatus,
              activationStatus: d.activationStatus,
              idNew: d.installerEmail,
              primaryAdultPadExpiry: d.primaryAdultPadExpiry,
              primaryBatteryExpiry: d.primaryBatteryExpiry,
              primaryChildPadExpiry: d.primaryChildPadExpiry,
              secondaryAdultPadExpiry: d.secondaryAdultPadExpiry,
              secondaryBatteryExpiry: d.secondaryBatteryExpiry,
              secondaryChildPadExpiry: d.secondaryChildPadExpiry,
              aedImage: d.aedImage,
              picOne: d.picOne,
              picTwo: d.picTwo,
              picThree: d.picThree,
              vicinityType: d.vicinityType,
              qrCodeNumber: d.qrCodeNumber,
              othernotes: d.othernotes,
              forceUpdate: d.forceUpdate,
              streetAddress: d.streetAddress,
            });
          }
        });
        this.ngxLoading = false;
        this._postsArray = Object(this.filterCabLocationsArray);
        this.csc.getAllAeds(this._postsArray);
        if (this._postsArray?.length === 0) {
          this.alertService.showFailure("No Result Found");
        }
        this.map.remove(this.onchangeMap);
        if (!this.onchangeMap) {
          this.MapSimple();
        } else {
          this.MapDetailed();
        }
        this.buildCabMarkers();
      },
      (err) => {
        this.ngxLoading = false;
        this.alertService.showFailure("No Result Found");
      }
    );
  }

  getFitleredAed() {
    this.isFilter = true;
    this.ClassAEDInformation = [];
    this.RealTimeAction = false;
    //this.Collection = [];
    this.ngxLoading = true;
    let body: any = {};
    body.agencyId = localStorage.getItem("agencyId");
    body.aedStatus = this.CheckAedState;
    body.cabinetState = this.CheckCabinetState;
    body.publiclyAccessible = this.CheckAccessible;
    body.warning = this.CheckWarning;

    this.dashboardService.getCabinetAed(body).subscribe(
      (res: any) => {
        this.ngxLoading = false;
        this._postsArray = [];
        this.filterCabLocationsArray = [];
        res.body.forEach((d) => {
          if (d.cabinetState !== CabinetStates.installation) {
            this.filterCabLocationsArray.push({
              name: d.name,
              lat: d.latitude,
              lng: d.longitude,
              aedStatus: d.aedStatus,
              aedSerialNumber: d.aedSerialNumber,
              CabinetSerialNumber: d.cabinetSerialNumber,
              cabinetType: d.cabinetType,
              aedState: d.aedState,
              cabStatus: d.cabinetState,
              warningStatus: d.warning,
              inaccessibilityStatus: d.inAccessibility,
              doorStatus: d.doorStatus,
              activationStatus: d.activationStatus,
              idNew: d.installerEmail,
              primaryAdultPadExpiry: d.primaryAdultPadExpiry,
              primaryBatteryExpiry: d.primaryBatteryExpiry,
              primaryChildPadExpiry: d.primaryChildPadExpiry,
              secondaryAdultPadExpiry: d.secondaryAdultPadExpiry,
              secondaryBatteryExpiry: d.secondaryBatteryExpiry,
              secondaryChildPadExpiry: d.secondaryChildPadExpiry,
              aedImage: d.aedImage,
              picOne: d.picOne,
              picTwo: d.picTwo,
              picThree: d.picThree,
              vicinityType: d.vicinityType,
              qrCodeNumber: d.qrCodeNumber,
              othernotes: d.othernotes,
              forceUpdate: d.forceUpdate,
              streetAddress: d.streetAddress,
            });
          }
        });
        this.ngxLoading = false;
        this._postsArray = Object(this.filterCabLocationsArray);
        this.csc.getAllAeds(this._postsArray);
        if (this._postsArray?.length === 0) {
          this.alertService.showFailure("No Result Found");
        }
        this.map.remove(this.onchangeMap);
        if (this.onchangeMap === false || this.onchangeMap != true) {
          this.MapSimple();
        } else {
          this.MapDetailed();
        }
        this.buildCabMarkers();
      },
      (err) => {
        this.ngxLoading = false;
        this.alertService.showFailure("No Result Found");
      }
    );
  }
  ////////////////////////////////////////////////// end filter aed code ////////////////////////////////////////////////

  applyFilter(type, value, searchText?) {
    //this._postsArray = this.tempAedData;
    console.log("--------this._postsArray--------", this.tempPostdat);
    this.filter.searchText =
      searchText != undefined ? searchText.trim() : this.filter.searchText;
    Object.keys(this.filter).forEach((f) => {
      if (f == type) {
        let index = this.filter[f].findIndex((i) => i == value);
        if (index < 0) {
          this.filter[f].push(value);
        } else {
          this.filter[f].splice(index, 1);
        }
      }
    });
    this._postsArray = this.filterArray(this.tempAedData, this.filter);
    let defaultfilter = new Filter();
    if (
      Object.values(this.filter).join() == Object.values(defaultfilter).join()
    ) {
      this._postsArray = this.tempAedData;
    }
    console.log("result------------", this._postsArray);
    console.log("filter------------", this.filter);
    this.map.remove();
    this.MapSimple();
    this.buildCabMarkers();
  }

  filterArray(array, filters) {
    let aedData = array;
    if (filters.seachText != "") {
      aedData = array.filter((obj) => {
        let result =
          this.isInclude(obj.friendlyName, filters) ||
          this.isInclude(obj.aedSerialNumber, filters) ||
          this.isInclude(obj.cabinetSerial, filters);
        return result;
      });
    }
    return aedData.filter((x) =>
      Object.keys(filters).every((f) =>
        filters[f].length == 0 || !Array.isArray(filters[f])
          ? []
          : filters[f].some((z) => z == x[f])
      )
    );
  }
  clearFilter() {
    this._postsArray = this.tempAedData;
    this.filter = new Filter();
    this.searchForm.reset();
    this.applyFilter("", "", "");
  }

  isInclude(key, filters) {
    return key.toLowerCase().indexOf(filters.seachText.toLowerCase()) >= 0;
  }
  //////////////////////////////////////////////////nagivate to my profile component////////////////////////////////////////////////
  onMyProfile() {
    this.router.navigate(["./myprofile/changePassword"]);
  }

  //////////////////////////////////////////////// Notification ////////////////////////////////////////////////
  NotificationOpen() {
    this.router.navigate(["/dashboard/notifications"]);
  }
  isExpanded: boolean = true;

  toggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }
}
