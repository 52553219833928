import { BehaviorSubject, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  // eslint-disable-next-line
  emailValidationRegex: any = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  specialCharactersValid: any = /^[A-Za-z0-9 ]+$/;

  //passwordRegex: any = /^(?=.*[A-Z])(?=.*?[0-9]).{8,30}$/; //Minimum 8 characters 1 upper case 1 numeric

  // passwordRegex: any = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,64}$/;

  passwordRegex: any =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,64}$/;

  phoneValidationRegex: any = /^\D?(\d{3})|\D?\D?(\d{3})\D?(\d{4})$/;

  extValidationRegex: any = new RegExp("^[0-9]{1,5}$");

  numbersRegex: any = /.*[a-zA-Z]+.*/;

  onlyNumbersRegex: any = /^[0-9]*$/;

  inputMaxlength = 32;

  countriesSubject = new BehaviorSubject<[]>([]);

  countries$ = this.countriesSubject.asObservable();

  statesSubject = new BehaviorSubject<[]>([]);

  states$ = this.statesSubject.asObservable();

  citiesSubject = new BehaviorSubject<[]>([]);

  cities$ = this.citiesSubject.asObservable();

  public editDataDetails: any = [];
  private messageSource = new BehaviorSubject(this.editDataDetails);
  currentMessage = this.messageSource.asObservable();
  public agencySelectedData: string = "";

  constructor(private http: HttpClient) {}

  getCountries(): Observable<any> {
    const headers = new HttpHeaders({
      "X-CSCAPI-KEY": environment.csc_api_key,
    });
    return this.http.get("https://api.countrystatecity.in/v1/countries", {
      headers: headers,
    });
  }

  getStates(iso2: string): Observable<any> {
    const headers = new HttpHeaders({
      "X-CSCAPI-KEY": environment.csc_api_key,
    });
    return this.http.get(
      `https://api.countrystatecity.in/v1/countries/${iso2}/states`,
      { headers: headers }
    );
  }

  getCitiesByState(iso2State: string, iso2Country: string): Observable<any> {
    const headers = new HttpHeaders({
      "X-CSCAPI-KEY": environment.csc_api_key,
    });
    return this.http.get(
      `https://api.countrystatecity.in/v1/countries/${iso2Country}/states/${iso2State}/cities`,
      { headers: headers }
    );
  }

  getCitiesByCountry(iso2: string): Observable<any> {
    const headers = new HttpHeaders({
      "X-CSCAPI-KEY": environment.csc_api_key,
    });
    return this.http.get(
      `https://api.countrystatecity.in/v1/countries/${iso2}/cities`,
      { headers: headers }
    );
  }

  getCountriesJson() {
    return this.http.get("./assets/countryStatesCitiesJson/countries.json");
  }

  getStatesJson() {
    return this.http.get("./assets/countryStatesCitiesJson/states.json");
  }

  getCitiesJson() {
    return this.http.get("./assets/countryStatesCitiesJson/cities.json");
  }

  getAllAeds(message) {
    this.messageSource.next(message);
  }
}
