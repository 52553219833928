<div class="popupAEDComponent">
  <header>
    <h3><span mat-dialog-close class="closeButton">x</span> AED CLUSTER</h3>
  </header>
  <div class="popupAEDContent">
    <table
      class="popupAEDTable"
      aria-hidden="true"
      role="presentation"
      *ngFor="let aeds of ClusterAED; let i = index"
    >
      <tbody (click)="ShowDetails(i)">
        <tr>
          <td>
            <strong style="text-align: center">{{
              aeds.AedSerialNumber
            }}</strong>
            <img
              class="aedImage"
              src="{{ aeds.Image }}"
              alt="AED-Image"
             
              title="{{ aeds.hoverText }}"
            />
          </td>
          <td>
            <div>
              <img
                class="aedStatusImage"
                src="{{ aeds.Icon }}"
                alt="{{ aeds.Icon }}"
              />
              <img
                *ngIf="aeds.warningStatus"
                src="../../../assets/map-icons/warningIcon.svg"
                alt="AED-Image"
              />
            </div>

            <div class="monitorNonMonitor">
              <table aria-hidden="true" role="presentation">
                <tr>
                  <td class="p-1">
                    <span
                      class="monitorAed nonRedMonitor"
                      [ngClass]="{
                        greenTag: aeds.aedStatus === 'READY',
                        redTag: aeds.aedStatus === 'NOT READY'
                      }"
                    >
                      <span class="monitorNumber">{{
                        aeds.aedStatus
                      }}</span></span
                    >
                  </td>
                  <td>
                    <span
                      [ngClass]="{
                        greenTag: aeds.accessibility == true,
                        redTag: aeds.accessibility == false
                      }"
                    >
                      <span class="monitorNumber">{{
                        aeds.accessibility ? "Location Open" : "Location Closed"
                      }}</span></span
                    >
                  </td>
                </tr>
              </table>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div></div>
</div>
