export enum CabinetTypes {
  monitored = "Monitored",
  Unmonitored = "Unmonitored",
}
export enum AedState {
  ready = "READY",
  notready = "NOT READY",
  noaed = "NO AED",
  unknownstate = "UNKNOWN STATE",
  missing = "NO AED",
  present = "PRESENT",
  all = "All",
}
export enum CabinetStates {
  enabled = "ENABLED",
  disabled = "DISABLED",
  temporary_disabled = "TEMPORARY_DISABLED",
  decomissioned = "DECOMMISSIONED",
  servicing = "SERVICING",
  installation = "INSTALLATION",
  all = "All",
  temporary_disabledView = "TEMPORARY DISABLED"
}
export enum doorStates {
  all = "All",
  open = "OPEN",
  closed = "CLOSED",
  NA = "N/A",
}

export enum Accessibility {
  accessible= "LOCATION OPEN",
  inaccessible= "LOCATION CLOSED",
}

export class Namings {
  constructor() {}
}
