import { Component, OnInit, Inject, Optional } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  Icon,
  icon,
  latLng,
  Layer,
  Map,
  MapOptions,
  marker,
  Marker,
  tileLayer,
  ZoomAnimEvent,
} from "leaflet";

@Component({
  selector: "app-map-modal",
  templateUrl: "./map-modal.component.html",
  styleUrls: ["./map-modal.component.css"],
})
export class MapModalComponent implements OnInit {
  public map: Map;
  public zoom: number;
  public options: MapOptions;
  public layers: any;
  cancelButton: boolean = false;
  buttonVisible: boolean = true;
  markerIcon: Icon;

  constructor(
    public dialogRef: MatDialogRef<MapModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.options = {
      layers: [
        tileLayer(
          "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
          {
            maxZoom: 19,
            minZoom: 3,
            noWrap: true,
          }
        ),
      ],
      zoom: 10,
      center: latLng(this.data.lat, this.data.lng),
    };
    this.layers = [
      marker(latLng(this.data.lat, this.data.lng), {
        draggable: true,
        icon: icon({
          ...Icon.Default.prototype.options,
          iconSize: [60, 51],
          iconUrl: "../../assets/map-icons/marker.svg",
          iconRetinaUrl: "",
          shadowUrl: "",
        }),
      }),
    ];
  }

  ngOnDestroy() {
    this.map.clearAllEventListeners;
    this.map.remove();
  }

  onMapReady(map: Map) {
    this.map = map;
  }

  cancel() {
    this.dialogRef.close();
  }

  createCabinet() {
    this.layers;
    const lat = this.layers[0]._latlng.lat;
    const lng = this.layers[0]._latlng.lng;
    const pos = {
      lat,
      lng,
    };
    this.dialogRef.close(pos);
  }

  // @ViewChild('mapContainer') gmap: ElementRef;

  // cancelButton = false;

  // tempStatus: string;

  // markersArray: any = [];

  // colour: string;

  // buttonVisible = false;

  // markersOnMap: any = [];

  // bounds = new google.maps.LatLngBounds();

  // map;

  // centerCords = {
  //   lat: 0,
  //   lng: 0,

  // };

  // itemValue = '';

  // mapOptions: google.maps.MapOptions = {
  //   center: this.centerCords,
  //   zoom: 15,
  // };

  // marker9 = new google.maps.Marker({
  //   position: this.centerCords,
  //   draggable: false,
  //   animation: google.maps.Animation.DROP,
  // });

  // constructor(
  //   public dialogRef: MatDialogRef<MapModalComponent>,
  //   @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  // ) {

  //   if (data.list == null) {

  //     this.centerCords = {
  //       lat: +this.data.lat,
  //       lng: +this.data.lng,
  //     };

  //     this.marker9.setPosition(this.centerCords);
  //     this.mapOptions.center = this.centerCords;

  //   }

  // }

  // displayCabinets(list) {

  //   list.forEach((d) => {

  //     this.markersOnMap.push({
  //       lat: d.latitude,
  //       lng: d.longitude,
  //       cabStatus: d.cabStatus,
  //       idNew: d.serialNumber,
  //       agencyId: d.agencyId,
  //       aedStatus: d.aedStatus,

  //     });

  //   });

  //   this.addMarkerInfo();
  //   this.setMarker();

  // }

  // addMarkerInfo() {

  //   for (let i = 0; i < this.markersOnMap.length; i += 1) {

  //     if (this.markersOnMap[i].aedStatus === 'Ready'
  //       && this.markersOnMap[i].cabStatus === 'Enable') {

  //       this.colour = 'http://maps.google.com/mapfiles/ms/icons/green-dot.png';
  //       this.tempStatus = 'READY';

  //     } else if (this.markersOnMap[i].cabStatus === 'Out Of Service') {

  //       this.colour = 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png';
  //       this.tempStatus = 'Out Of Service';

  //     } else if (this.markersOnMap[i].aedStatus === 'Not Ready') {

  //       this.colour = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
  //       this.tempStatus = 'NOT READY';

  //     } else if (this.markersOnMap[i].cabStatus === 'Disable') {

  //       this.colour = 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png';
  //       this.tempStatus = 'DISABLE';

  //     } else {

  //       this.colour = 'http://maps.google.com/mapfiles/ms/icons/purple-dot.png';
  //       this.tempStatus = 'ENABLE';

  //     }
  //     const contentString = `<div id="content"><p>Status : ${this.tempStatus
  //       }<br> ID : ${this.markersOnMap[i].idNew}<br>Latitude : ${this.markersOnMap[i].lat}<br>Longitude : ${this.markersOnMap[i].lng}</p></div>`;

  //     const coordinates5 = new google.maps.LatLng(this.markersOnMap[i].lat, this.markersOnMap[i].lng);
  //     const marker = new google.maps.Marker({
  //       position: coordinates5,
  //       draggable: false,
  //       icon: {
  //         url: this.colour,
  //       },
  //     });
  //     const infowindow = new google.maps.InfoWindow({
  //       content: contentString,
  //       maxWidth: 200,
  //     });

  //     marker.addListener('click', function () {

  //       infowindow.open(marker.get('map'), marker);

  //     });
  //     this.markersArray[i] = marker;

  //   }

  // }

  // setMarker() {

  //   for (let i = 0; i < this.markersOnMap.length; i += 1) {

  //     this.markersArray[i].setMap(this.map);
  //     this.bounds.extend(this.markersArray[i].getPosition());

  //   }

  //   this.map.fitBounds(this.bounds);

  // }

  // ngOnInit(): void {

  //   setTimeout(() => {

  //     this.mapInitializer();
  //     this.buttonVisible = true;

  //   }, 10);

  // }

  // mapInitializer() {

  //   this.map = new google.maps.Map(this.gmap.nativeElement,
  //     this.mapOptions);
  //   if (this.data.list == null) {

  //     this.dropMarker();

  //   } else {

  //     this.cancelButton = true;
  //     this.displayCabinets(this.data.list);

  //   }

  // }

  // cancel() {

  //   this.dialogRef.close();

  // }

  // createCabinet() {

  //   const lat = this.map.getCenter().lat();
  //   const lng = this.map.getCenter().lng();
  //   const pos = {
  //     lat,
  //     lng,

  //   };
  //   this.dialogRef.close(pos);

  // }

  // dropMarker() {

  //   this.marker9.setAnimation(google.maps.Animation.DROP);
  //   this.marker9.setMap(this.map);
  //   this.map.addListener('drag', this.markerDrag.bind(this));
  //   this.map.addListener('dragend', this.markerDrag.bind(this));

  // }

  // markerDrag() {

  //   this.marker9.setPosition(this.map.getCenter());

  // }
}
