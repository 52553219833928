import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class DashboardGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate() {
    console.log("dashboard");
    const agencyStatus = localStorage.getItem("agencyStatus");
    const agencyId = localStorage.getItem("agencyId");
    if (!agencyId || agencyStatus !== "ACTIVE") {
      if (agencyStatus === "PENDING_ACTIVATION") {
        this.router.navigate(["/confirm-password"]);
      } else if (agencyStatus === "PENDING_SIGNUP") {
        this.router.navigate(["/agency/agency-registeration"]);
      } else {
        this.router.navigate(["/home"]);
      }
    } else {
      return true;
    }
  }
}
