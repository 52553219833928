import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { LoaderService } from "../services/loader.service";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  pendingRequest: Array<string> = [];

  constructor(private loaderService: LoaderService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.params.get("loader") === "disable") {
      return next.handle(request);
    } else {
      this.pendingRequest.push(request.url);
      this.loaderService.showLoader();
      return next.handle(request).pipe(
        finalize(() => {
          const index = this.pendingRequest.indexOf(request.url);
          if (index > -1) {
            this.pendingRequest.splice(index, 1);
          }
          if (this.pendingRequest.length === 0) {
            this.loaderService.hideLoader();
          }
        })
      );
    }
  }
}
