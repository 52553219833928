<div class="deviceDetailComponent">
  <header>
    <h3>
      <span mat-dialog-close class="closeButton">x</span>
      <img src="../../../assets/images/disabled.svg" alt="AEDStates" /> Disable
      Cabinet
    </h3>
  </header>
  <form [formGroup]="form">
    <div class="deviceDetailContent">
      <table class="deviceMonitoring">
        <tbody>
          <tr>
            <th>CABINET SERIAL NO.</th>
            <td>{{ data.cabinetSerialNumber }}</td>
          </tr>
          <tr>
            <th>Battery Model</th>
            <td>
              <span>{{ data.powerType ? data.powerType : "N/A" }}</span>
            </td>
          </tr>
          <tr>
            <th>CABINET TYPE</th>
            <td>
              <span>{{ data.cabinetType }}</span>
            </td>
          </tr>
          <tr>
            <th>AED SERIAL NO.</th>
            <td>{{ data.aedSerialNumber }}</td>
          </tr>
          <tr>
            <th>AED MODEL</th>
            <td>{{ data.manufacturer }} ({{ data.model }})</td>
          </tr>
          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>
          <tr>
            <th colspan="2" class="pt-0 pb-0">DISABLE</th>
          </tr>
          <tr>
            <td colspan="2">
              <ul class="disableRadioButton" [(ngModel)]="cabinetstate">
                <li>
                  <div class="form-check">
                    <label
                      class="form-check-label"
                      [for]="cabinetstate.temporary_disabled"
                    >
                      <input
                        [id]="cabinetstate.temporary_disabled"
                        class="form-check-input"
                        type="radio"
                        [value]="cabinetstate.temporary_disabled"
                        checked="checked"
                        name="Disabled"
                        formControlName="Disabled"
                      />
                      TEMPORARY DISABLE
                    </label>
                  </div>
                </li>
                <li>
                  <div class="form-check">
                    <label
                      class="form-check-label"
                      [for]="cabinetstate.decomissioned"
                    >
                      <input
                        [id]="cabinetstate.decomissioned"
                        class="form-check-input"
                        type="radio"
                        [value]="cabinetstate.decomissioned"
                        name="Disabled"
                        formControlName="Disabled"
                      />
                      DECOMMISSION
                    </label>
                  </div>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <th colspan="2" class="pt-0 pb-0">REASON</th>
          </tr>
          <tr>
            <td colspan="2">
              <span class="mat-form-field-width">
                <mat-form-field
                  appearance="outline"
                  class="width500"
                  [class.mat-form-field-invalid]="
                    !notes.pristine && notes.touched && notes.invalid
                  "
                >
                  <textarea
                    id="myTextarea"
                    rows="4"
                    matInput
                    placeholder="Type here"
                    formControlName="notes"
                  ></textarea>
                </mat-form-field>
              </span>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="text-right">
              <div class="clearfix">
                <span class="outline" mat-dialog-close>Cancel</span>&nbsp;
                <button
                  class="third-button"
                  [disabled]="!form.valid"
                  (click)="submit()"
                >
                  Submit
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </form>
</div>
