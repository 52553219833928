import { DatePipe } from "@angular/common";
import {
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  NgZone,
  OnDestroy,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { AuthService } from "src/app/core/services/auth.service";
import { HandleEmitterService } from "src/app/core/services/handle-emitter.service";
import { DeviceDetailComponent } from "src/app/device-detail/device-detail.component";

import { AedService } from "../../aed/aedservice";
import { AlertService } from "../../core/services";
import { DeviceService } from "../../device/device.service";
import { CabinetStates, AedState, CabinetTypes } from "src/app/namings";
@Component({
  selector: "app-popup-aeds",
  templateUrl: "./popup-aeds.component.html",
  styleUrls: ["./popup-aeds.component.scss"],
})
export class PopupAEDsComponent implements OnInit, OnDestroy {
  ClusterAED: any = [];
  Icons: any = [];
  Images: any = [];
  subscription: any;
  aedStatus: any = [{ markerImg: "", icon: "" }];

  constructor(
    public fb: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public aedService: AedService,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    public deviceService: DeviceService,
    private alertService: AlertService,
    private zone: NgZone,
    public emitter: HandleEmitterService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    // this.subscription = this.emitter.returnNotification().subscribe((data: any) => {

    //   this.aedrealTimeReflection(data);
    // })
    if (localStorage.getItem("agencyId")) {
      const temp = `notifications/agencydata`;

      this.subscription = this.subscription = this.authService
        .getRealTimeUpdate()
        .subscribe((data: any) => {
          let loginAgency = localStorage.getItem("agencyId");
          let notificationData = data.payload.data();
          if (
            notificationData.message &&
            notificationData.agencyId == loginAgency
          ) {
            // alert(notificationData.message)
            this.aedrealTimeReflection(notificationData);
          }
        });
    }
    this.MappingAED();
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  aedrealTimeReflection(aedData) {
    let clusterIndex = this.ClusterAED.findIndex(
      (a) => a.AedSerialNumber == aedData.aedSerial
    );
    let index = this.data.information.findIndex(
      (a) => a.aedSerialNumber == aedData.aedSerial
    );

    let statuses = JSON.parse(aedData.status);
    const cabinetType = statuses.cabinetType as string;
    const accessibility = statuses.accessibility as string;
    const cabStatus = statuses.cabinetStatus as string;
    const aedStatus = statuses.aedStatus as string;
    let status = this.setIcon(accessibility, cabStatus, cabinetType, aedStatus);
    this.ClusterAED[clusterIndex].Icon = status.aedMarker;
    this.ClusterAED[clusterIndex].aedStatus = statuses.aedStatus;
    this.ClusterAED[clusterIndex].accessibility = statuses.accessibility;
    this.ClusterAED[clusterIndex].Image = this.setImage(cabinetType, aedStatus);

    this.data.information[index].Icon = status.aedMarker;
    this.data.information[index].aedStatus = statuses.aedStatus;
    this.data.information[index].accessibility = statuses.accessibility;
    this.data.information[index].Image = this.setImage(cabinetType, aedStatus);
  }

  setIcon(inAccessibleStatus, cabStatus, cabinetType, aedStatus) {
    let status = {
      color: "",
      aedMarker: "",
      accessible: "",
      accessibleColor: "",
    };
    if (cabStatus != CabinetStates.enabled) {
      status.color = "red";
      status.aedMarker = "../assets/map-icons/disable.svg";
    }

    if (cabinetType === CabinetTypes.monitored) {
      if (aedStatus === AedState.ready || aedStatus === AedState.present) {
        status.color = "green";
        status.aedMarker = "../assets/map-icons/C4L_ReadyDas.svg";
      }
      if (aedStatus === AedState.notready) {
        status.color = "red";
        status.aedMarker = "../assets/map-icons/Not_ReadyDas.svg";
      }
      if (aedStatus === AedState.noaed) {
        status.color = "red";
        status.aedMarker = "../assets/map-icons/C4l_Missing.svg";
      }
    } else {
      status.color = "red";
      status.aedMarker = "../assets/map-icons/NonC4l_Ready.svg";
    }

    if (inAccessibleStatus) {
      status.accessible = "";
      status.accessibleColor = "green";
    } else {
      status.accessible = "../assets/map-icons/In_Accessible.svg";
      status.accessibleColor = "green";
    }
    // if (warningStatus) {
    //   colour = '../assets/map-icons/warningIcon.svg'
    //   this.colour.push(colour)
    //   status.accessible = "../assets/map-icons/In_Accessible.svg";
    //   status.accessibleColor = "green"
    // }

    return status;
  }

  //Mapping all cluster aed in ClusterAED
  MappingAED() {
    this.data.information.forEach((d) => {
      console.log("data------", d);
      if (d.cabinetState != CabinetStates.installation) {
        this.ClusterAED.push({
          AedSerialNumber: d.aedSerialNumber,
          aedStatus: d.aedStatus,
          cabinetType: d.cabinetType,
          accessibility: d.accessibilityStatus,
          Image: this.setImage(d.cabinetType, d.aedStatus),
          Icon: this.ClusterIcons(d),
          doorStatus: d.doorStatus,
          forceUpdate: d.forceUpdate,
          warningStatus: d.warningStatus,
          hoverText: "F-name: " + d.friendlyName,
          //inaccessibilityStatus: d.inaccessibilityStatus,
        });
        // if(d.cabinetType === 'Monitored'){

        // }
        // else{
        //   this.ClusterAED.push({
        //     AedSerialNumber : d.aedSerialNumber,
        //     aedStatus : d.aedStatus,
        //     cabinetType : d.cabinetType,
        //     inacessibilityStatus : d.inaccessibilityStatus,
        //     Image : '../assets/map-icons/NonC4lImage.svg',
        //     Icon :this.ClusterIcons(d),
        //     warningStatus : d.warningStatus,
        //     inaccessibilityStatus : d.inaccessibilityStatus
        //   })
        // }
      }
    });
  }

  setImage(cabinetType, aedStatus) {
    // cabinaetType
    if (cabinetType == CabinetTypes.monitored && aedStatus == AedState.ready) {
      return "../assets/map-icons/monitoredReady.svg";
    } else if (cabinetType == "Monitored" && aedStatus == "NOT READY") {
      return "../assets/map-icons/monitoredNotReady.svg";
    } else if (cabinetType == "Monitored") {
      return "../assets/map-icons/monitoredAed.svg";
    } else if (cabinetType == "Unmonitored") {
      return "../assets/map-icons/nonmonitoredmodal.svg";
    }
  }

  //Cluster Icons mapping on popup
  ClusterIcons(aed) {
    let colour = "../assets/map-icons/";
    let Icon: {};

    if (aed.cabinetType === CabinetTypes.monitored) {
      if (aed.doorStatus === "OPEN") {
        colour = "../assets/map-icons/Door_Open.svg";
        Icon = colour;
      }

      if (aed.warningStatus) {
        colour = "../assets/map-icons/warning.svg";
        Icon = colour;
      }
      if (!aed.accessibility) {
        colour = "../assets/map-icons/In_Accessible.svg";
        Icon = colour;
      }
    } else {
      if (aed.warningStatus) {
        colour = "../assets/map-icons/warningIcon.svg";
        Icon = colour;
      }
      if (!aed.accessibility) {
        colour = "../assets/map-icons/In_Accessible.svg";
        Icon = colour;
      }
    }
    if (
      aed.aedStatus === AedState.ready ||
      aed.aedStatus === AedState.present
    ) {
      colour = "../assets/map-icons/ReadyIcon.svg";
      Icon = colour;
    }
    if (aed.aedStatus === AedState.notready) {
      colour = "../assets/map-icons/Not_ReadyIcon.svg";
      Icon = colour;
    }
    if (aed.aedStatus === AedState.noaed) {
      colour = "../assets/map-icons/AED_MissingIcon.svg";
      Icon = colour;
    }

    console.log(Icon);
    return Icon;
  }

  ShowDetails(a) {
    //dialog.closeAll() -> //Uzair
    this.zone.run(() => {
      const aedInformation = this.data.information[a];
      /* eslint-disable-next-line */
      const deviceDetailDialog = this.dialog.open(DeviceDetailComponent, {
        height: "90%",
        maxWidth: "500px",

        position: { right: "0" },
        // width: '150%',
        data: { aedid: aedInformation.aedSerialNumber },
        panelClass: "custom-modalbox",
      });
    });
  }
}
