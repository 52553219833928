import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class HomeGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate() {
    console.log("home");
    const agencyId = localStorage.getItem("agencyId");
    if (agencyId !== null) {
      this.router.navigate(["/dashboard"]);
    } else {
      return true;
    }
  }
}
