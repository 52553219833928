<div class="filterDashboardWrapper" @rowsAnimation>
  <span mat-dialog-close class="filterCloseButton">
    <img
      src="../../../../assets/revamp-images/icon/closeFilterButton.svg"
      alt="Filter Button"
    />
  </span>
  <!-- <span mat-dialog-close class="filterCloseButton secondaryFilterButton">
    <img
      src="../../../../assets/revamp-images/icon/secondaryFilterButton.svg"
      alt="Secondary Filter Button"
    />
  </span> -->
  <form [formGroup]="searchForm">
    <div class="dashboardFilter">
      <div class="searchBox">
        <mat-form-field class="searchInput">
          <input
            class="filterSearch hide-575"
            type="text"
            matInput
            placeholder="Search by AED"
            formControlName="textsearch"
          />
          <!-- (keyup)="onSelectValue(searchForm.value.textsearch, 'textsearch')" -->
        </mat-form-field>

        <div class="selectFilter">
          <mat-form-field>
            <mat-select
              formControlName="category"
              (selectionChange)="changeCategory($event)"
              placeholder="Select Categories"
            >
              <!-- <mat-option value="" disabled>Select Categories</mat-option> -->
              <mat-option
                *ngFor="let category of allCategories"
                [value]="category"
              >
                {{ category.lbl }}
              </mat-option>
              <!-- <mat-option value="Cabinet Type">Cabinet Type</mat-option>
            <mat-option value="AED State">AED State</mat-option>
            <mat-option value="Cabinet Status">Cabinet Status</mat-option>
            <mat-option value="Alert">Alert</mat-option>
            <mat-option value="Accessible">Accessible</mat-option> -->
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <button
        [disabled]="!searchForm.value.textsearch?.trim()"
        (click)="onSelectValue(searchForm.value.textsearch, 'textsearch')"
        class="dashboardSearchFilter"
      >
        search
      </button>
      <div class="text-center m-2">
      {{
        filterService?.selectedFilter.length == 0
          ? 0
          : filterService.filteredData?.length
      }}
    </div>
      <div class="filterStatus" *ngIf="categoriesValues?.length > 0">
        <div>
          <span *ngFor="let value of categoriesValues">
            <span
              @rowsAnimation
              class="selectedFilterStatus"
              [ngClass]="
                setActiveCategory(value)
                  ? 'activeCategory selectedFilterStatus'
                  : 'selectedFilterStatus'
              "
              (click)="onSelectValue(value, searchForm.value.category)"
              >{{ value.name }}</span
            >
          </span>
        </div>

        <div class="clearfix">&nbsp;</div>
      </div>
      <app-filter-child></app-filter-child>
    </div>
  </form>
</div>
