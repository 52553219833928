import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { AppGlobals } from "../app.global";
import { INotification } from "./INotification";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  public sharedNotificationCount: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  public notificationContent: BehaviorSubject<INotification> =
    new BehaviorSubject<INotification>({
      status: {},
      aedSerial: "",
      count: 0,
      message: "",
      actionStatus: "",
      notificationStatus: "",
    });

  public notificationObs = this.notificationContent.asObservable();

  baseURLc4life: string;
  totalNotifications: number;

  agencyChange = new BehaviorSubject<Boolean>(false);
  agencyNotificationCount = new BehaviorSubject<number>(0);

  setAgencychange(value: Boolean) {
    this.agencyChange.next(value);
  }

  getAgencychange() {
    return this.agencyChange.asObservable();
  }
  setAgencyNotificationCount(count: number) {
    this.agencyNotificationCount.next(count);
  }

  getAgencyNotificationCount() {
    return this.agencyNotificationCount.asObservable();
  }

  constructor(protected http: HttpClient, private appGlobal: AppGlobals) {
    this.baseURLc4life = this.appGlobal.baseApiUrl;
  }

  getallAlarms(
    page,
    size,
    opened = true,
    sortDir = "DESC",
    sort = "createdTime",
    filter = {
      notification: "",
      time: "",
      priority: "",
      action: "",
    }
  ) {
    const agencyId = localStorage.getItem("agencyId");
    return this.http.get(
      `${this.baseURLc4life}alarm/get-all?agencyId=${agencyId}&page=${page}&size=${size}&opened=${opened}&sortDir=${sortDir}&sort=${sort}&message=${filter.notification}&action=${filter.action}`
    );
  }
  // getallAlarmsV2(payload) {
  //   return this.http.get(
  //     `${this.baseURLc4life}alarm/get-all?agencyId=${payload.agencyId}&page=${payload.page}&size=${payload.size}&opened=${payload.opened}&filter=[]&sorted=${payload.sorted}&message=${payload.message}`
  //   );
  // }
  getallAlarmsV2(payload) {
    return this.http.post(`${this.baseURLc4life}alarm/get-all`, payload);
  }
  getallAlarms2(
    page,
    size,
    opened = true,
    sortDir = "DESC",
    sort = "createdTime",
    filter = {
      notification: "",
      time: "",
      priority: "",
      action: "",
    },
    resetCount
  ) {
    const agencyId = localStorage.getItem("agencyId");
    return this.http.get(
      `${this.baseURLc4life}alarm/get-all?agencyId=${agencyId}&page=${page}&size=${size}
      &opened=${opened}&sortDir=${sortDir}&sort=${sort}&message=${filter.notification}
      &action=${filter.action}&resetCount=${resetCount}`,
      { params: { loader: "disable" } }
    );
  }

  getAlarmsTop5() {
    const agencyId = localStorage.getItem("agencyId");
    return this.http.get(
      `${this.baseURLc4life}alarm/get-top-five-alarms?agencyId=${agencyId}`,
      { params: { loader: "disable" } }
    );
  }
  getActiveAlarmCount() {
    const agencyId = localStorage.getItem("agencyId");
    return this.http.get(
      `${this.baseURLc4life}alarm/active-alarms-count?agencyId=${agencyId}`
    );
  }

  updateAlarm(body): any {
    return this.http.post(
      `${this.baseURLc4life}alarm/update-alarm-action`,
      body
    );
  }

  closeInspectionAlarm(body) {
    return this.http.post(
      `${this.baseURLc4life}alarm/close-inspection-alarm`,
      body
    );
  }
  getAlarmCategories() {
    return this.http.get(`${this.baseURLc4life}alarm/get-alarm-categories`);
  }
  getUnseenNotificationCount(agencyId) {
    return this.http
      .get(`${this.baseURLc4life}alarm/get-unseen-notification-count?agencyId=${agencyId}
    `);
  }
}
