import { DatePipe } from "@angular/common";
import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Subscription } from "rxjs";

import { AedService } from "../aed/aedservice";
import { AuthService } from "../core/services/auth.service";
import { AlertService } from "../core/services";
import { HandleEmitterService } from "../core/services/handle-emitter.service";
import { DeviceService } from "../device/device.service";
import { ModalAedComponent } from "../modal-aed/modal-aed.component";
import {
  CabinetStates,
  AedState,
  CabinetTypes,
  doorStates,
  Accessibility,
} from "../namings";
import {
  convertToTemporaryDisabled,
  formatDateToString,
} from "../core/utilities/utils";
@Component({
  selector: "app-device-detail",
  templateUrl: "./device-detail.component.html",
  styleUrls: ["./device-detail.component.scss"],
  providers: [DatePipe],
})
export class DeviceDetailComponent implements OnInit, OnDestroy {
  @Input() public resultGridList: Array<any> = [];

  cabLocationsArray: any = [];
  aedPic;

  IconImage;

  isChecked: string;

  toggle: boolean;

  deviceInformationForm: FormGroup;

  ngxLoading = false;
  secondaryAdultPadExpiry: any;
  PlayVedio = false;

  isMonitored: boolean = true;
  notParentqrFlag: boolean;
  aedSerialNumber;
  subscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<DeviceDetailComponent>,
    public fb: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public aedService: AedService,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    public deviceService: DeviceService,
    private alertService: AlertService,
    private emitter: HandleEmitterService,
    private authService: AuthService
  ) {}
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    // this.subscription = this.emitter.returnNotification().subscribe((data: any) => {
    //   console.log("device detail ")
    //   this.aedrealTimeReflection(data);
    // })
    if (localStorage.getItem("agencyId")) {
      const temp = `notifications/agencydata`;

      this.subscription = this.subscription = this.authService
        .getRealTimeUpdate()
        .subscribe((data: any) => {
          let loginAgency = localStorage.getItem("agencyId");
          let notificationData = data.payload.data();
          if (
            notificationData.message &&
            notificationData.agencyId == loginAgency
          ) {
            // alert(notificationData.message)
            this.aedrealTimeReflection(notificationData);
          }
        });
    }
    this.deviceInformationForm = this.fb.group({
      aedSerialNumber: [""],
      deviceName: [""],
      cabinetType: [""],
      aedState: [""],
      cabinetState: [""],
      warning: [""],
      accessibility: [""],
      location: [""],
      door: [""],
      installedBy: [""],
      activationStatus: [""],
      primaryAdultPadExpiry: ["N/A"],
      primaryBatteryPadExpiry: ["N/A"],
      primaryChildPadExpiry: ["N/A"],
      secondaryAdultPadExpiry: [""],
      secondaryBatteryExpiry: [""],
      secondaryChildPadExpiry: [""],
      aedImage: [""],
      picOne: [""],
      picTwo: [""],
      picThree: [""],
      AramedDisarmed: [""],
      cabinetSerialNumber: [""],
      qrCode: [""],
      reason: [""],
      frontCamera: [""],
    });

    this.getAedInformation();
  }

  aedrealTimeReflection(data) {
    if (data.aedSerial == this.aedSerialNumber) {
      let statuses = JSON.parse(data.status);
      statuses = convertToTemporaryDisabled(statuses);
      this.deviceInformationForm.controls.aedState.setValue(statuses.aedStatus);
      this.deviceInformationForm.controls.door.setValue(statuses.doorStatus);
      if (statuses.warning) {
        this.deviceInformationForm.controls.warning.setValue("YES");
      } else {
        this.deviceInformationForm.controls.warning.setValue("NO");
      }
      this.deviceInformationForm.controls.cabinetState.setValue(
        statuses.cabinetStatus
      );

      if (statuses.armedStatus === "DISARMED") {
        this.isChecked = "Not Armed";
        this.toggle = false;
      } else {
        this.isChecked = "Armed";
        this.toggle = true;
      }
      if (statuses.accessibility === true) {
        this.deviceInformationForm.controls.accessibility.setValue(
          Accessibility.accessible
        );
      } else {
        this.deviceInformationForm.controls.accessibility.setValue(
          Accessibility.inaccessible
        );
      }
    }
  }

  openModal(image) {
    this.dialog.open(ModalAedComponent, {
      disableClose: true,
      data: { pic: image },
    });
  }

  clickEvent() {
    if (this.hasPrivilege(["ACCOUNT_STANDARD"])) return;
    this.ngxLoading = true;
    if (this.isChecked === "Armed") {
      let ActivationStatus = "DISARMED";
      this.deviceService
        .setDisaramedDevices(
          ActivationStatus,
          this.deviceInformationForm.value.cabinetSerialNumber
        )
        .subscribe(
          (res) => {
            this.alertService.showSuccess("Disarmed Successfully");
            this.isChecked = "Not Armed";
            this.ngxLoading = false;
          },
          (err) => {
            this.toggle = false;
            this.ngxLoading = false;
            this.isChecked = "Armed";
            this.alertService.showFailure("Disarmed failed");
          }
        );
    } else {
      let ActivationStatus = "ARMED";
      this.deviceService
        .setAramedDevices(
          ActivationStatus,
          this.deviceInformationForm.value.cabinetSerialNumber
        )
        .subscribe(
          (res) => {
            this.alertService.showSuccess("Armed Successfully");
            this.isChecked = "Armed";
            this.ngxLoading = false;
          },
          () => {
            this.toggle = false;
            this.ngxLoading = false;
            this.isChecked = "Not Armed";
            this.alertService.showFailure("Armed failed");
          }
        );
    }
  }

  getAedInformation() {
    const imageFields = [
      "picOne",
      "picTwo",
      "picThree",
      "qrCode",
      "frontCamera",
      "aedImage",
    ];
    let aedId = this.data.aedid;
    this.aedService
      .getAedsInformationByID(aedId)
      .subscribe(async (data: any) => {
        let aedData = data.body;
        this.notParentqrFlag = aedData.qrFlag;
        aedData.cabinetStatus = convertToTemporaryDisabled(
          aedData.cabinetStatus
        );

        this.deviceInformationForm.patchValue({
          reason: aedData.disabledNotes,
        });
        this.deviceInformationForm.controls.aedSerialNumber.setValue(
          this.data.aedid
        );
        this.deviceInformationForm.controls.cabinetSerialNumber.setValue(
          aedData.cabinetSerialNumber
        );
        this.deviceInformationForm.controls.deviceName.setValue(aedData.name);
        if (aedData.cabinetType === "Monitored") {
          this.deviceInformationForm.controls.cabinetType.setValue("Monitored");
        } else {
          this.deviceInformationForm.controls.cabinetType.setValue(
            "Unmonitored"
          );
        }

        this.deviceInformationForm.controls.aedState.setValue(
          aedData.aedStatus
        );
        //    function func(location,locationDetails,installerLocationDetails){
        //     let newLocal = '';
        //     let newLocals = '';
        //     let newLocalss = '';
        //     if(location!==null){

        //       newLocal = aedData.location
        //  }
        //    if(locationDetails!==null){

        //     newLocals = aedData.location
        //    }
        //    if(installerLocationDetails!==null){

        //     newLocalss = aedData.location
        //    }
        //      let n  = newLocal.concat(',',newLocals,',',newLocalss)
        //      return n;
        //    }
        const locationParts = [
          aedData.location,
          aedData.locationDetails,
          aedData.installerLocationDetails,
        ].filter((part) => !!part); // Filter out null or undefined values

        const locationValue = locationParts.join(",");

        this.deviceInformationForm.controls.location.setValue(locationValue);
        this.deviceInformationForm.controls.cabinetState.setValue(
          aedData.cabinetStatus
        );
        if (aedData.warningStatus === true) {
          this.deviceInformationForm.controls.warning.setValue("YES");
        } else {
          this.deviceInformationForm.controls.warning.setValue("NO");
        }
        this.deviceInformationForm.controls.door.setValue(aedData.doorStatus);
        if (aedData.accessibility === true) {
          this.deviceInformationForm.controls.accessibility.setValue(
            Accessibility.accessible
          );
        } else {
          this.deviceInformationForm.controls.accessibility.setValue(
            Accessibility.inaccessible
          );
        }
        this.deviceInformationForm.controls.installedBy.setValue(
          aedData.installedBy
        );

        if (aedData.cabinetType === "Monitored") {
          this.isMonitored = true;
          this.IconImage = "../assets/map-icons/C4l_Ready.svg";
          this.aedSerialNumber = this.data.aedid;
          this.aedPic = aedData.aedImage;
          if (aedData.aedImage != null) {
            let check = this.aedPic.split(".");
            let CheckValue = check.indexOf("mp4");

            if (CheckValue >= 0) {
              this.PlayVedio = true;
            }
          }
        } else {
          this.isMonitored = false;
          this.IconImage = "../assets/map-icons/NonC4l_Ready.svg";
          this.aedSerialNumber = this.data.aedid;
        }

        //handling null images
        for (const field of imageFields) {
          const imageUrl =
            aedData[field] || "../assets/images/imgUnavailable.png";
          this.deviceInformationForm.controls[field].setValue(imageUrl);
        }

        this.deviceInformationForm.controls.activationStatus.setValue(
          aedData.activationStatus
        );
        if (aedData.activationStatus === "DISARMED") {
          this.isChecked = "Not Armed";
          this.toggle = false;
        } else {
          this.isChecked = "Armed";
          this.toggle = true;
        }

        const primary = [
          "primaryAdultPadExpiry",
          "primaryBatteryPadExpiry",
          "primaryChildPadExpiry",
        ];
        primary.forEach((property) => {
          if (aedData[property] && aedData[property] != "null") {
            this.deviceInformationForm.controls[property].setValue(
              this.datepipe.transform(aedData[property], "MM/dd/yyyy")
            );
          } else {
            this.deviceInformationForm.controls[property].setValue("N/A");
          }
        });

        // this.deviceInformationForm.controls.primaryAdultPadExpiry.setValue(
        //   this.datepipe.transform(aedData.primaryAdultPadExpiry, "MM/dd/yyyy")
        // );

        // this.deviceInformationForm.controls.primaryBatteryExpiry.setValue(
        //   this.datepipe.transform(aedData.primaryBatteryPadExpiry, "MM/dd/yyyy")
        // );

        // this.deviceInformationForm.controls.primaryChildPadExpiry.setValue(
        //   this.datepipe.transform(aedData.primaryChildPadExpiry, "MM/dd/yyyy")
        // );

        // this.deviceInformationForm.controls.secondaryAdultPadExpiry.setValue(
        //   this.datepipe.transform(aedData.secondaryAdultPadExpiry, "MM/dd/yyyy")
        // );

        // this.deviceInformationForm.controls.secondaryBatteryExpiry.setValue(
        //   this.datepipe.transform(aedData.secondaryBatteryExpiry, "MM/dd/yyyy")
        // );

        // this.deviceInformationForm.controls.secondaryChildPadExpiry.setValue(
        //   this.datepipe.transform(aedData.secondaryChildPadExpiry, "MM/dd/yyyy")
        // );
      });
  }
  hasPrivilege(privileges: string[]): boolean {
    return this.authService.hasPrivilege(privileges);
  }

  // onImgError(event: any) {
  //   event.target.src = "../assets/images/ImageUnavailable.png";
  // }

  applyDynamicclass(controlName) {
    const ctrl = this.deviceInformationForm.get(controlName) as FormControl;
    let isSuccess = false;

    if (!ctrl) return null;

    if (controlName === "cabinetType") {
      isSuccess = true;
    }
    if (controlName === "aedState") {
      if (ctrl.value === "READY") {
        isSuccess = true;
      } else {
        isSuccess = false;
      }
    }

    if (controlName === "cabinetState") {
      if (ctrl.value === CabinetStates.enabled) {
        isSuccess = true;
      } else {
        isSuccess = false;
      }
    }

    if (controlName === "accessibility") {
      if (ctrl.value === Accessibility.accessible) {
        isSuccess = true;
      } else {
        isSuccess = false;
      }
    }

    if (controlName === "warning") {
      if (ctrl.value === "NO") {
        isSuccess = true;
      } else {
        isSuccess = false;
      }
    }
    if (
      controlName === "primaryAdultPadExpiry" ||
      controlName === "primaryBatteryPadExpiry" ||
      controlName === "primaryChildPadExpiry"
    ) {
      const currentDate = new Date();
      const inputDate = new Date(ctrl.value);
      inputDate.setHours(23, 59);
      if (inputDate < currentDate) {
        isSuccess = true;
      } else {
        isSuccess = false;
      }
    }

    if (controlName === "door") {
      if (ctrl.value === doorStates.open) {
        isSuccess = false;
      } else {
        isSuccess = true;
      }
    }

    return isSuccess;
  }
}
