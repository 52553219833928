import { IAedState } from "../Interface/IAedState"
import { ICabinetStatus } from "../Interface/ICabinetStatus"
import { IFilter } from "../Interface/IFilter";

export class Filter implements IFilter{
    cabinetType = [];
    aedStatus = [];
    cabStatus= [];
    warningStatus=[];
    accessibilityStatus=[];
    searchText=""
}