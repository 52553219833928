import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { AlertService } from "../core/services/index";
import { AuthService } from "../core/services/auth.service";
import { NotificationsService } from "../notifications/notifications.service";

@Component({
  selector: "app-inspection-interval-modal",
  templateUrl: "./inspection-interval-modal.component.html",
  styleUrls: ["./inspection-interval-modal.component.css"],
})
export class InspectionIntervalModalComponent implements OnInit {
  aedRegistrationForm: FormGroup;
  alarmId: string;
  sbe: string;
  startDate = new Date();
  todayDate: Date = new Date();
  ngxLoading = false;

  constructor(
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private dialogRef: MatDialogRef<InspectionIntervalModalComponent>,
    private alertService: AlertService,
    private notificationsService: NotificationsService
  ) {
    this.alarmId = localStorage.getItem("alarmId");
  }

  ngOnInit() {
    this.aedRegistrationForm = this.fb.group({
      sbe: ["", [Validators.required, Validators.minLength(2)]],
    });
  }

  onSubmit(event) {
    this.ngxLoading = true;
    event.preventDefault();
    const { target } = event;
    this.sbe = target.querySelector("#sbe").value;

    let sbe = this.datepipe.transform(this.sbe, "yyyy-MM-dd");
    sbe += "T00:00:00.00Z";

    const body = {
      alarmId: this.alarmId,
      lastInspectionDate: sbe,
    };

    return this.notificationsService
      .closeInspectionAlarm(body)
      .subscribe(() => {
        // update alarm ok condition
        this.ngxLoading = false;
        this.alertService.showSuccess(" Alarm Closed ");
        this.dialogRef.close("updated");
      });
  }

  getsbeErrorMessages() {
    return this.aedRegistrationForm.controls.sbe.hasError("required")
      ? "You must enter a value"
      : this.aedRegistrationForm.controls.sbe.hasError("minlength")
      ? "Name must be at least 2 characters long"
      : this.aedRegistrationForm.controls.sbe.hasError("pattern")
      ? "No special characters allowed"
      : "";
  }

  get getaedRegistrationFormControls() {
    return this.aedRegistrationForm.controls;
  }
}
