import { Component, OnDestroy, OnInit } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable, Subscription } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { AgencyService } from "../agency/agency.service";
import { AuthService } from "../core/services/auth.service";
import { UserService } from "../settings/users/user.service";
import { Event, NavigationStart, Router } from "@angular/router";
import { AppGlobals } from "../app.global";
import { SharedService } from "../core/services/shared.service";
import { SharedMapService } from "../core/services/shared-map.service";
import { AgencyPopupComponent } from "../blank/agency-popup/agency-popup.component";
import { NotificationsService } from "../notifications/notifications.service";
import { AedState } from "../namings";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, OnDestroy {
  selectedAgency: string;
  cabLocationsArray: any = [];
  _postsArray: Array<any> = [];
  countC4l: number;
  countNonC4l: number;
  text: string;
  path = "../assets/images/world.png";
  isPicture = false;
  agencyPicture: any;
  firstName;
  lastName;
  agencyIdBySpecific: any;
  email;
  role;
  selected = "english";
  buildNo;
  BEBuidlNo;
  togglebtn = true;
  ngxLoading = false;
  Collection = [];
  checked: boolean;
  ShowAll: boolean;
  CheckAedState = [];
  CheckCabinetState = [];
  CheckWarning = [];
  CheckAccessible = [];
  AedStates: string[] = [
    "All AED STATES",
    AedState.ready,
    AedState.notready,
    AedState.noaed,
  ];
  CabinetStates: string[] = [
    "All CABINET STATES",
    "ENABLE",
    "DISABLE",
    "TEMPORARY REPLACEMENT",
    "OUT OF SERVICE",
  ];
  Warning: string[] = ["All WARNING", "TRUE", "FALSE"];
  Accessible: string[] = ["All ACCESSIBLE", "ACCESSIBLE", "NOT ACCESSIBLE"];
  ClassAEDInformation: Array<any> = [];
  RealTimeAction = false;
  filterCabLocationsArray: any = [];
  filterAed = false;
  tabselected = "";
  agencySubscription$: Subscription;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private translation: TranslateService,
    private agencyService: AgencyService,
    public userService: UserService,
    private authService: AuthService,
    private router: Router,
    private appGlobal: AppGlobals,
    public sharedDataService: SharedService,
    private SharedMapService: SharedMapService,
    public notificationsService: NotificationsService
  ) {}

  ngOnInit(): void {
    let agencyId = localStorage.getItem("agencyId");
    this.getSideBarData(agencyId);
    this.setSelectedTab(this.router.url);
    this.getUnseenNotificationCount(agencyId);

    this.buildNo = this.appGlobal.buildNum;
    this.getBEBuildNo();
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.setSelectedTab(event.url);
      }
    });
  }

  getSideBarData(agencyId) {
    this.ngxLoading = true;
    let userInfo: any;
    let sdieBarData = this.agencyService.getSidebar(agencyId).pipe(
      switchMap((res: any) => {
        this.ngxLoading = false;
        if (res.body.userResponseList.length > 1) {
          res.body.userResponseList.forEach((user) => {
            if (user.primaryEmail) {
              this.agencyService.setAgencyName(user.agency);
            } else {
              userInfo = user;
            }
          });
        } else {
          userInfo = res.body.userResponseList[0];
        }

        this.agencyService.setMonitoredAed(res.body.monitoredAedCount);
        this.agencyService.setNonMonitoredAed(res.body.nonMonitoredAedCount);
        this.agencyService.setAgencyPicture(res.body.logo);
        this.userService.userData.firstName = userInfo.firstName;
        this.userService.userData.lastName = userInfo.lastName;
        this.userService.userData.role = userInfo.roles[0].replace("_", " ");

        return this.agencyService.getAgency();
      })
    );

    this.agencySubscription$ = sdieBarData.subscribe((res) => {
      this.agencyPicture = res.agencyLogo;
      this.countC4l = res.countMonitored;
      this.countNonC4l = res.countNonMonitored;
      this.selectedAgency = res.agencyName;
    });
  }

  ngOnDestroy(): void {
    this.agencySubscription$.unsubscribe();
  }

  setSelectedTab(url) {
    if (url.includes("aed")) {
      this.tabselected = "devices";
    } else if (url.includes("dashboard")) {
      this.tabselected = "dashboard";
    } else if (url.includes("setting")) {
      this.tabselected = "setting";
    } else {
      this.tabselected = "";
    }
  }

  hasPrivilege(privileges: string[]): boolean {
    return this.authService.hasPrivilege(privileges);
  }

  changeRoute(url) {
    this.router.navigateByUrl(url);
  }

  //////////////////////////////////////////////////to open agency list to select an agency when logging in from ositch admin account///
  showAgencyListPopup() {
    const Btn = false;
    this.dialog.open(AgencyPopupComponent, {
      // height: '320px',
      width: "500px",
      // minWidth: '500px'
      // width: '700px',
      panelClass: "custom-modalbox",
      data: { Btn },
    });
  }

  //////////////////////////////////////////////////Set English to all devices////////////////////////////////////////////////
  onen() {
    this.text = "EN";
    this.path = "../assets/images/world.png";
    this.translation.use("en");
    localStorage.setItem("preferredLanguage", "en");
  }

  //////////////////////////////////////////////////Set French to all devices////////////////////////////////////////////////
  onfr() {
    this.text = "FR";
    this.path = "../assets/images/world.png";
    this.translation.use("fr");
    localStorage.setItem("preferredLanguage", "fr");
  }

  getBEBuildNo() {
    this.userService.getVersion().subscribe((res) => {
      this.BEBuidlNo = Object(res).body;
    });
  }

  //////////////////////////////////////////////////nagivate to my profile component////////////////////////////////////////////////
  onMyProfile() {
    this.router.navigate(["./myprofile/changeInfo"]);
  }

  //////////////////////////////////////////////////logout////////////////////////////////////////////////
  onLogout() {
    this.authService.onLogout();
  }

  onChange(value) {
    this.SharedMapService.getMap(value);
  }

  //////// Get Unseen Notification count

  getUnseenNotificationCount(agencyId) {
    this.ngxLoading = true;
    let sdieBarData = this.notificationsService
      .getUnseenNotificationCount(agencyId)
      .subscribe(
        (data: any) => {
          console.log(
            "Unseen notification count",
            data.body.unseenNotificationCount
          );
          this.notificationsService.setAgencyNotificationCount(
            data.body.unseenNotificationCount
          );
        },
        (error) => {
          this.ngxLoading = false;
        }
      );
  }
}
