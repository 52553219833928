import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export class CustomValidators {
  static cannotContainWhiteSpace(
    control: AbstractControl
  ): ValidationErrors | null {
    const isWhitespace = (control.value || "").trim().length === 0;
    return !isWhitespace ? null : { required: true };
  }

  static emailPattern(control: AbstractControl): ValidationErrors | null {
    // eslint-disable-next-line
    const emailRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
    const regexValidation = emailRegex.test(control.value);
    return !regexValidation ? { email: true } : null;
  }

  static faxNumberValidator(control: AbstractControl): ValidationErrors | null {
    const faxNumberPattern =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

    if (!control.value || faxNumberPattern.test(control.value)) {
      return null;
    } else {
      return { invalidFaxNumber: true };
    }
  }

  static validateSpecialCharacter(
    control: AbstractControl
  ): ValidationErrors | null {
    const value = control.value;
    if (value && value.includes("\\")) {
      return { nobackslash: true };
    }
    if (value && value.includes("/")) {
      return { noforwardslash: true };
    }
    return null;
  }

  static validateFloatingPoint(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        const validFloatRegex = /^(?!0\d+(\.\d{1,3})?)\d*(\.\d{1,3})?$/;

        const startsWithPointRegex = /^\.\d+$/;

        if (
          !validFloatRegex.test(control.value) ||
          startsWithPointRegex.test(control.value)
        ) {
          return { invalidFloatingPoint: true };
        }
      }

      return null;
    };
  }

  static onlySpacesValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && typeof control.value === "string") {
        // Check if the input contains only spaces
        if (/^\s*$/.test(control.value)) {
          return { onlySpaces: true };
        }
      }
      return null;
    };
  }

  static atLeastOneFieldRequired(fields: string[]) {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const values = fields.some((field) => !!control.get(field).value);
      // const hasValue = values.some((value) => !!value);

      if (!values) {
        return { atLeastOneFieldRequired: true };
      }

      return null;
    };
  }

  static passwordValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const upperCase = /[A-Z]/.test(control.value);
      const lowerCase = /[a-z]/.test(control.value);
      const number = /\d/.test(control.value);
      const minLength = control.value.length >= 8;
      const allowedCharacters =
        /^[a-zA-Z0-9~!@#$%^&*_\-+=`|(){}[\]:;”’'"<>,.?/]+$/.test(control.value);

      const valid =
        upperCase && lowerCase && number && minLength && allowedCharacters;

      return valid
        ? null
        : {
            minLength8: minLength ? false : true,
            hasUpperCase: upperCase ? false : true,
            hasLowerCase: lowerCase ? false : true,
            hasNumber: number ? false : true,
            hasInvalidCharacter: allowedCharacters ? false : true,
          };
      ` `;
    };
  }
}
