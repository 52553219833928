import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppGlobals } from "../app.global";
import { BehaviorSubject } from "rxjs";
interface IAgency {
  agencyLogo: string;
  countMonitored: number;
  countNonMonitored: number;
  agencyName: string;
}
@Injectable({
  providedIn: "root",
})
export class AgencyService {
  agency: BehaviorSubject<IAgency>;
  baseURLc4life: string;

  constructor(protected http: HttpClient, private appGlobal: AppGlobals) {
    this.baseURLc4life = this.appGlobal.baseApiUrl;
    this.agency = new BehaviorSubject({
      agencyLogo: localStorage.getItem("agencyLogo"),
      countMonitored: parseInt(localStorage.getItem("monitoredAedCount")),
      countNonMonitored: parseInt(localStorage.getItem("nonMonitoredAedCount")),
      agencyName: localStorage.getItem("agencyName"),
    });
  }

  setAgencyPicture(path: string) {
    localStorage.setItem("agencyLogo", path);
    this.agency.next({
      ...this.agency.value,
      agencyLogo: path,
    });
  }

  setMonitoredAed(count) {
    
    localStorage.setItem("monitoredAedCount", count.toString());
    this.agency.next({
      ...this.agency.value,
      countMonitored: count,
    });
  }

  setNonMonitoredAed(count) {
    localStorage.setItem("nonMonitoredAedCount", count.toString());
    this.agency.next({
      ...this.agency.value,
      countNonMonitored: count,
    });
  }

  setAgencyName(name: string) {
    localStorage.setItem("agencyName", name);
    this.agency.next({
      ...this.agency.value,
      agencyName: name,
    });
  }

  addMonitoredAed() {
    let count = this.agency.value.countMonitored + 1;
    localStorage.setItem("monitoredAedCount", count.toString());
    this.agency.next({
      ...this.agency.value,
      countMonitored: count,
    });
  }

  addNonMonitoredAed() {
    let count = this.agency.value.countNonMonitored + 1;
    localStorage.setItem("nonMonitoredAedCount", count.toString());
    this.agency.next({
      ...this.agency.value,
      countNonMonitored: count,
    });
  }

  getAgency() {
    return this.agency.asObservable();
  }

  configuredManualInspection() {
    const agencyId = localStorage.getItem("agencyId");
    return this.http.get(
      `${this.baseURLc4life}agency/get/mir-config?agencyId=${agencyId}`,
      {
        observe: "response",
      }
    );
  }

  reInviteAgency(body) {
    return this.http.post(`${this.baseURLc4life}agency/reInvite`, body, {
      observe: "response",
    });
  }

  manualInspectionReminder(body) {
    const agencyId = localStorage.getItem("agencyId");
    return this.http.post(
      `${this.baseURLc4life}agency/manual-inspection-reminder?agencyId=${agencyId}`,
      body,
      {
        observe: "response",
      }
    );
  }

  getSpecificAgency(agencyId) {
    return this.http.get<any>(
      `${this.baseURLc4life}agency/get-agency/${agencyId}`
    );
  }

  inviteAgency(body): any {
    return this.http.post(`${this.baseURLc4life}agency/add`, body);
  }
  getAllAgencypaginated(
    pageNum,
    size,
    dir = "ASC",
    value = "name",

    filter = {
      name: "",
      status: "",
    }
  ) {
    return this.http.get(
      `${this.baseURLc4life}agency/get-all-agency?page=${pageNum}&size=${size}&sortDir=${dir}&sort=${value}&name=${filter.name}&status=${filter.status}`
    );
  }
  getAllAgency() {
    return this.http.get(`${this.baseURLc4life}agency/get-all-agencies`);
  }

  getAgencyByName(name) {
    return this.http.get(
      `${this.baseURLc4life}agency/search-agency-byname?agencyname=${name}`
    );
  }

  deleteAgency(agencyId) {
    return this.http.delete(`${this.baseURLc4life}agency/delete/${agencyId}`);
  }

  changeAgencyStatus(action, agencyId, body): any {
    return this.http.post(
      `${this.baseURLc4life}agency/change/enable/${action}/?agencyId=${agencyId}`,
      body
    );
  }

  updateAgency(data) {
    const agencyId = localStorage.getItem("agencyId");
    return this.http.post(
      `${this.baseURLc4life}agency/update?agencyId=${agencyId}`,
      data
    );
  }

  agencySignup(body): any {
    return this.http.post(`${this.baseURLc4life}agency/signup`, body);
  }

  verifyAgency(token) {
    return this.http.get(`${this.baseURLc4life}agency/verify/${token}`);
  }
  getSidebar(agencyId) {
    return this.http.get<any>(
      `${this.baseURLc4life}aed/v2/get-sidebar-info?agencyId=${agencyId}`
    );
  }
}
