<div class="example-container">
  <app-toast-notification-internet-connectivity></app-toast-notification-internet-connectivity>
  <mat-sidenav-container
    class="example-sidenav-container c4ladmin"
    autosize
    [hasBackdrop]="false"
  >
    <mat-sidenav
      #drawer
      class="sidenav"
      fixedInViewport="false"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'side' : 'side'"
      [opened]="(isHandset$ | async) === true"
    >
      <app-sidebar
        *ngIf="authService.isLogin() && !isSetPassowrd"
      ></app-sidebar>
    </mat-sidenav>
    <mat-sidenav-content>
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        class="toggleNavButton"
        id="toggleNavButton"
        [disabled]="ngxLoading"
      >
        <span *ngIf="drawer.opened"
          ><img
            src="../../assets/revamp-images/icon/menu-icon.svg"
            alt="Menu Icon"
        /></span>
        <span *ngIf="!drawer.opened" class="reverseMenuIcon"
          ><img
            src="../../assets/revamp-images/icon/menu-icon.svg"
            alt="Menu Icon"
        /></span>
      </button>

      <app-toast-notification
        class="notificationsDashbaord"
      ></app-toast-notification>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
