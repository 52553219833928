import { Injectable } from "@angular/core";

@Injectable()
export class AppGlobals {
  // public baseApiUrl = "https://dev.connected4life.com/c4life/v1/";

  //public baseApiUrl = 'https://dev.connected4life.com/c4life/v1/';

  public baseApiUrl = "https://prod.connected4life.com/c4life/v1/";

  // public baseApiUrl = "http://localhost:443/c4life/v1/";
  // public baseApiUrl = "http://103.125.69.154:8443/c4life/v1/";

  // public baseApiUrl = "https://local.connected4life.com:8081/c4life/v1/";

  // public baseApiUrl = "https://local.connected4life.com:8081/c4life/v1/";

  public alertMsgTimeout: number = 2 * 1000;

  public modalWidth = "950px";

  public confirmModalWidth = "550px";

  public buildNum = "v3.2.2";

  domain = "http://localhost:4200/";
}
