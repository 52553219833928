import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { map } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { parsePhoneNumber } from "libphonenumber-js/max";
import { AlertService } from "../../../core/services";
import { AgencyService } from "../../../agency/agency.service";
import { SharedService } from "../../../core/services/shared.service";
import { ModalAedComponent } from "../../../modal-aed/modal-aed.component";
import { sortByAscending } from "src/app/core/utilities/utils";
import { CustomValidators } from "../../../shared/validators/custom.validator";

@Component({
  selector: "app-agency-edit",
  templateUrl: "./agency-edit.component.html",
  styleUrls: ["./agency-edit.component.css"],
})
export class AgencyEditComponent implements OnInit {
  @ViewChild("fileInput") fileInput: any;
  agencyRegistrationForm: FormGroup;
  agencyJurisdictionCity: any = {
    lat: "",
    lng: "",
  };
  logo: File;
  errorFileSize = false;
  errorFileType = false;
  countries = [];
  saveLogo: string;
  jurisdictionCities: any = [];
  ngxLoading = false;
  agencyPic: any;

  constructor(
    private agencyService: AgencyService,
    private alertService: AlertService,
    private csc: SharedService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,
    private translation: TranslateService
  ) {}

  ngOnInit() {
    this.ngxLoading = true;
    this.getCountriesDataFromService();
    this.createAgencyForm();
    this.getAgency();
    this.agencyRegistrationForm.controls["mailingAdress"].disable();
  }

  createAgencyForm() {
    const fdqnPattern =
      /^(?!-)[A-Za-z][A-Za-z0-9-]{1,62}(\.[A-Za-z0-9-]{1,63}){2,3}(\.[A-Za-z][A-Za-z0-9-]{1,60}[A-Za-z])?[^-.0-9]$/;
    const emailPattern = /^.{2,63}@.{2,255}/;
    // const reg =
    //   /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    this.agencyRegistrationForm = this.fb.group({
      name: ["", [Validators.required]],
      mailingAdress: [
        "",
        [Validators.required, Validators.pattern(emailPattern)],
      ],
      city: ["", [Validators.required]],
      zipCode: [
        "",
        [Validators.required, Validators.pattern("^[A-Za-z0-9\\s]*$")],
      ],
      country: ["", [Validators.required]],
      province: ["", [Validators.required]],
      street: ["", [Validators.required]],
      selectedtob: ["", [Validators.required]],
      website: ["", [Validators.required, Validators.pattern(fdqnPattern)]],
      phoneNumber: ["", [Validators.required]],
      faxNumber: ["", Validators.required],
      agencyJurisdictionCountry: ["", [Validators.required]],
      agencyJurisdictionCity: ["", [Validators.required]],
      thresholdOfDaysToNotify: ["", [Validators.required]],
      logo: [""],
    });
  }

  getCountriesDataFromService() {
    this.csc.getCountries().subscribe((result) => {
      this.countries = sortByAscending(result, "name");
      this.agencyRegistrationForm.controls.country.enable();
      this.agencyRegistrationForm.controls.province.enable();
      this.agencyRegistrationForm.controls.city.enable();
    });
  }

  // get agency details by hitting the api
  getAgency() {
    const agencyId = localStorage.getItem("agencyId");
    if (agencyId !== null) {
      this.agencyService.getSpecificAgency(agencyId).subscribe((response) => {
        this.ngxLoading = false;
        this.setAgencyFields(response.body);
      });
    } else {
      this.ngxLoading = false;
      this.alertService.showFailure("No Signed Up Agency Found");
    }
  }

  // populate input fields as received from api
  setAgencyFields(data) {
    this.onChangeCountry();
    const results = data.agencyJurisdictionLocation.split(",");
    this.onChangeJurisdicationCountry({ value: results[0], city: results[1] });
    this.saveLogo = data.logo;
    this.agencyRegistrationForm.controls.agencyJurisdictionCountry.setValue(
      results[0]
    );
    this.agencyRegistrationForm.controls.name.setValue(data.name);
    this.agencyRegistrationForm.controls.mailingAdress.setValue(
      data.mailingAddress
    );
    this.agencyRegistrationForm.controls.city.setValue(data.city);
    this.agencyRegistrationForm.controls.zipCode.setValue(data.postalCode);
    this.agencyRegistrationForm.controls.country.setValue(data.country);
    this.agencyRegistrationForm.controls.province.setValue(data.province);
    this.agencyRegistrationForm.controls.street.setValue(data.street);
    this.agencyRegistrationForm.controls.selectedtob.setValue(
      data.typeOfBusiness
    );
    this.agencyRegistrationForm.controls.website.setValue(data.website);
    if (data.thresholdOfDaysToNotify) {
      this.agencyRegistrationForm.controls.thresholdOfDaysToNotify.setValue(
        data.thresholdOfDaysToNotify.toString()
      );
    }
    this.agencyRegistrationForm.controls.phoneNumber.setValue(data.phoneNumber);
    var faxnumber = data.faxNumber;
    faxnumber.split("");
    if (faxnumber[0] != "+") {
      this.agencyRegistrationForm.controls.faxNumber.setValue(
        "+" + data.faxNumber
      );
    } else {
      this.agencyRegistrationForm.controls.faxNumber.setValue(data.faxNumber);
    }

    this.agencyRegistrationForm.controls.agencyJurisdictionCountry.setValue(
      results[0]
    );
    this.agencyRegistrationForm.controls.agencyJurisdictionCity.setValue(
      results[1]
    );

    this.agencyRegistrationForm.controls.logo.setValue(data.logo);
    this.agencyPic = data.logo;
  }

  // open modal component to display picture
  openModal() {
    this.dialog.open(ModalAedComponent, {
      disableClose: true,
      data: { pic: this.saveLogo },
    });
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  compareFunction(c1, c2): boolean {
    if (c1 && c2) {
      const valueMatched = c1.toString() === c2.toString();
      return valueMatched;
    }
    return false;
  }

  omitSpecialChar(event) {
    if (this.agencyRegistrationForm.get("name").value.length === 0) {
      const k = event.charCode;
      return (
        (k > 64 && k < 91) ||
        (k > 96 && k < 123) ||
        k === 8 ||
        k === 32 ||
        (k >= 48 && k <= 57)
      );
    }
    return true;
  }

  // submit user entered data to api
  onSubmit() {
    if (
      this.agencyRegistrationForm.invalid ||
      this.errorFileType === true ||
      this.errorFileSize === true
    ) {
      this.agencyRegistrationForm.markAllAsTouched();
      this.alertService.showFailure("Unable to Edit.");
      return;
    }
    this.ngxLoading = true;
    const urlencoded = new FormData();

    urlencoded.append("name", this.agencyRegistrationForm.get("name").value);
    urlencoded.append(
      "mailingAddress",
      this.agencyRegistrationForm.get("mailingAdress").value
    );
    urlencoded.append("city", this.agencyRegistrationForm.get("city").value);
    urlencoded.append(
      "postalCode",
      this.agencyRegistrationForm.get("zipCode").value.trim()
    );
    urlencoded.append(
      "country",
      this.agencyRegistrationForm.get("country").value
    );
    urlencoded.append(
      "province",
      this.agencyRegistrationForm.get("province").value
    );
    urlencoded.append(
      "street",
      this.agencyRegistrationForm.get("street").value
    );
    urlencoded.append(
      "typeOfBusiness",
      this.agencyRegistrationForm.get("selectedtob").value
    );
    urlencoded.append(
      "website",
      this.agencyRegistrationForm.get("website").value
    );
    urlencoded.append(
      "thresholdOfDaysToNotify",
      this.agencyRegistrationForm.get("thresholdOfDaysToNotify").value
    );
    urlencoded.append(
      "phoneNumber",
      this.agencyRegistrationForm.get("phoneNumber").value
    );
    urlencoded.append(
      "faxNumber",
      this.agencyRegistrationForm.get("faxNumber").value
    );
    urlencoded.append(
      "agencyJurisdictionLocation",
      `${this.agencyRegistrationForm.get("agencyJurisdictionCountry").value},${
        this.agencyRegistrationForm.get("agencyJurisdictionCity").value
      }`
    );
    urlencoded.append(
      "jurisdictionLat",
      this.agencyJurisdictionCity.lat.toString()
    );
    urlencoded.append(
      "jurisdictionLng",
      this.agencyJurisdictionCity.lng.toString()
    );
    if (this.logo != null) {
      urlencoded.append("logo", this.logo, this.logo.name);
    }

    this.agencyService.updateAgency(urlencoded).subscribe(
      (res: any) => {
        this.ngxLoading = false;
        this.alertService.showSuccess(
          this.translation.instant(
            "cfl.agencyEditComponent.success.message.updated"
          )
        );
        this.agencyService.setAgencyPicture(res.body.logo);
        this.agencyService.setAgencyName(res.body.name);
        localStorage.setItem("currentLat", Object(res).body.jurisdictionLat);
        localStorage.setItem("currentLng", Object(res).body.jurisdictionLng);
        this.router.navigate(["/dashboard"]);
      },
      (err: any) => {
        this.ngxLoading = false;
        if (err.error.errors[0].errorCode === "AG-0011") {
          this.alertService.showFailure(
            this.translation.instant(
              "cfl.agencyEditComponent.success.message.userNotFound"
            )
          );
        } else if (err.error.errors[0].errorCode === "AG-0002") {
          this.alertService.showFailure(
            this.translation.instant(
              "cfl.agencyEditComponent.success.message.agencyNotFound"
            )
          );
        } else if (err.error.errors[0].errorCode === "AG-0001") {
          this.alertService.showFailure(
            this.translation.instant("cfl.error.form.editAgency")
          );
        }
      }
    );
  }

  onChangeCountry() {
    this.agencyRegistrationForm.controls.phoneNumber.setValue(null);
    this.agencyRegistrationForm.controls.faxNumber.setValue(null);
    this.agencyRegistrationForm.controls.faxNumber.markAsTouched();
    this.agencyRegistrationForm.controls.faxNumber.markAsTouched();
    this.phoneNumberChanged();
    this.agencyRegistrationForm.controls.province.enable();
    this.agencyRegistrationForm.controls.province.setValue(null);
    this.agencyRegistrationForm.controls.city.setValue(null);
    this.agencyRegistrationForm.controls.province.setValidators(
      Validators.required
    );
    this.agencyRegistrationForm.controls.city.setValidators(
      Validators.required
    );
  }

  onChangeJurisdicationCountry(data) {
    const countryIso = data.value;
    this.csc
      .getCitiesJson()
      .pipe(
        map((items: any) =>
          items.filter((item: any) => item.country_code === countryIso)
        )
      )
      .subscribe((result) => {
        if (result.length > 0) {
          this.jurisdictionCities = result.sort((a, b) =>
            a.name.toUpperCase() < b.name.toUpperCase()
              ? -1
              : a.name.toUpperCase() > b.name.toUpperCase()
              ? 1
              : 0
          );
          this.agencyRegistrationForm.controls.agencyJurisdictionCity.reset();
          this.agencyRegistrationForm.controls.agencyJurisdictionCity.enable();
          this.agencyRegistrationForm.controls.agencyJurisdictionCity.setValidators(
            [Validators.required]
          );
          this.agencyRegistrationForm.controls.agencyJurisdictionCity.setValue(
            data.city ? data.city : null
          );
        } else {
          this.agencyRegistrationForm.controls.agencyJurisdictionCity.setValue(
            null
          );
          this.agencyRegistrationForm.controls.agencyJurisdictionCity.setValue(
            null
          );
          // this.agencyRegistrationForm.controls.agencyJurisdictionCity.disable();
          // this.agencyRegistrationForm.controls.agencyJurisdictionCity.clearValidators();
        }
      });
    this.agencyRegistrationForm.updateValueAndValidity();
  }

  onChangeJurisdicationCity(event) {
    const cityName = event.value;
    const city = this.jurisdictionCities.find((city) => city.name === cityName);
    this.agencyJurisdictionCity.lat = city.latitude;
    this.agencyJurisdictionCity.lng = city.longitude;
  }

  faxNumberChanged() {
    // const formNumber = this.agencyRegistrationForm.controls.faxNumber.value;
    // const country = this.agencyRegistrationForm.controls.country.value;
    // if (formNumber && country && formNumber.length > 5) {
    //   const selectedCountry = this.countries.find((c) => c.name === country);
    //   try {
    //     const phoneNumber = parsePhoneNumber(formNumber, selectedCountry.iso2);
    //     this.agencyRegistrationForm.controls.faxNumber.setValue(
    //       phoneNumber.number
    //     );
    //     if (!phoneNumber.isValid()) {
    //       this.agencyRegistrationForm.controls.faxNumber.setErrors({
    //         pattern: true,
    //       });
    //       this.agencyRegistrationForm.controls.faxNumber.markAsTouched();
    //     } else {
    //       this.agencyRegistrationForm.controls.faxNumber.setErrors(null);
    //     }
    //   } catch (error) {
    //     this.agencyRegistrationForm.controls.faxNumber.setErrors({
    //       pattern: true,
    //     });
    //     this.agencyRegistrationForm.controls.faxNumber.updateValueAndValidity();
    //   }
    // }
    const number = this.agencyRegistrationForm.controls.faxNumber.value;
    const country = this.agencyRegistrationForm.controls.country.value;
    if (number && country && number.length > 5) {
      const selectedCountry = this.countries.find((c) => c.name === country);
      try {
        const faxNumber = parsePhoneNumber(number, selectedCountry.iso2);
        this.agencyRegistrationForm.controls.faxNumber.setValue(
          faxNumber.number
        );
        if (!faxNumber.isValid()) {
          this.agencyRegistrationForm.controls.faxNumber.setErrors({
            pattern: true,
          });
          this.agencyRegistrationForm.controls.faxNumber.markAsTouched();
        } else {
          this.agencyRegistrationForm.controls.faxNumber.setErrors(null);
        }
      } catch (error) {
        this.agencyRegistrationForm.controls.faxNumber.setErrors({
          pattern: true,
        });
      }
    }
  }

  // get methods for form fields
  get websiteName() {
    return this.agencyRegistrationForm.get("website");
  }

  get thresholdOfDays() {
    return this.agencyRegistrationForm.get("thresholdOfDaysToNotify");
  }

  get getagencyRegistrationFormControls() {
    return this.agencyRegistrationForm.controls;
  }

  // when user clicks on choose file , do validations
  onFileSelected(event) {
    const file2 = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file2);
    reader.onload = () => {
      console.log(reader.result.toString());
    };

    this.errorFileSize = false;
    this.errorFileType = false;
    [this.getagencyRegistrationFormControls.logo] = event.target.files;
    const file = event.target.files[0];
    if (file.type === "image/png") {
    }
    if (file.size > 5000000) {
      this.errorFileSize = true;
      this.fileInput.nativeElement.value = "";
    } else if (
      !(
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg"
      )
    ) {
      this.errorFileType = true;
      this.fileInput.nativeElement.value = "";
    } else {
      this.logo = file;
    }
  }

  phoneNumberChanged() {
    const number = this.agencyRegistrationForm.controls.phoneNumber.value;
    const country = this.agencyRegistrationForm.controls.country.value;
    if (number && country && number.length > 5) {
      const selectedCountry = this.countries.find((c) => c.name === country);
      try {
        const phoneNumber = parsePhoneNumber(number, selectedCountry.iso2);
        this.agencyRegistrationForm.controls.phoneNumber.setValue(
          phoneNumber.number
        );
        if (!phoneNumber.isValid()) {
          this.agencyRegistrationForm.controls.phoneNumber.setErrors({
            pattern: true,
          });
          this.agencyRegistrationForm.controls.phoneNumber.markAsTouched();
        } else {
          this.agencyRegistrationForm.controls.phoneNumber.setErrors(null);
        }
      } catch (error) {
        this.agencyRegistrationForm.controls.phoneNumber.setErrors({
          pattern: true,
        });
      }
    }
  }

  // Error messages for UI respectively for form
  getNameErrorMessages() {
    return this.agencyRegistrationForm.controls.name.hasError("required")
      ? this.translation.instant("cfl.error.required", {
          value: this.translation.instant(
            "cfl.form.dialog.component.name.placeholder"
          ),
        })
      : "";
  }

  getmailingAdressErrorMessages() {
    return this.agencyRegistrationForm.controls.mailingAdress.hasError(
      "required"
    )
      ? this.translation.instant("cfl.error.required", {
          value: this.translation.instant(
            "cfl.agencyEditComponent.form.field.mailingAddress.placeholder"
          ),
        })
      : this.agencyRegistrationForm.controls.mailingAdress.hasError("minlength")
      ? this.translation.instant("cfl.error.minLength", {
          value: this.translation.instant(
            "cfl.agencyEditComponent.form.field.mailingAddress.placeholder"
          ),
        })
      : "";
  }

  getcityErrorMessages() {
    return this.agencyRegistrationForm.controls.city.hasError("required")
      ? this.translation.instant("cfl.error.required", {
          value: this.translation.instant(
            "cfl.agencyEditComponent.form.field.city.placeholder"
          ),
        })
      : "";
  }

  getzipCodeErrorMessages() {
    const a = this.agencyRegistrationForm.controls.zipCode.hasError("required");
    const b = this.agencyRegistrationForm.controls.zipCode.hasError("pattern");

    return this.agencyRegistrationForm.controls.zipCode.hasError("required")
      ? this.translation.instant("cfl.error.required", {
          value: this.translation.instant(
            "cfl.agencyEditComponent.form.field.zipCode.placeholder"
          ),
        })
      : this.agencyRegistrationForm.controls.zipCode.hasError("pattern")
      ? "Only numbers and alphabets are allowed"
      : "";
  }

  getselectedcErrorMessages() {
    return this.agencyRegistrationForm.controls.country.hasError("required")
      ? this.translation.instant("cfl.error.required", {
          value: this.translation.instant(
            "cfl.agencyEditComponent.form.field.country.placeholder"
          ),
        })
      : "";
  }

  getprovinceErrorMessages() {
    return this.agencyRegistrationForm.controls.province.hasError("required")
      ? this.translation.instant("cfl.error.required", {
          value: this.translation.instant(
            "cfl.agencyEditComponent.form.field.province.placeholder"
          ),
        })
      : "";
  }

  getstreetErrorMessages() {
    return this.agencyRegistrationForm.controls.street.hasError("required")
      ? this.translation.instant("cfl.error.required", {
          value: this.translation.instant(
            "cfl.agencyRegisterationComponent.form.field.street.placeholder"
          ),
        })
      : this.agencyRegistrationForm.controls.street.hasError("minlength")
      ? this.translation.instant("cfl.error.minLength", {
          value: this.translation.instant(
            "cfl.agencyRegisterationComponent.form.field.street.placeholder"
          ),
        })
      : "";
  }

  getwebsiteErrorMessages() {
    return this.agencyRegistrationForm.controls.website.hasError("required")
      ? this.translation.instant("cfl.error.required", {
          value: this.translation.instant(
            "cfl.agencyEditComponent.form.field.website.placeholder"
          ),
        })
      : this.agencyRegistrationForm.controls.website.hasError("pattern")
      ? this.translation.instant("cfl.error.invalidURL")
      : "";
  }

  getselectedtobErrorMessages() {
    return this.agencyRegistrationForm.controls.selectedtob.hasError("required")
      ? this.translation.instant("cfl.error.required", {
          value: this.translation.instant(
            "cfl.agencyEditComponent.form.field.tob.placeholder"
          ),
        })
      : "";
  }

  getphoneNumberErrorMessages() {
    return this.agencyRegistrationForm.controls.phoneNumber.hasError("required")
      ? this.translation.instant("cfl.error.required", {
          value: this.translation.instant(
            "cfl.agencyEditComponent.form.field.phone.placeholder"
          ),
        })
      : this.agencyRegistrationForm.controls.phoneNumber.hasError("pattern")
      ? "Not a valid phoneNumber"
      : this.agencyRegistrationForm.controls.phoneNumber.hasError("minlength")
      ? this.translation.instant("cfl.error.validphonenumber", {
          value: this.translation.instant(
            "cfl.agencyRegisterationComponent.form.field.phone.placeholder",
            {
              value: this.translation.instant(
                "cfl.agencyRegisterationComponent.form.field.phone.placeholder"
              ),
            }
          ),
        })
      : "";
  }

  getfaxNumberErrorMessages() {
    return this.agencyRegistrationForm.controls.faxNumber.hasError("required")
      ? this.translation.instant("cfl.error.required", {
          value: this.translation.instant(
            "cfl.agencyEditComponent.form.field.fax.placeholder"
          ),
        })
      : this.agencyRegistrationForm.controls.faxNumber.hasError("pattern")
      ? "Not a valid Fax number"
      : "";
  }

  getThresholdOfDaysToNotifyError() {
    return this.agencyRegistrationForm.controls.thresholdOfDaysToNotify.hasError(
      "required"
    )
      ? this.translation.instant("cfl.error.required", {
          value: this.translation.instant(
            "cfl.agencyRegisterationComponent.form.field.thresholdOfDaysToNotify.placeholder",
            {
              value: this.translation.instant(
                "cfl.agencyRegisterationComponent.form.field.thresholdOfDaysToNotify.placeholder"
              ),
            }
          ),
        })
      : "";
  }

  getagencyJurisdictionLocationErrorMessages() {
    return this.agencyRegistrationForm.controls.agencyJurisdictionLocation.hasError(
      "required"
    )
      ? this.translation.instant("cfl.error.required", {
          value: this.translation.instant(
            "cfl.agencyEditComponent.form.field.jurisdiction.placeholder"
          ),
        })
      : "";
  }

  getagencyJurisdictionCityErrorMessages() {
    return this.agencyRegistrationForm.controls.agencyJurisdictionCity.hasError(
      "required"
    )
      ? this.translation.instant("cfl.error.required", {
          value: this.translation.instant(
            "cfl.agencyEditComponent.form.field.jurisdictionCity.placeholder"
          ),
        })
      : "";
  }

  getselectedCountryErrorMessages() {
    return this.agencyRegistrationForm.controls.agencyJurisdictionCountry.hasError(
      "required"
    )
      ? this.translation.instant("cfl.error.required", {
          value: this.translation.instant("cfl.error.selectValue"),
        })
      : "";
  }

  getWebsiteError() {
    return this.websiteName.hasError("required")
      ? this.translation.instant("cfl.error.required", {
          value: this.translation.instant("cfl.error.validUrl"),
        })
      : "";
  }
}
