<!-- <div class="modal-header">
<h4 mat-dialog-title>Error</h4>
</div> -->
<div mat-dialog-content>
  <p class="mt-30">{{message}}</p>
</div>
<div mat-dialog-actions class="modal-buttons">
  <button class="confirm-dial-btn" mat-button [mat-dialog-close]="true">{{acceptButtonText}}</button>
  <button class="confirm-dial-btn" mat-button mat-dialog-close cdkFocusInitial *ngIf="displaySecondButton">{{cancelButtonText}}</button>
</div>
