import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TimePickerModule } from "@syncfusion/ej2-angular-calendars";
import { MyprofileComponent } from "./components/myprofile/myprofile.component";
import { ChangeInfoComponent } from "./components/change-info/change-info.component";
import { SharedModule } from "../shared/shared.module";
import { AgencyEditComponent } from "./components/agency-edit/agency-edit.component";
import { MyprofileRoutingModule } from "./myprofile.routing";
import { ChangePasswordComponent } from "./components/change-password/change-password.component";

@NgModule({
  declarations: [
    MyprofileComponent,
    ChangeInfoComponent,
    AgencyEditComponent,
    ChangePasswordComponent,
  ],
  exports: [
    MyprofileComponent,
    ChangeInfoComponent,
    AgencyEditComponent,
    ChangePasswordComponent,
  ],
  entryComponents: [MyprofileComponent],
  imports: [
    CommonModule,
    TimePickerModule,
    MyprofileRoutingModule,
    SharedModule,
  ],
})
export class MyprofileModule {}
