import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "../core/services/index";
import { SharedService } from "../core/services/shared.service";
import { AuthService } from "../core/services/auth.service";
import { MustMatch } from "../helpers/must-match.validator";
import { UserService } from "../settings/users/user.service";
import { CustomValidators } from "../shared/validators";
import { AppConstants } from "../core/utilities/constants";

@Component({
  selector: "app-confirm-password",
  templateUrl: "./confirm-password.component.html",
  styleUrls: ["./confirm-password.component.css"],
})
export class ConfirmPasswordComponent implements OnInit {
  hide2 = true;
  hide3 = true;
  setPasswordForm: FormGroup;
  ngxLoading;
  passwordMaxLength = AppConstants.passwordMaxLength;
  errorType = AppConstants.errorType;

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    public sharedService: SharedService,
    private translation: TranslateService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.createPasswordForm();
  }

  createPasswordForm() {
    this.setPasswordForm = this.fb.group(
      {
        newPassword: [
          "",
          [Validators.required, CustomValidators.passwordValidator()],
        ],
        confirmPassword: ["", [Validators.required]],
      },
      {
        validator: MustMatch("newPassword", "confirmPassword"),
      }
    );
  }

  onSubmit() {
    this.ngxLoading = true;

    const body = {
      newPassword: this.newPassword.value,
      confirmPassword: this.confirmPassword.value,
    };

    return this.userService.setPassword(body).subscribe(
      (res) => {
        this.ngxLoading = false;
        localStorage.setItem("realmName", res.body.agencyName);
        localStorage.setItem("email", res.body.userName);
        localStorage.setItem("agencyStatus", "PENDING_SIGNUP");
        this.router.navigate(["/agency/agency-registeration"]);
        this.alertService.showSuccess(
          this.translation.instant("cfl.confirmPassword.success.message") +
            res.body.userName
        );
      },

      (err) => {
        if (err.error.errors[0].errorCode === "AUTH-0005") {
          this.alertService.showFailure(
            this.translation.instant(
              "cfl.form.signin.error.incorrectCredentials"
            )
          );
        } else if (err.error.errors[0].errorCode === "AUTH-0006") {
          this.alertService.showFailure(
            this.translation.instant("cfl.form.signin.error.disabledAccount")
          );
        }
        localStorage.removeItem("role");
      }
    );
  }

  hasPrivilege(privileges: string[]): boolean {
    return this.authService.hasPrivilege(privileges);
  }

  getsetPasswordErrorMessages() {
    return this.setPasswordForm.controls.newPassword.hasError("required")
      ? this.translation.instant("cfl.error.required", {
          value: this.translation.instant(
            "cfl.form.confirmPassword.password.placeholder"
          ),
        })
      : "";
  }

  getconfirmPasswordErrorMessages() {
    return this.setPasswordForm.controls.confirmPassword.hasError("mustMatch")
      ? this.translation.instant("cfl.error.doesNotMatch", {
          field1: this.translation.instant("cfl.new.placeholder", {
            value: this.translation.instant(
              "cfl.form.confirmPassword.password.placeholder"
            ),
          }),
          field2: this.translation.instant("cfl.confirm.placeholder", {
            value: this.translation.instant(
              "cfl.form.confirmPassword.password.placeholder"
            ),
          }),
        })
      : "";
  }

  get newPassword() {
    return this.setPasswordForm.controls.newPassword;
  }

  get confirmPassword() {
    return this.setPasswordForm.controls.confirmPassword;
  }

  getErrorClass(e: string): string {
    const control = this.setPasswordForm.controls["newPassword"];
    return control.hasError("required") || control.hasError(e)
      ? ""
      : "text-success";
  }

  getErrorMessage(e: string): string {
    const translationKey = `cfl.error.${e}`;
    const placeholderKey = "cfl.password.placeholder";
    return this.translation.instant(translationKey, {
      value: this.translation.instant(placeholderKey),
    });
  }
}
