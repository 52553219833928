<div class="form-container" class="forgotPassForm">
  <h2 mat-dialog-title class="text-center">
    {{ "cfl.form.forgot.modal.title" | translate }}
  </h2>
  <p>{{ "cfl.form.forgot.modal.text" | translate }}</p>

  <form [formGroup]="emailForm">
    <div class="formField">
      <mat-form-field appearance="outline">
        <input
          matInput
          placeholder="{{
            'cfl.form.forgot.modal.email.placeholder' | translate
          }}"
          id="email"
          formControlName="email"
        />
      </mat-form-field>
      <div class="errorDivFormField">
        <mat-error
          *ngIf="
            emailForm.controls.email.hasError('required') &&
            emailForm.controls.email.touched
          "
        >
          {{
            alertService.showRequiredFieldMessage(
              "cfl.form.forgot.modal.email.placeholder" | translate
            )
          }}
        </mat-error>
        <mat-error
          *ngIf="
            !emailForm.controls.email.hasError('required') &&
            emailForm.controls.email.hasError('email')
          "
        >
          {{ alertService.showInvalidEmailMessage() }}
        </mat-error>
      </div>
    </div>
    <div class="button-container">
      <button
        *ngIf="!isLoading"
        class="primary-button"
        (click)="onSubmit()"
        mat-raised-button
        [disabled]="!emailForm.valid"
      >
        {{ "cfl.form.forgot.modal.btn.send" | translate }}
      </button>
      <button
        class="secondary-button"
        *ngIf="isLoading"
        [disabled]="true"
        mat-raised-button
      >
        <mat-progress-spinner
          diameter="32"
          *ngIf="isLoading"
          mode="indeterminate"
        ></mat-progress-spinner>
      </button>
    </div>
  </form>
</div>
