<div class="deviceDetailComponent headerFixed">
  <form [formGroup]="deviceInformationForm" class="example-form">
    <header>
      <h3>
        <span mat-dialog-close class="closeButton">x</span>
        <img src="{{ IconImage }}" alt="AEDStates" /> {{ aedSerialNumber }}
      </h3>
    </header>

    <div id="loader" class="deviceDetailContent">
      <div class="alert alert-danger notparent-qrcode" *ngIf="notParentqrFlag">
        Not a Parent Cabinet
      </div>
      <table class="aedPopupTable" aria-hidden="true" role="presentation">
        <!-- <tr>
                <td>
                    <mat-label>Friendly Name</mat-label>
                </td>
                <td><span class="green"><input matInput readonly formControlName="deviceName"></span></td>
            </tr> -->
        <tr *ngIf="isMonitored">
          <td class="text-center">
            <div class="imgHeading">
              Inward camera image
              <img
                class="img-front-back"
                *ngIf="PlayVedio === false; else elseBlock"
                [src]="deviceInformationForm.controls.aedImage.value"
                (click)="
                  openModal(deviceInformationForm.controls.aedImage.value)
                "
                alt="Image"
              />
              <ng-template #elseBlock>
                <video
                  style="max-width: 200px"
                  autoplay
                  loop
                  muted
                  (click)="
                    openModal(deviceInformationForm.controls.aedImage.value)
                  "
                >
                  <source
                    [src]="deviceInformationForm.controls.aedImage.value"
                    type="video/mp4"
                  />
                </video>
              </ng-template>
            </div>
          </td>
          <td class="text-center">
            <div class="imgHeading">
              Front camera image
              <img
                class="img-front-back"
                *ngIf="PlayVedio === false; else elseBlock"
                [src]="deviceInformationForm.controls.frontCamera.value"
                (click)="
                  openModal(deviceInformationForm.controls.frontCamera.value)
                "
                alt="Image"
              />
              <ng-template #elseBlock>
                <video
                  style="max-width: 200px"
                  autoplay
                  loop
                  muted
                  (click)="
                    openModal(deviceInformationForm.controls.frontCamera.value)
                  "
                >
                  <source
                    [src]="deviceInformationForm.controls.frontCamera.value"
                    type="video/mp4"
                  />
                </video>
              </ng-template>
            </div>
          </td>
        </tr>
        <tr *ngIf="deviceInformationForm.value.reason != null">
          <td>
            <mat-label>Reason</mat-label>
          </td>
          <td>
            <p>
              {{ deviceInformationForm.value.reason }}
            </p>
            <!-- <span  [ngClass]="{'red': deviceInformationForm.value.aedState === 'MISSING','green': deviceInformationForm.value.aedState === 'READY', 'red' : deviceInformationForm.value.aedState === 'NOT READY' }" >
              <input matInput readonly formControlName="aedState" />
            </span> -->
          </td>
        </tr>
        <tr>
          <td>
            <mat-label>Location</mat-label>
          </td>
          <td>
            <textarea
              style="resize: none !important"
              matInput
              readonly
              formControlName="location"
              id="location"
            ></textarea>
            <!-- <input matInput readonly formControlName="location"> -->
          </td>
        </tr>
        <tr>
          <td>
            <mat-label> Cabinet Type</mat-label>
          </td>
          <td>
            <span
              [ngClass]="applyDynamicclass('cabinetType') ? 'green' : 'red'"
            >
              <input
                matInput
                readonly
                formControlName="cabinetType"
                id="cabinetType"
              />
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <mat-label>Cabinet Serial Number</mat-label>
          </td>
          <td>
            <span class="green">
              <input
                class="normalCase"
                matInput
                readonly
                formControlName="cabinetSerialNumber"
                id="cabinetSerialNumber"
              />
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <mat-label>Cabinet State</mat-label>
          </td>
          <td>
            <span
              [ngClass]="applyDynamicclass('cabinetState') ? 'green' : 'red'"
            >
              <input
                matInput
                readonly
                formControlName="cabinetState"
                id="cabinetState"
              />
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <mat-label>Accessibility</mat-label>
          </td>
          <td>
            <span
              [ngClass]="applyDynamicclass('accessibility') ? 'green' : 'red'"
            >
              <input
                matInput
                readonly
                formControlName="accessibility"
                id="accessibility"
              />
            </span>
          </td>
        </tr>
        <tr *ngIf="isMonitored">
          <td>
            <mat-label>Activation Status</mat-label>
          </td>
          <td>
            DISARMED
            <mat-slide-toggle
              [disabled]="hasPrivilege(['ACCOUNT_STANDARD'])"
              [(ngModel)]="toggle"
              formControlName="AramedDisarmed"
              (click)="clickEvent()"
              id="AramedDisarmed"
            >
            </mat-slide-toggle>
            ARMED
          </td>
        </tr>
        <!-- <tr>
          <td>
            <mat-label>Status</mat-label>
          </td>
          <td>
            <span
              [ngClass]="
                deviceInformationForm.value.aedState === 'READY'
                  ? 'green'
                  : 'red'
              "
            >
              <input matInput readonly formControlName="aedState" />
            </span>
          </td>
        </tr> -->
        <tr *ngIf="isMonitored">
          <td>
            <mat-label>Installed by</mat-label>
          </td>
          <td>
            <span id="installedBy">{{
              deviceInformationForm.value.installedBy
            }}</span>
            <!-- <textarea rows="1" class="resizeNone" disabled="disabled" matInput readonly formControlName="installedBy"></textarea> -->
            <!-- <input matInput readonly formControlName="installedBy"> -->
          </td>
        </tr>
        <tr *ngIf="isMonitored">
          <td>
            <mat-label>Door</mat-label>
          </td>
          <td>
            <span [ngClass]="applyDynamicclass('door') ? 'green' : 'red'">
              <input matInput readonly formControlName="door" id="door" />
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <mat-label>AED State</mat-label>
          </td>
          <td>
            <!-- [ngClass]="
                deviceInformationForm.value.aedState === 'READY'
                  ? 'green'
                  : 'red'
              " -->
            <span [ngClass]="applyDynamicclass('aedState') ? 'green' : 'red'">
              <input
                matInput
                readonly
                formControlName="aedState"
                id="aedState"
              />
            </span>
            <!-- <span  [ngClass]="{'red': deviceInformationForm.value.aedState === 'MISSING','green': deviceInformationForm.value.aedState === 'READY', 'red' : deviceInformationForm.value.aedState === 'NOT READY' }" >
              <input matInput readonly formControlName="aedState" />
            </span> -->
          </td>
        </tr>
        <tr>
          <td>
            <mat-label>alert</mat-label>
          </td>
          <td>
            <span [ngClass]="applyDynamicclass('warning') ? 'green' : 'red'">
              <input matInput readonly formControlName="warning" id="warning" />
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <mat-label>Primary Adult Pad Expiry</mat-label>
          </td>
          <td>
            <span
              [ngClass]="
                applyDynamicclass('primaryAdultPadExpiry') ? 'red' : ''
              "
            >
              <input
                matInput
                readonly
                formControlName="primaryAdultPadExpiry"
              />
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <mat-label>Primary Battery Expiry</mat-label>
          </td>
          <td>
            <span
              [ngClass]="
                applyDynamicclass('primaryBatteryPadExpiry') ? 'red' : ''
              "
            >
              <input
                matInput
                readonly
                formControlName="primaryBatteryPadExpiry"
              />
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <mat-label>Primary Child Pad Expiry</mat-label>
          </td>
          <td>
            <span
              [ngClass]="
                applyDynamicclass('primaryChildPadExpiry') ? 'red' : ''
              "
            >
              <input
                matInput
                readonly
                formControlName="primaryChildPadExpiry"
              />
            </span>
          </td>
        </tr>

        <tr
          *ngIf="
            isMonitored &&
            (deviceInformationForm.controls.picOne.value ||
              deviceInformationForm.controls.picTwo.value ||
              deviceInformationForm.controls.picThree.value)
          "
        >
          <td colspan="2">IMAGES</td>
        </tr>
      </table>
      <div
        class="deviceDetailImages"
        *ngIf="
          isMonitored &&
          (deviceInformationForm.controls.picOne.value ||
            deviceInformationForm.controls.picTwo.value ||
            deviceInformationForm.controls.picThree.value)
        "
      >
        <div class="row">
          <div class="col-4">
            <img
              (click)="openModal(deviceInformationForm.controls.picOne.value)"
              [src]="deviceInformationForm.controls.picOne.value"
              alt="image"
            />
          </div>
          <div class="col-4">
            <img
              (click)="openModal(deviceInformationForm.controls.picTwo.value)"
              [src]="deviceInformationForm.controls.picTwo.value"
              alt="image"
            />
          </div>
          <div class="col-4">
            <img
              (click)="openModal(deviceInformationForm.controls.picThree.value)"
              [src]="deviceInformationForm.controls.picThree.value"
              alt="image"
            />
          </div>
        </div>
      </div>
    </div>
  </form>
  <div></div>
</div>
