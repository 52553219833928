import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AedService } from "../aedservice";
import { AlertService } from "../../core/services";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { CustomValidators } from "src/app/shared/validators";
import { Namings, CabinetStates } from "src/app/namings";
@Component({
  selector: "app-aeddisable",
  templateUrl: "./aeddisable.component.html",
  styleUrls: ["./aeddisable.component.scss"],
})
export class AEDDisableComponent implements OnInit {
  cabinetstate = CabinetStates;
  constructor(
    public aedService: AedService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    private TranslateService: TranslateService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}
  form = new FormGroup({
    Disabled: new FormControl(
      CabinetStates.temporary_disabled,
      Validators.required
    ),
    notes: new FormControl("", [
      Validators.required,
      CustomValidators.onlySpacesValidator(),
    ]),
  });

  submit() {
    this.aedService.disableNotes = this.form.value.notes.trim();
    this.aedService
      .updateCabinetStatus(
        this.data.aedSerialNumber,
        this.form.value.Disabled,
        this.form.value.notes.trim()
      )
      .subscribe(
        () => {
          this.alertService.showSuccess(
            this.TranslateService.instant("State Updated")
          );
          this.dialog.closeAll();
        },
        (err) => {
          this.alertService.showFailure(err.error.errors[0].errorMessage);
        }
      );
  }

  get f() {
    return this.form.controls;
  }

  get notes() {
    return this.form.controls.notes;
  }
}
