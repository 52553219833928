<mat-sidenav-container class="tp-container" fullscreen>
  <mat-tab-group
    #tabGroup
    [selectedIndex]="currentTabIndex"
    (selectedTabChange)="onTabChange($event)"
  >
    <mat-tab
      label="{{ 'cfl.ChangeUserInfo' | translate }}"
      [routerLinkActive]="['is-active']"
      [routerLinkActiveOptions]="{ exact: true }"
    >
    </mat-tab>
    <mat-tab
      *ngIf="hasPrivilege(['ROOT_ADMIN', 'ACCOUNT_ADMIN'])"
      label="{{ 'cfl.sideNav.menu.editAgency' | translate }}"
      [routerLinkActive]="['is-active']"
      [routerLinkActiveOptions]="{ exact: true }"
    >
    </mat-tab>
    <mat-tab
      label="{{ 'cfl.ChangePassword' | translate }}"
      [routerLinkActive]="['is-active']"
      [routerLinkActiveOptions]="{ exact: true }"
    >
    </mat-tab>
  </mat-tab-group>
  <router-outlet></router-outlet>
</mat-sidenav-container>
