import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css'],
})
export class ConfirmDialogComponent {

  heading: string;

  message: string;

  acceptButtonText: string;

  cancelButtonText: string;

  displaySecondButton: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

    this.heading = data.heading ? data.heading : 'CONFIRM';
    this.message = data.message ? data.message : 'Are you sure?';
    this.acceptButtonText = data.acceptButtonText ? data.acceptButtonText : 'Continue';
    this.cancelButtonText = data.cancelButtonText ? data.cancelButtonText : 'Cancel';
    this.displaySecondButton = (data.displaySecondButton != null) ? data.displaySecondButton : true;

  }

}
