<form
  [formGroup]="aedRegistrationForm"
  class="example-form"
  (submit)="onSubmit($event)"
>
  <div class="wrapper">
    <mat-form-field appearance="outline" class="des">
      <mat-label>Inspection Date</mat-label>
      <input
        matInput
        [matDatepicker]="picker"
        [min]="todayDate"
        id="sbe"
        formControlName="sbe"
        readonly
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker
        #picker
        startView="month"
        [startAt]="startDate"
        disabled="false"
      ></mat-datepicker>

      <mat-error *ngIf="getaedRegistrationFormControls.sbe.invalid">{{
        getsbeErrorMessages()
      }}</mat-error>
    </mat-form-field>
    <button
      mat-raised-button
      class="primary-button form-top-margin"
      [disabled]="!aedRegistrationForm.valid"
    >
      Submit
    </button>
  </div>
</form>
