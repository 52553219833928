import {
  trigger,
  sequence,
  state,
  stagger,
  animate,
  transition,
  style,
  query,
  animateChild,
  group,
} from "@angular/animations";

export const fadeOut = trigger("fadeOut", [
  state(
    "void",
    style({
      background: "pink",
      borderBottomColor: "pink",
      opacity: 0,
      transform: "translateX(-550px)",
      "box-shadow": "none",
    })
  ),
  transition("void => *", sequence([animate(".8s ease")])),
]);

export const rowsAnimation = trigger("rowsAnimation", [
  transition("void => *", [
    style({
      height: "*",
      opacity: "0",
      transform: "translateX(-550px)",
      "box-shadow": "none",
    }),
    sequence([
      animate(
        ".35s ease",
        style({
          height: "*",
          opacity: ".2",
          transform: "translateX(0)",
          "box-shadow": "none",
        })
      ),
      animate(
        ".35s ease",
        style({ height: "*", opacity: 1, transform: "translateX(0)" })
      ),
    ]),
  ]),
]);

export const blub = trigger("blub", [
  transition(":leave", [
    style({ background: "pink" }),
    query("*", stagger(-150, [animateChild()]), { optional: true }),
  ]),
]);

export const SlideInOutAnimation = [
  trigger('slideInOut', [
      state('in', style({
          'max-height': '500px', 'opacity': '1', 'visibility': 'visible'
      })),
      state('out', style({
          'max-height': '0px', 'opacity': '0', 'visibility': 'hidden'
      })),
      transition('in => out', [group([
          animate('400ms ease-in-out', style({
              'opacity': '0'
          })),
          animate('600ms ease-in-out', style({
              'max-height': '0px'
          })),
          animate('700ms ease-in-out', style({
              'visibility': 'hidden'
          }))
      ]
      )]),
      transition('out => in', [group([
          animate('1ms ease-in-out', style({
              'visibility': 'visible'
          })),
          animate('600ms ease-in-out', style({
              'max-height': '500px'
          })),
          animate('800ms ease-in-out', style({
              'opacity': '1'
          }))
      ]
      )])
  ]),
]
