<div class="app-delete-modal">
    <div *ngIf="showRuleDelete" class="text-center">
        <h2 mat-dialog-title style="text-align: center;">{{'cfl.settingsComponent.deleteModal.component.popup.rule.title' | translate}}</h2>
        <p style="text-align: center;size: 40px;">{{'cfl.settingsComponent.deleteModal.component.popup.rule.text' | translate}}</p>
        <button class="form-top-margin primary-button" mat-dialog-close mat-dialog-close="closed" mat-raised-button>{{'cfl.settingsComponent.deleteModal.component.popup.btn.delete' | translate}}</button>
    </div>

    <div *ngIf="showScaDelete" class="text-center">
        <h2 mat-dialog-title style="text-align: center;">{{'cfl.settingsComponent.deleteModal.component.popup.event.title' | translate}}</h2>
        <p style="text-align: center;size: 40px;">{{'cfl.settingsComponent.deleteModal.component.popup.event.text' | translate}}</p>

        <button class="form-top-margin primary-button" style=" padding: 2px 40px;outline: none;text-decoration: none;" mat-dialog-close mat-dialog-close="closed" mat-raised-button>{{'cfl.settingsComponent.deleteModal.component.popup.btn.delete' | translate}}</button>
    </div>

    <div *ngIf="showFobDelete" class="text-center">
        <h2 mat-dialog-title style="text-align: center;">{{'cfl.settingsComponent.deleteModal.component.popup.fob.title' | translate}}</h2>
        <p style="text-align: center;size: 40px;">{{'cfl.settingsComponent.deleteModal.component.popup.fob.text' | translate}}</p>

        <button class="form-top-margin primary-button" mat-dialog-close mat-dialog-close="closed" mat-raised-button>{{'cfl.settingsComponent.deleteModal.component.popup.btn.delete' | translate}}</button>
    </div>

    <div *ngIf="showAgencyDelete" class="text-center">
        <h2 mat-dialog-title>{{'cfl.settingsComponent.deleteModal.component.popup.Agency.title' | translate}}</h2>
        <p>{{'cfl.settingsComponent.deleteModal.component.popup.Agency.text' | translate}}</p>

        <button class="form-top-margin primary-button" mat-dialog-close mat-dialog-close="closed" mat-raised-button>{{'cfl.settingsComponent.deleteModal.component.popup.btn.delete' | translate}}</button>
    </div>

    <div *ngIf="showUserDelete" class="text-center">
        <h2 mat-dialog-title style="text-align: center;">{{'cfl.settingsComponent.deleteModal.component.popup.User.title' | translate}}</h2>
        <p style="text-align: center;size: 40px;">{{'cfl.settingsComponent.deleteModal.component.popup.User.text' | translate}}</p>

        <button class="form-top-margin primary-button" mat-dialog-close mat-dialog-close="closed" mat-raised-button>{{'cfl.settingsComponent.deleteModal.component.popup.btn.delete' | translate}}</button>
    </div>

    <div *ngIf="showIbuDelete" class="text-center">
        <h2 mat-dialog-title style="text-align: center;">{{'cfl.settingsComponent.deleteModal.component.popup.User.title' | translate}}</h2>
        <p style="text-align: center;size: 40px;">{{'cfl.settingsComponent.deleteModal.component.popup.Device.ibu' | translate}}</p>

        <button class="form-top-margin primary-button" mat-dialog-close mat-dialog-close="closed" mat-raised-button>{{'cfl.settingsComponent.deleteModal.component.popup.btn.delete' | translate}}</button>
    </div>
</div>