import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "../../../core/services";
import { UserService } from "../../../settings/users/user.service";
import { AuthService } from "../../../core/services/auth.service";
import { CustomValidators } from "../../../shared/validators";
@Component({
  selector: "change-info",
  templateUrl: "./change-info.component.html",
  styleUrls: ["./change-info.component.css"],
})
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["tabChanged"]}] */
export class ChangeInfoComponent implements OnInit {
  changeUserNameForm: FormGroup;
  disableSubmit = false;
  agencyPicture: string;
  ngxLoading: boolean;
  saveDisable: boolean = true;
  lastUpdatedValue = {
    firstName: "",
    lastName: "",
  };

  constructor(
    public alertService: AlertService,
    private authService: AuthService,
    private fb: FormBuilder,
    private translationService: TranslateService,
    private userService: UserService
  ) {
    this.agencyPicture = localStorage.getItem("agencyLogo");
  }

  ngOnInit() {
    this.createUserInfoForm();
    this.disbaleSubmitButton();
    this.lastUpdatedValue = {
      firstName: localStorage.getItem("firstName").trim(),
      lastName: localStorage.getItem("lastName").trim(),
    };
  }

  disbaleSubmitButton() {
    this.changeUserNameForm.valueChanges.subscribe((value) => {
      this.saveDisable = true;
      value.firstName = value.firstName.trim();
      value.lastName = value.lastName.trim();
      if (value.firstName != "" && value.lastName != "") {
        const data = this.lastUpdatedValue;
        if (
          Object.values(value).toLocaleString() ==
          Object.values(data).toLocaleString()
        ) {
          this.saveDisable = true;
        } else {
          this.saveDisable = false;
        }
      }
    });
  }

  createUserInfoForm() {
    this.changeUserNameForm = this.fb.group({
      userName: [
        localStorage.getItem("email"),
        [
          Validators.minLength(3),
          Validators.email,
          CustomValidators.emailPattern,
        ],
      ],
      firstName: [
        localStorage.getItem("firstName") !== "null"
          ? localStorage.getItem("firstName")
          : null,
      ],
      lastName: [
        localStorage.getItem("lastName") !== "null"
          ? localStorage.getItem("lastName")
          : null,
      ],
    });
    this.changeUserNameForm.controls.userName.disable();
  }

  // make body a/c to api and send user entered data to api
  submitChangeUsername() {
    if (this.changeUserNameForm.invalid) {
      this.changeUserNameForm.markAllAsTouched();
      return;
    }
    const body = {
      userName: this.changeUserNameForm.get("userName").value,
      firstName: this.changeUserNameForm.get("firstName").value.trim(),
      lastName: this.changeUserNameForm.get("lastName").value.trim(),
      languageCode: localStorage.getItem("preferredLanguage"),
    };

    this.ngxLoading = true;
    return this.userService.changeUsername(body).subscribe(
      () => {
        this.ngxLoading = false;
        this.userService.userData.firstName = body.firstName;
        this.userService.userData.lastName = body.lastName;
        localStorage.setItem("firstName", body.firstName);
        localStorage.setItem("lastName", body.lastName);
        this.lastUpdatedValue = {
          firstName: body.firstName,
          lastName: body.lastName,
        };

        const data = this.changeUserNameForm.value;

        if (
          Object.values(data).toLocaleString() ===
          Object.values(data).toLocaleString()
        ) {
          this.saveDisable = true;
        } else {
          this.saveDisable = false;
        }
        this.alertService.showSuccess(
          this.translationService.instant(
            "cfl.settingsComponent.tab.success.message.accountUpdated"
          )
        );
      },
      (err: HttpErrorResponse) => {
        if (
          err.error.text ===
          "A verification email has been send to you.Kindly processed."
        ) {
          this.alertService.showSuccess(
            this.translationService.instant(
              "cfl.settingsComponent.tab.success.message.emailSent"
            )
          );
        }

        if (err.error.text === "user account information has been updated") {
          this.alertService.showSuccess(
            this.translationService.instant(
              "cfl.settingsComponent.tab.success.message.accountUpdated"
            )
          );
        }
        if (
          err.error.text ===
          "This email/user name is already  assigned to some other user"
        ) {
          this.alertService.showFailure(
            this.translationService.instant(
              "cfl.settingsComponent.tab.failure.message.alreadyAssigned"
            )
          );
        }

        if (err.error.text === "Un-Authorized User") {
          this.alertService.showFailure(
            this.translationService.instant(
              "cfl.settingsComponent.tab.error.message.invalidIdPassword"
            )
          );
        }
        if (err.status === 400) {
          this.alertService.showFailure(err.error);
        }
      }
    );
  }

  hasPrivilege(privileges: string[]): boolean {
    return this.authService.hasPrivilege(privileges);
  }

  get getChangeUserNameFormControls() {
    return this.changeUserNameForm.controls;
  }

  FirstNameError() {
    return this.changeUserNameForm.controls.firstName.hasError("required")
      ? this.translationService.instant("cfl.Firstnameisrequired")
      : "";
  }
  LastNameError() {
    return this.changeUserNameForm.controls.lastName.hasError("required")
      ? this.translationService.instant("cfl.Lastnameisrequired")
      : "";
  }
}
