import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MonitoringComponent } from 'src/app/aed/active-devices/monitoring/monitoring.component';
import { AedComponent } from 'src/app/aed/aed.component';
import { DashboardComponent } from 'src/app/dashboard/dashboard.component';
import { SettingsComponent } from 'src/app/settings/settings.component';
import { SidebarComponent } from '../../sidebar.component';


const routes: Routes = [
  { path : '' , component : SidebarComponent , children : [
    {path : 'dashboard' , component : DashboardComponent},
    {path :'devices', component : MonitoringComponent},
    {path : 'settings',component : SettingsComponent},
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SidebarModuleRoutingModule { }
