import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "../../../core/services";
import { MustMatch } from "../../../helpers/must-match.validator";
import { UserService } from "../../../settings/users/user.service";
import { Router } from "@angular/router";
import { CustomValidators } from "src/app/shared/validators";
import { AppConstants } from "src/app/core/utilities/constants";
import { AuthService } from "../../../core/services/auth.service";
import { SharedService } from "../../../core/services/shared.service";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.css"],
})
export class ChangePasswordComponent implements OnInit {
  isLoading = false;

  loggedInUser: string;

  hideCurrentPassword = true;

  hideNewPassword = true;

  hideConfirmPassword = true;

  changePasswordForm: FormGroup;

  passwordMaxLength = AppConstants.passwordMaxLength;

  errorType = AppConstants.errorType;

  constructor(
    public fb: FormBuilder,
    public sharedService: SharedService,
    public translationService: TranslateService,
    public alertService: AlertService,
    public userService: UserService,
    public authService: AuthService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.changePasswordForm = this.fb.group(
      {
        oldPassword: ["", [Validators.required]],
        newPassword: [
          "",
          [Validators.required, CustomValidators.passwordValidator()],
        ],
        confirmPassword: ["", [Validators.required]],
      },
      {
        validator: MustMatch("newPassword", "confirmPassword"),
      }
    );
  }

  // send user entered data to api
  submitChangePassword() {
    this.isLoading = true;
    this.loggedInUser = localStorage.getItem("email");
    const body = {
      oldPassword: this.changePasswordForm.get("oldPassword").value,
      newPassword: this.changePasswordForm.get("newPassword").value,
      confirmPassword: this.changePasswordForm.get("confirmPassword").value,
      userName: this.loggedInUser,
    };

    return this.userService.changePassword(body).subscribe(
      () => {
        this.changePasswordForm.reset();
        this.isLoading = false;
        this.alertService.showSuccess(
          this.translationService.instant(
            "cfl.settingsComponent.tab.success.message.passwordUpdated"
          )
        );
        // window.location.reload(); this.userService.logout().subscribe(() => {
        this.router.navigate(["/signin"]);
      },
      (err: HttpErrorResponse) => {
        this.isLoading = false;
        if (err.error.errors[0].errorCode === "AUTH-0006") {
          this.alertService.showFailure(
            this.translationService.instant(
              "cfl.settingsComponent.tab.error.message.invalidCurrentPassword"
            )
          );
        } else if (err.error.errors[0].errorCode === "US-0015") {
          this.alertService.showFailure(
            this.translationService.instant(
              "cfl.settingsComponent.tab.error.message.sameNewOldPassword"
            )
          );
        }
      }
    );
  }

  get changePasswordControls() {
    return this.changePasswordForm.controls;
  }

  getOldPasswordErrorMessages() {
    return this.changePasswordForm.controls.oldPassword.hasError("required")
      ? this.translationService.instant("cfl.error.required", {
          value: this.translationService.instant(
            "cfl.settingsComponent.tab.changeInfo.group.field.currentPassword.placeholder"
          ),
        })
      : "";
  }

  getConfirmPasswordErrorMessages() {
    return this.changePasswordForm.controls.confirmPassword.hasError(
      "mustMatch"
    )
      ? this.translationService.instant("cfl.error.doesNotMatch")
      : this.changePasswordForm.controls.confirmPassword.hasError("required")
      ? this.translationService.instant("cfl.error.required", {
          value: this.translationService.instant(
            "cfl.settingsComponent.tab.changeInfo.group.field.confirmPassword.placeholder"
          ),
        })
      : "";
  }
  getErrorClass(e: string): string {
    const control = this.changePasswordForm.controls["newPassword"];
    return control.hasError("required") || control.hasError(e)
      ? ""
      : "text-success";
  }

  getErrorMessage(e: string): string {
    const translationKey = `cfl.error.${e}`;
    const placeholderKey = "cfl.password.placeholder";
    return this.translationService.instant(translationKey, {
      value: this.translationService.instant(placeholderKey),
    });
  }
}
