import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, NavigationEnd } from "@angular/router";
import { MatIconRegistry } from "@angular/material/icon";
import { AuthService } from "../../core/services/auth.service";
import { AlertService } from "../../core/services/index";
import { UserService } from "../../settings/users/user.service";
import { NotificationsService } from "../../notifications/notifications.service";

import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

import { Observable, Subscription } from "rxjs";

import { map, shareReplay, timeInterval } from "rxjs/operators";
import { style } from "@angular/animations";
import { AgencyService } from "src/app/agency/agency.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  notificationCounter;
  message;
  value: number;
  element: HTMLElement;
  isSetPassowrd = false;
  isExpanded = false;
  yourNumber = 100;
  title = "ositech";
  agencySubscription$: Subscription;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
  ngxLoading: boolean = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    public authService: AuthService,
    public alertService: AlertService,
    private messagingService: AuthService,
    public translation: TranslateService,
    public router: Router,
    private userService: UserService,
    public notificationsService: NotificationsService,
    public agencyService: AgencyService
  ) {
    this.notificationCounter = 0;
  }

  ngOnInit() {
    this.getAgencyDetail();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isSetPassowrd =
          event.url.includes("setPassword") ||
          event.url.includes("confirmPassword") ||
          event.url.includes("agencyRegistration") ||
          event.url.includes("signin");
      }
    });

    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage;
    let language = localStorage.getItem("preferredLanguage");
    if (language !== "fr") {
      language = "en";
    }
    if (language) this.translation.use(language);
  }

  hasPrivilege(privileges: string[]): boolean {
    return this.authService.hasPrivilege(privileges);
  }

  updateNotificationCount() {
    this.notificationsService
      .getallAlarms(0, 1, true)
      .subscribe((data: any) => {
        this.notificationsService.sharedNotificationCount.next(
          data.body.badgeCount
        );
      });
  }

  getAgencyDetail() {
    this.ngxLoading = true;
    this.userService.getAgencyDetail().subscribe((res) => {
      this.ngxLoading = false;
      if (!this.hasPrivilege(["ROOT_ADMIN"])) {
        localStorage.setItem("agencyId", res.body.agencyId);
        this.agencyService.setAgencyName(res.body.agency);
      }

      if (!this.userService.userData.firstName) {
        this.userService.userData.firstName = res.body.firstName;
        this.userService.userData.lastName = res.body.lastName;
        this.userService.userData.email = res.body.email;
        this.userService.userData.role = res.body.roles[0].replace("_", " ");
      }

      localStorage.setItem("firstName", res.body.firstName);
      localStorage.setItem("lastName", res.body.lastName);
      localStorage.setItem("email", res.body.email);
      localStorage.setItem("role", res.body.roles[0]);
      // // received whenever notification is generated from firebase
      // this.authService.getRealTimeUpdate().subscribe(() => {
      //   this.updateNotificationCount();
      // });
    });
  }

  isLogin(): boolean {
    return this.authService.isLogin();
  }

  onLogout() {
    this.userService.logout().subscribe(() => {
      const language = localStorage.getItem("preferredLanguage");
      localStorage.clear();
      if (language) {
        localStorage.setItem("preferredLanguage", language);
        this.translation.use(language);
      }
      this.router.navigate(["/signin"]);
    });
  }
}
