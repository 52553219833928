import { Component, OnInit } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "../core/services/index";
import { MustMatch } from "../helpers/must-match.validator";
import { UserService } from "../settings/users/user.service";
import { CustomValidators } from "../shared/validators";
import { AppConstants } from "../core/utilities/constants";
import { SharedService } from "../core/services/shared.service";
import { AuthService } from "../core/services/auth.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
  hide1 = true;

  hide2 = true;

  hide3 = true;

  forgotPasswordForm: FormGroup;

  newPassword: string;

  confirmPassword: string;
  vCode: string;

  userName: string;

  status: number;

  passwordMaxLength = AppConstants.passwordMaxLength;

  errorType = AppConstants.errorType;

  getsetPasswordErrorMessages() {
    return this.forgotPasswordForm.controls.newPassword.hasError("required")
      ? this.translation.instant("cfl.error.required", {
          value: this.translation.instant(
            "cfl.form.forgot.component.password.placeholder"
          ),
        })
      : this.forgotPasswordForm.controls.newPassword.hasError("minlength")
      ? this.translation.instant("cfl.error.pattern")
      : this.forgotPasswordForm.controls.newPassword.hasError("pattern")
      ? this.translation.instant("cfl.error.pattern")
      : "";
  }

  getconfirmPasswordErrorMessages() {
    return this.forgotPasswordForm.controls.confirmPassword.hasError(
      "mustMatch"
    )
      ? this.translation.instant("cfl.error.doesNotMatch")
      : // :this.forgotPasswordForm.controls.confirmPassword.hasError('required') ? this.translation.instant('cfl.error.required', { value: this.translation.instant('cfl.confirm.placeholder', { value: this.translation.instant('cfl.form.forgot.component.password.placeholder') }) })
        "";
  }

  getvCodeErrorMessages() {
    return this.forgotPasswordForm.controls.vCode.hasError("required")
      ? this.translation.instant("cfl.error.required", {
          value: this.translation.instant(
            "cfl.form.forgot.component.verificationCode.placeholder"
          ),
        })
      : "";
  }

  get setPasswordControls() {
    return this.forgotPasswordForm.controls;
  }

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private Auth: AuthService,
    http: HttpClient,
    private alertService: AlertService,
    public sharedService: SharedService,
    public translation: TranslateService,
    public userService: UserService
  ) {}

  ngOnInit() {
    this.userName = localStorage.getItem("userName");
    this.forgotPasswordForm = this.fb.group(
      {
        newPassword: [
          "",
          [Validators.required, CustomValidators.passwordValidator()],
        ],
        confirmPassword: ["", [Validators.required]],
        vCode: ["", Validators.required],
      },
      {
        validator: MustMatch("newPassword", "confirmPassword"),
      }
    );
    setTimeout(() => {
      localStorage.removeItem("IsforgetEnable");
    }, 1000);
  }

  // first hitting an api to verify token; if success than hit another api
  // to submit the user entered data
  verifyToken() {
    this.vCode = this.forgotPasswordForm.get("vCode").value;

    return this.userService.verifyTokenV2(this.vCode).subscribe(
      (res: any) => {
        this.vCode = res.body;
        this.onSubmit();
      },
      (err) => {
        if (err.error.errors[0].errorCode === "EXATT-0003") {
          this.alertService.showFailure(
            this.translation.instant("cfl.error.invalidToken")
          );
        } else if (err.error.errors[0].errorCode === "EXATT-0002") {
          this.alertService.showFailure(
            this.translation.instant("cfl.error.tokenExpired")
          );
        }
      }
    );
  }

  // if token is verified than this function is called and user input
  // data is saved through api
  onSubmit() {
    this.newPassword = this.forgotPasswordForm.get("newPassword").value;
    this.confirmPassword = this.forgotPasswordForm.get("confirmPassword").value;

    const body = {
      confirmPassword: this.confirmPassword,
      newPassword: this.newPassword,
    };

    return this.userService.forgotPassword(body, this.vCode).subscribe(() => {
      this.alertService.showSuccess(
        this.translation.instant("cfl.forgot.success.message", {
          value: this.userName,
        })
      );
      this.router.navigate(["/signin"]);
    });
  }

  getErrorClass(errorType: string): string {
    const control = this.forgotPasswordForm.controls["newPassword"];
    return control.hasError("required") || control.hasError(errorType)
      ? ""
      : "text-success";
  }

  getErrorMessage(errorType: string): string {
    const translationKey = `cfl.error.${errorType}`;
    const placeholderKey = "cfl.password.placeholder";
    return this.translation.instant(translationKey, {
      value: this.translation.instant(placeholderKey),
    });
  }
}
