import { TOUCH_BUFFER_MS } from "@angular/cdk/a11y";
import { Component, Input, OnInit } from "@angular/core";
import { FilterService } from "src/app/core/services/filter.service";
import { Filter } from "src/app/shared/model/Filter";
import { blub, fadeOut, rowsAnimation } from "src/app/shared/animation/matrow";

@Component({
  selector: "app-filter-child",
  templateUrl: "./filter-child.component.html",
  styleUrls: ["./filter-child.component.css"],
  animations: [fadeOut, blub, rowsAnimation],
})
export class FilterChildComponent implements OnInit {
  constructor(public filterService: FilterService) {}

  ngOnInit(): void {}

  removeSingleFilter(value) {
    let isClearSerach = false;

    let filterIndex = this.filterService.selectedFilter.findIndex(
      (f) => f.id == value.id
    );
    this.filterService.selectedFilter.splice(filterIndex, 1);
    if (value.category != "textsearch") {
      let index = this.filterService.dashboardFilters[value.category].findIndex(
        (i) => i == value.value
      );
      this.filterService.dashboardFilters[value.category].splice(index, 1);
    } else {
      this.filterService.dashboardFilters["searchText"] = "";
      isClearSerach = true;
    }

    this.filterService.removeFilter.emit({
      removeFilter: true,
      isClearAll: false,
      isClearSerach,
    });
  }
  removeAllFilters() {
    this.filterService.selectedFilter = [];
    this.filterService.dashboardFilters = new Filter();
    this.filterService.removeFilter.emit({
      removeFilter: true,
      isClearAll: true,
      isClearSerach: true,
    });
  }
}
