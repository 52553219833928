import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppGlobals } from '../app.global';
@Injectable({
  providedIn: 'root',
})

export class DeviceService {

  baseURLc4life: string;

  constructor(protected http: HttpClient, private appGlobal: AppGlobals) {

    this.baseURLc4life = this.appGlobal.baseApiUrl;

  }

  getFilteredDevices(category, value, value2) {

    const url = `${this.baseURLc4life}device/filterby?search=${category}==${value}&pendingCabinets=${value2}`;
    return this.http.get(url);

  }

  getIbuFilteredDevices(category, value) {

    const url = `${this.baseURLc4life}device/filterIbu?search=${category}==${value}`;
    return this.http.get(url);

  }

  getAllDevices(pendingCabinets, pageNo: number, pageSize: number) {
    return this.http.get(`${this.baseURLc4life}device/v2/get/all`,

      {
        params: new HttpParams()
          .set('pendingCabinets', pendingCabinets)
          .set('page', pageNo.toString())
          .set('size', pageSize.toString()),
      });

  }

  getUnAssignedDevices() {

    return this.http.get(`${this.baseURLc4life}device/get/unAssigned-devices`);

  }

  setAramedDevices(Activationstatus, Deviceserialnumber) {

    const body = {
      'activationStatus': Activationstatus,
      'deviceSerialNumber': Deviceserialnumber,
      'disarmDuration': 0
    }
    let Duration = 0;
    return this.http.post(`${this.baseURLc4life}aed/v2/cabinet-activation?activationStatus=${Activationstatus}&deviceSerialNumber=${Deviceserialnumber}&disarmDuration=${Duration}`, body);
  }
  setDisaramedDevices(Activationstatus, Deviceserialnumber) {
    const body = {
      'activationStatus': Activationstatus,
      '&deviceSerialNumber': Deviceserialnumber,
      '&disarmDuration': 30
    }
    let Duration = 30;
    return this.http.post(`${this.baseURLc4life}aed/v2/cabinet-activation?activationStatus=${Activationstatus}&deviceSerialNumber=${Deviceserialnumber}&disarmDuration=${Duration}`, body);
  }

}
