import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppGlobals } from "../../app.global";

@Injectable({
  providedIn: "root",
})
export class UserService {
  baseURLc4life: string;
  isPicture: boolean;
  userData: any = {};

  constructor(protected http: HttpClient, private appGlobal: AppGlobals) {
    this.baseURLc4life = this.appGlobal.baseApiUrl;
  }

  getAgencyUsers(enable) {
    const agencyId = localStorage.getItem("agencyId");
    return this.http.get(
      `${this.baseURLc4life}user/get/all-agency-users?agencyId=${agencyId}&enable=${enable}`
    );
  }
  getAgencyFobUsers() {
    const agencyId = localStorage.getItem("agencyId");
    return this.http.get(
      `${this.baseURLc4life}fob/get/all-agency-users-fob?agencyId=${agencyId}`
    );
  }

  changePassword(body) {
    return this.http.post(`${this.baseURLc4life}user/update-password`, body);
  }

  changeUsername(body) {
    return this.http.put(`${this.baseURLc4life}user/update-account`, body);
  }

  getAgencyDetail() {
    return this.http.get<any>(`${this.baseURLc4life}user/me`);
  }

  login(body): any {
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });

    return this.http.post(`${this.baseURLc4life}auth/login`, body, {
      headers: httpHeaders,
    });
  }

  logout(body?) {
    return this.http.post(`${this.baseURLc4life}user/logout`, body);
  }

  deleteUser(name) {
    return this.http.delete(
      `${this.baseURLc4life}user/delete?userName=${name}`
    );
  }

  changeUserStatus(action, username): any {
    return this.http.post(
      `${this.baseURLc4life}user/change/user-status?action=${action}&userName=${username}`,
      null
    );
  }

  changeUserType(accountType, username, body): any {
    return this.http.post(
      `${this.baseURLc4life}/user/change/account-type?userName=${username}&accountType=${accountType}`,
      body
    );
  }

  setPasswordForAgencyUser(body): any {
    return this.http.post(`${this.baseURLc4life}user/set-password`, body);
  }

  setPassword(body): any {
    const agencyId = localStorage.getItem("agencyId");
    return this.http.post(
      `${this.baseURLc4life}user/reset-password-agency-admin?agencyId=${agencyId}`,
      body
    );
  }

  addAgencyUser(body): any {
    return this.http.post(`${this.baseURLc4life}user/create?`, body);
  }

  verifyToken(token) {
    return this.http.get<any>(`${this.baseURLc4life}GA/verify-token/${token}`);
  }
  verifyTokenV2(token) {
    let email = localStorage.getItem("userName");
    const body = { confirmationCode: token, email: email };
    return this.http.post(`${this.baseURLc4life}auth/verify-token/`, body);
  }

  refreshToken(token) {
    const body = { token };
    return this.http.post(`${this.baseURLc4life}user/refreshToken/`, body);
  }

  forgotPassword(body, token): any {
    return this.http.post(
      `${this.baseURLc4life}GA/reset-password/${token}`,
      body
    );
  }

  sendVerificationToken(email) {
    return this.http.get(`${this.baseURLc4life}GA/forgot-password/${email}`);
  }

  addRole(role, userName) {
    return this.http.post(
      `${this.baseURLc4life}user/add/role?role=${role}&userName=${userName}`,
      {}
    );
  }

  unassignRole(role, userName) {
    return this.http.post(
      `${this.baseURLc4life}user/unassign/role?role=${role}&userName=${userName}`,
      {}
    );
  }

  getVersion() {
    return this.http.get(`${this.baseURLc4life}user/version`);
  }

  clearUserData() {
    this.userData = {};
  }
}
