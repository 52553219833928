<div class="container">
  <div class="verticalDiv">
    <div class="main">
      <br />
      <h2 class="heading text-center">Set Password</h2>
      <br />
    </div>
    <div class="signInForm">
      <form
        [formGroup]="setChangePasswordForm"
        class="example-form"
        autocomplete="off"
      >
        <div class="text-center">
          <img
            src="../assets/images/password.png"
            class="headingIcon"
            alt="Password"
          />
        </div>
        <br />

        <div class="formField">
          <mat-form-field appearance="outline">
            <input
              matInput
              placeholder="{{
                'cfl.new.placeholder'
                  | translate
                    : {
                        value:
                          'cfl.form.setPassword.component.password.placeholder'
                          | translate
                      }
              }}"
              [type]="hide2 ? 'password' : 'text'"
              id="newPassword"
              formControlName="newPassword"
              [maxlength]="passwordMaxLength"
            />
            <mat-icon
              matSuffix
              (click)="hide2 = !hide2"
              style="cursor: pointer"
            >
              {{ hide2 ? "visibility_off" : "visibility" }}
            </mat-icon>
          </mat-form-field>
        </div>

        <div class="formField">
          <mat-form-field appearance="outline">
            <input
              matInput
              placeholder="{{
                'cfl.confirm.placeholder'
                  | translate
                    : {
                        value:
                          'cfl.form.setPassword.component.password.placeholder'
                          | translate
                      }
              }}"
              [type]="hide3 ? 'password' : 'text'"
              id="confirmPassword"
              formControlName="confirmPassword"
              [maxlength]="passwordMaxLength"
            />
            <mat-icon
              matSuffix
              (click)="hide3 = !hide3"
              style="cursor: pointer"
              >{{ hide3 ? "visibility_off" : "visibility" }}</mat-icon
            >
          </mat-form-field>
          <div class="errorDivFormField">
            <ng-container *ngFor="let e of errorType">
              <mat-error [ngClass]="getErrorClass(e)">
                • {{ getErrorMessage(e) }}
              </mat-error>
            </ng-container>

            <mat-error
              *ngIf="
                setChangePasswordControls.confirmPassword.invalid &&
                setChangePasswordControls.confirmPassword.touched
              "
            >
              {{ getconfirmPasswordErrorMessages() }}
            </mat-error>
          </div>
        </div>
      </form>
    </div>

    <br />
    <div class="formField text-center">
      <button
        class="primary-button"
        mat-raised-button
        id="submitSetPassword"
        [disabled]="!setChangePasswordForm.valid"
        (click)="onSubmit()"
      >
        {{ "cfl.form.setPassword.component.btn.submit" | translate }}
      </button>
    </div>
  </div>
</div>
