import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "../core/services/index";
import { CustomValidators } from "../shared/validators";
import { SharedService } from "../core/services/shared.service";
import { AgencyService } from "../agency/agency.service";

@Component({
  selector: "app-dialog-example",
  templateUrl: "./dialog-example.component.html",
  styleUrls: ["./dialog-example.component.css"],
})
export class DialogExampleComponent implements OnInit {
  employeeForm: FormGroup;
  isLoading = false;

  constructor(
    public alertService: AlertService,
    private dialogRef: MatDialogRef<DialogExampleComponent>,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private translation: TranslateService,
    private agencyService: AgencyService,
    private router: Router
  ) {}

  ngOnInit() {
    this.employeeForm = this.fb.group({
      fullName: [
        "",
        [
          Validators.required,
          Validators.minLength(2),
          Validators.pattern(this.sharedService.specialCharactersValid),
        ],
      ],
      email: [
        "",
        [Validators.required, Validators.email, CustomValidators.emailPattern],
      ],
    });
  }

  // submit user entered data to api
  onSubmit3() {
    this.isLoading = true;
    const body = {
      name: this.fullName.value.trim(),
      primaryEmail: this.email.value,
      url: `${window.location.protocol}//${window.location.host}`,
    };

    return this.agencyService.inviteAgency(body).subscribe(
      () => {
        this.isLoading = false;
        this.alertService.showSuccess(
          this.translation.instant("cfl.dialog.emailSent")
        );
        this.dialogRef.close("update");
      },
      (err) => {
        this.isLoading = false;
        if (err.error.errors[0].errorCode === "APP-0010") {
          this.alertService.showFailure("Access Denied");
          this.router.navigate(["/dashboard"]);
          this.dialogRef.close("error");
          return;
        }
        this.alertService.showFailure(err.error.errors[0].errorMessage);
      }
    );
  }

  get fullName() {
    return this.employeeForm.controls.fullName;
  }

  get email() {
    return this.employeeForm.controls.email;
  }
}
