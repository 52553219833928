<div class="modal-wrapper">
  <h2 mat-dialog-title class="text-center">
    {{ "cfl.adminComponent.header.button.inviteAgency" | translate }}
  </h2>
  <form [formGroup]="employeeForm">
    <mat-form-field
      appearance="outline"
      [class.mat-form-field-invalid]="
        fullName.touched && !fullName.pristine && fullName.invalid
      "
    >
      <mat-label>
        {{ "cfl.form.dialog.component.companyName.placeholder" | translate }}
      </mat-label>
      <input matInput id="fullName" formControlName="fullName" />
    </mat-form-field>
    <ng-container *ngIf="fullName.touched && !fullName.pristine">
      <mat-error *ngIf="fullName.hasError('required')">
        {{
          alertService.showRequiredFieldMessage(
            "cfl.form.dialog.component.name.placeholder" | translate
          )
        }}
      </mat-error>
      <mat-error
        *ngIf="!fullName.hasError('required') && fullName.hasError('minlength')"
      >
        {{
          alertService.minLengthFieldMessage(
            "cfl.form.dialog.component.name.placeholder" | translate
          )
        }}
      </mat-error>
      <mat-error
        *ngIf="!fullName.hasError('minlength') && fullName.hasError('pattern')"
      >
        {{ alertService.showSpecialCharactersMessage() }}
      </mat-error>
    </ng-container>
    <mat-form-field
      appearance="outline"
      class="form-top-margin"
      [class.mat-form-field-invalid]="
        email.touched && !email.pristine && email.invalid
      "
    >
      <mat-label>
        {{ "cfl.form.dialog.component.email.placeholder" | translate }}
      </mat-label>
      <input matInput id="email" formControlName="email" />
    </mat-form-field>
    <ng-container *ngIf="email.touched && !email.pristine">
      <mat-error *ngIf="email.hasError('required') && email.touched">
        {{
          alertService.showRequiredFieldMessage(
            "cfl.form.dialog.component.email.placeholder" | translate
          )
        }}
      </mat-error>
      <mat-error *ngIf="!email.hasError('required') && email.hasError('email')">
        {{ alertService.showInvalidEmailMessage() }}
      </mat-error>
    </ng-container>
  </form>
  <div class="form-top-margin text-center">
    <button
      mat-raised-button
      *ngIf="!isLoading"
      class="form-top-margin"
      id="submitAgencyInfo"
      (click)="onSubmit3()"
      class="primary-button"
      [disabled]="!employeeForm.valid"
    >
      {{ "cfl.form.dialog.modal.btn.send" | translate }}
    </button>

    <button
      *ngIf="isLoading"
      class="form-top-margin"
      [disabled]="true"
      mat-raised-button
      class="primary-button"
    >
      <mat-progress-spinner
        diameter="32"
        *ngIf="isLoading"
        mode="indeterminate"
      ></mat-progress-spinner>
    </button>
  </div>
</div>
