<mat-accordion
  class="dashboardFooter mat-elevation-z8"
  [togglePosition]="'center'"
>
  <mat-expansion-panel class="dasboard-footer-bg">
    <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
    </mat-expansion-panel-header>

    <div class="iconInfoAccordion">
      <table aria-hidden="true" role="presentation">
        <tr>
          <td>
            <div class="iconInfo">
              <img
                src="../../../assets/map-icons/NonC4l_Ready.svg"
                alt="Unmonitored"
              />
            </div>
          </td>
          <td>Unmonitored</td>
        </tr>
        <tr>
          <td>
            <div class="iconInfo">
              <img
                src="../../../assets/map-icons/C4L_ReadyDas.svg"
                alt="Ready"
              />
            </div>
          </td>
          <td>Ready</td>
        </tr>
        <tr>
          <td>
            <div class="iconInfo">
              <img
                src="../../../assets/map-icons/Not_ReadyDas.svg"
                alt="Not Ready"
              />
            </div>
          </td>
          <td>Not Ready</td>
        </tr>
        <tr>
          <td>
            <div class="iconInfo">
              <img
                src="../../../assets/map-icons/C4l_Missing.svg"
                alt="No AED"
              />
            </div>
          </td>
          <td>No AED</td>
        </tr>
        <tr>
          <td>
            <div class="iconInfo">
              <img src="../../../assets/map-icons/disable.svg" alt="Disabled" />
            </div>
          </td>
          <td>Disabled</td>
        </tr>
        <tr>
          <td>
            <div class="iconInfo">
              <img
                src="../../../assets/revamp-images/icon/door-open.svg"
                alt="Door Open"
              />
            </div>
          </td>
          <td>Door Open</td>
        </tr>
        <tr>
          <td>
            <div class="iconInfo">
              <img
                src="../../../assets/revamp-images/icon/warning.svg"
                alt="Alert"
              />
            </div>
          </td>
          <td>Alert</td>
        </tr>
        <tr>
          <td>
            <div class="iconInfo">
              <img
                src="../../../assets/revamp-images/icon/in-accessible.svg"
                alt="Location Closed"
              />
            </div>
          </td>
          <td>Location Closed</td>
        </tr>
        <tr>
          <td>
            <div class="iconInfo2">
              <img src="../../../assets/map-icons/Qr_flag.svg" alt="QR Code" />
            </div>
          </td>
          <td>QR Code</td>
        </tr>
        <tr>
          <td>
            <div class="iconInfo">
              <img
                src="../../../assets/map-icons/Ready_ForceUpdate.svg"
                alt="Force Update (Ready)"
              />
            </div>
          </td>
          <td>Force Update (Ready)</td>
        </tr>
        <tr>
          <td>
            <div class="iconInfo">
              <img
                src="../../../assets/map-icons/NotReady_ForceUpdate.svg"
                alt="Force Update (Not Ready)"
              />
            </div>
          </td>
          <td>Force Update (Not Ready)</td>
        </tr>
        <tr>
          <td>
            <div class="iconInfo">
              <img
                src="../../../assets/map-icons/Missing_ForceUpdate.svg"
                alt="Force Update (No AED)"
              />
            </div>
          </td>
          <td>Force Update (No AED)</td>
        </tr>
      </table>
    </div>
  </mat-expansion-panel>
</mat-accordion>
