import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AgencyEditComponent } from "./components/agency-edit/agency-edit.component";
import { ChangeInfoComponent } from "./components/change-info/change-info.component";
import { ChangePasswordComponent } from "./components/change-password/change-password.component";
import { MyprofileComponent } from "./components/myprofile/myprofile.component";

const routes: Routes = [
  {
    path: "",
    component: MyprofileComponent,
    children: [
      { path: "changeInfo", component: ChangeInfoComponent },
      { path: "editAgency", component: AgencyEditComponent },
      { path: "changePassword", component: ChangePasswordComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MyprofileRoutingModule {}
