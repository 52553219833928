import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class InternetConnectivityService {
  public onlineStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  constructor() {
    window.addEventListener("online", () => this.updateOnlineStatus());
    window.addEventListener("offline", () => this.updateOnlineStatus());
  }

  isOnline(): Observable<boolean> {
    return this.onlineStatus.asObservable();
  }

  private updateOnlineStatus() {
    this.onlineStatus.next(window.navigator.onLine);
  }
}
