import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "../core/services/index";
import { SharedService } from "../core/services/shared.service";
import { MustMatch } from "../helpers/must-match.validator";
import { AuthService } from "../core/services/auth.service";
import { UserService } from "../settings/users/user.service";
import { CustomValidators } from "../shared/validators";
import { AppConstants } from "../core/utilities/constants";

@Component({
  selector: "app-set-password",
  templateUrl: "./set-password.component.html",
  styleUrls: ["./set-password.component.css"],
})
export class SetPasswordComponent implements OnInit {
  hide1 = true;

  hide2 = true;

  hide3 = true;

  selectedId: string;

  setChangePasswordForm: FormGroup;

  newPassword2: string;

  confirmPassword: string;

  result;

  realmName: string;

  agencyName: any = "";

  userName: any;

  status: number;

  token5: string;

  addr: string;

  user: string;

  currentAccessToken: string;
  passwordMaxLength = AppConstants.passwordMaxLength;
  errorType = AppConstants.errorType;

  getsetPasswordErrorMessages() {
    return this.setChangePasswordForm.controls.newPassword.hasError("required")
      ? this.translation.instant("cfl.error.required", {
          value: this.translation.instant(
            "cfl.form.confirmPassword.password.placeholder"
          ),
        })
      : "";
  }

  getconfirmPasswordErrorMessages() {
    return this.setChangePasswordForm.controls.confirmPassword.hasError(
      "mustMatch"
    )
      ? this.translation.instant("cfl.error.doesNotMatch", {
          field1: this.translation.instant("cfl.new.placeholder", {
            value: this.translation.instant(
              "cfl.form.confirmPassword.password.placeholder"
            ),
          }),
          field2: this.translation.instant("cfl.confirm.placeholder", {
            value: this.translation.instant(
              "cfl.form.confirmPassword.password.placeholder"
            ),
          }),
        })
      : "";
  }

  getErrorClass(e: string): string {
    const control = this.setChangePasswordForm.controls["newPassword"];
    return control.hasError("required") || control.hasError(e)
      ? ""
      : "text-success";
  }

  getErrorMessage(e: string): string {
    const translationKey = `cfl.error.${e}`;
    const placeholderKey = "cfl.password.placeholder";
    return this.translation.instant(translationKey, {
      value: this.translation.instant(placeholderKey),
    });
  }

  get setChangePasswordControls() {
    return this.setChangePasswordForm.controls;
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    public sharedService: SharedService,
    public translation: TranslateService,
    public userService: UserService
  ) {
    this.currentAccessToken = localStorage.getItem("currentAccessToken");
  }

  ngOnInit() {
    this.setChangePasswordForm = this.fb.group(
      {
        newPassword: [
          "",
          [
            Validators.required,
            Validators.minLength(8),
            CustomValidators.passwordValidator(),
          ],
        ],
        confirmPassword: ["", [Validators.required]],
      },
      {
        validator: MustMatch("newPassword", "confirmPassword"),
      }
    );
  }

  onSubmit() {
    this.newPassword2 = this.setChangePasswordForm.get("newPassword").value;
    this.confirmPassword =
      this.setChangePasswordForm.get("confirmPassword").value;

    const body = {
      newPassword: this.newPassword2,
      confirmPassword: this.confirmPassword,
    };

    return this.userService.setPasswordForAgencyUser(body).subscribe(
      () => {
        this.alertService.showSuccess(
          this.translation.instant(
            "cfl.form.setPassword.component.success.message"
          )
        );
        localStorage.removeItem("IssetPassEnable");
        this.router.navigate(["/dashboard"]);
      },
      (err) => {
        if (err.error.errors[0].errorCode === "AUTH-0005") {
          this.alertService.showFailure(
            this.translation.instant(
              "cfl.form.setPassword.component.error.invalid"
            )
          );
        }
      }
    );
  }
}
