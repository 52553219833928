import { DatePipe } from "@angular/common";
import {
  Component,
  ViewChild,
  Inject,
  Input,
  OnInit,
  Optional,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { CabinetStates, CabinetTypes, AedState } from "src/app/namings";
import { AedService } from "../../aed/aedservice";
import { AlertService } from "../../core/services";
import { DeviceService } from "../../device/device.service";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { merge, of as observableOf, Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import {
  catchError,
  map,
  startWith,
  switchMap,
  debounceTime,
  distinctUntilChanged,
  filter,
} from "rxjs/operators";
import { Router } from "@angular/router";
import { SharedInitialMapService } from "../../core/services/shared-initial-map.service";
import * as L from "leaflet";
import { PopupAEDsComponent } from "../popup-aeds/popup-aeds.component";
import { SharedService } from "../../core/services/shared.service";

@Component({
  selector: "app-popupsearch",
  templateUrl: "./popupsearch.component.html",
  styleUrls: ["./popupsearch.component.scss"],
})
export class PopupsearchComponent implements OnInit {
  displayedColumns: string[] = ["filePath", "status", "accessbility"];

  bounds = L.latLngBounds([]);
  ClusterAEDInformation: Array<any> = [];
  form: FormGroup;
  DataLength;
  count;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource;
  cabLocationsArray: any = [];
  center;
  ngxLoading = false;
  btn = true;
  Images;
  _postsArray: Array<any> = [];
  ClusterAed = false;

  constructor(
    public fb: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public aedService: AedService,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private router: Router,
    public deviceService: DeviceService,
    private alertService: AlertService,
    public translationService: TranslateService,
    private SharedInitialMapService: SharedInitialMapService,
    private SharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [""],
    });
  }

  onkey(value) {
    if (!value) {
      this.btn = true;
    } else {
      this.btn = false;
    }
  }

  SearchById() {
    this.ngxLoading = true;
    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.ngxLoading = true;
          return this.aedService.getSearchById(
            this.form.controls.name.value,
            this.paginator.pageIndex,
            this.paginator.pageSize
          );
        }),
        map((res: any) => {
          this.ngxLoading = false;
          this.DataLength = res.body.length;
          this.count = res.totalSize;
          this.cabLocationsArray = [];
          res.body.forEach((d) => {
            if (d.cabinetState != CabinetStates.installation) {
              if (d.cabinetType === CabinetTypes.monitored) {
                this.cabLocationsArray.push({
                  cabinetType: d.cabinetType,
                  aedSerialNumber: d.aedSerialNumber,
                  pic: d.picOne,
                  status: d.aedStatus,
                  accessbility: d.inAccessible,
                  lat: d.latitude,
                  lng: d.longitude,
                });
              } else {
                this.cabLocationsArray.push({
                  cabinetType: d.cabinetType,
                  aedSerialNumber: d.aedSerialNumber,
                  pic: "../assets/map-icons/NonC4lImage.svg",
                  status: d.aedStatus,
                  accessbility: d.inAccessible,
                  lat: d.latitude,
                  lng: d.longitude,
                });
              }
            }
          });
        }),
        catchError(() => {
          this.ngxLoading = false;
          return observableOf([]);
        })
      )
      .subscribe((res) => {
        this.ngxLoading = false;
        if (this.DataLength === 0) {
          this.alertService.showFailure(
            this.translationService.instant(
              "cfl.deviceComponent.page.aed.notFoundText"
            )
          );
        } else {
          this.dataSource = this.cabLocationsArray;
          //this.MonitoredImages()
        }
      });
  }

  //Mapping Monitored Images and non monitored image as default
  MonitoredImages() {
    this.cabLocationsArray.forEach((d) => {
      if (d.pic != null) {
        this.Images.push(d.pic);
      } else {
        let pic = "../assets/map-icons/NonC4lImage.svg";
        this.Images.push(pic);
      }
    });
  }

  clearFilters() {
    this.form.controls.name.setValue("");
  }

  getMarker(a) {
    this.ClusterAed = false;
    this.dialog.closeAll();
    let lat = this.cabLocationsArray[a].lat;
    let lng = this.cabLocationsArray[a].lng;
    this.bounds.extend([lat, lng]);
    this.SharedInitialMapService.currentMessage.subscribe((res) => {
      res.fitBounds(this.bounds);
      // For cluster AED Model popup open if condition return true then show cluster specific aed else show single aed
      this.SharedService.currentMessage.subscribe((res) => {
        this._postsArray = res;
        this._postsArray.forEach((element) => {
          if (
            element.lat.toFixed(3) === lat.toFixed(3) &&
            element.lng.toFixed(3) === lng.toFixed(3)
          ) {
            if (this.cabLocationsArray.length === 1) {
              this.ClusterAed = false;
            } else {
              this.ClusterAed = true;
            }
          } else {
            this.ClusterAed = false;
          }
        });
        if (this.ClusterAed) {
          var aedInformation = this._postsArray.find(function (post, index) {
            if (post.lat === lat && post.lng === lng) {
              return true;
            }
          });
          this.ClusterAEDInformation.push(aedInformation);
          const deviceDetailDialog = this.dialog.open(PopupAEDsComponent, {
            height: "70%",
            maxWidth: "400px",

            position: { right: "0" },
            data: { information: this.ClusterAEDInformation },
            panelClass: "custom-modalbox",
          });
          this.ClusterAed = false;
        }
      });

      //End of cluster AED code
    });
  }
}
