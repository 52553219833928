import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, RouterEvent } from "@angular/router";
import { BreakpointObserver } from "@angular/cdk/layout";
// import material components
import { MatSidenav } from "@angular/material/sidenav";
import { MatTabChangeEvent } from "@angular/material/tabs";
// import rxjs
import { delay } from "rxjs/operators";
// import services
import { AuthService } from "../../../core/services/auth.service";

@Component({
  selector: "app-myprofile",
  templateUrl: "./myprofile.component.html",
  styleUrls: ["./myprofile.component.css"],
})
export class MyprofileComponent implements OnInit {
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  public currentTabIndex: number = 0;

  constructor(
    private authService: AuthService,
    private observer: BreakpointObserver,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.setSelectedTabForRoute(this.router.url);
  }

  // ngAfterViewInit() {
  //   this.observer
  //     .observe(["(max-width: 992px)"])
  //     .pipe(delay(1))
  //     .subscribe((res) => {
  //       if (res.matches) {
  //         this.sidenav.mode = "side";
  //         this.sidenav.close();
  //       } else {
  //         this.sidenav.mode = "side";
  //         this.sidenav.open();
  //       }
  //     });
  // }

  setSelectedTabForRoute(url) {
    if (url === "/myprofile/changeInfo") {
      this.currentTabIndex = 0;
    } else if (url === "/myprofile/editAgency") {
      this.currentTabIndex = 1;
    } else if (url === "/myprofile/changePassword") {
      this.currentTabIndex = 2;
    } else {
      this.currentTabIndex = 0;
    }
  }

  hasPrivilege(privileges: string[]): boolean {
    return this.authService.hasPrivilege(privileges);
  }

  onTabChange(event: MatTabChangeEvent) {
    this.currentTabIndex = event.index;
    switch (event.tab.textLabel) {
      case "Change User Info":
        this.routeToUrl("myprofile/changeInfo");
        break;
      case "Edit Agency":
        this.routeToUrl("myprofile/editAgency");
        break;
      case "Change Password":
        this.routeToUrl("myprofile/changePassword");
        break;
    }
  }

  routeToUrl(route: string) {
    this.router.navigate([route]);
  }
}
