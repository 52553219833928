import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-wifi-setting-status',
  templateUrl: './wifi-setting-status.component.html',
  styleUrls: ['./wifi-setting-status.component.css'],
})
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["ngOnInit"] }] */
export class WifiSettingStatusComponent implements OnInit {

  heading: string;

  message: any;

  acceptButtonText: string;

  cancelButtonText: string;

  displaySecondButton: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

    this.heading = data.heading ? data.heading : 'Status';
    this.message = data.message ? data.message : 'Status not found';
    this.acceptButtonText = data.acceptButtonText ? data.acceptButtonText : 'Continue';
    this.cancelButtonText = data.cancelButtonText ? data.cancelButtonText : 'Close';
    this.displaySecondButton = (data.displaySecondButton != null) ? data.displaySecondButton : true;

  }

  ngOnInit(): void {
    // do nothing
  }

}
