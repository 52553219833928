import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { ToastrManager } from "ng6-toastr-notifications";
import { Subscription } from "rxjs";
import { AuthService } from "../core/services/auth.service";
import { NotificationsService } from "../notifications/notifications.service";
import { SharedInitialMapService } from "../core/services/shared-initial-map.service";
import { SharedService } from "../core/services/shared.service";
import { latLngBounds } from "leaflet";
import {
  formatDateToString,
  separateDateAndTime,
} from "../core/utilities/utils";
import { blub, fadeOut } from "../shared/animation/matrow";
import { OverlayContainer } from "@angular/cdk/overlay";
import { MatDialog } from "@angular/material/dialog";
import { DashboardService } from "../dashboard/dashboard.service";

@Component({
  selector: "app-toast-notification",
  templateUrl: "./toast-notification.component.html",
  styleUrls: ["./toast-notification.component.scss"],
  animations: [fadeOut, blub],
})
export class ToastNotificationComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenuTrigger) triggerBtn: MatMenuTrigger;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input("opened")
  subscriptions: Subscription;
  displayedColumns: string[] = ["message"];
  notificationCounter: any = 0;
  dataLength;
  ELEMENT_DATA: any = [];
  close = false;
  ngxLoading = false;
  opened = true;
  resetCount = true;
  latSpecific;
  lngSpecific;
  toastrIdentify;
  dataSource: MatTableDataSource<any>;
  bounds = latLngBounds([]);

  ElEMENT_getImage: any = [];

  filter = {
    notification: "",
    time: "",
    priority: "",
    action: "",
  };
  callPending = false;
  payload: {
    agencyId: number;
    filter: any;
    message: string;
    opened: boolean;
    page: number;
    size: number;
    sorted: boolean;
  };

  constructor(
    private authService: AuthService,
    private notificationsService: NotificationsService,
    private router: Router,
    private toastr: ToastrManager,
    private SharedInitialMapService: SharedInitialMapService,
    private csc: SharedService,
    private overlayContainer: OverlayContainer,
    public dialog: MatDialog,
    public dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.notificationsService
      .getAgencyNotificationCount()
      .subscribe((count: number) => {
        this.notificationCounter = count;
      });

    // this.getNotifcationsV2();

    this.notificationsService.getAgencychange().subscribe((status) => {
      if (status) {
        this.ELEMENT_DATA = [];
        this.ElEMENT_getImage = [];
        // this.getNotifcationsV2();
        this.notificationsService
          .getAgencyNotificationCount()
          .subscribe((count: number) => {
            this.notificationCounter = count;
          });
      }
    });

    if (localStorage.getItem("agencyId")) {
      this.subscriptions = this.authService
        .getRealTimeUpdate()
        .subscribe((data: any) => {
          let loginAgency = localStorage.getItem("agencyId");
          let notificationData = data.payload.data();
          if (
            notificationData?.message &&
            notificationData?.agencyId == loginAgency
          ) {
            this.notificationMessage(notificationData);

            this.setNotificationModal(notificationData);
            this.notificationCounter = JSON.parse(
              notificationData.status
            ).unseenAgencyNotificationCount;
            localStorage.setItem(
              "notificationCount",
              this.notificationCounter.toString()
            );
          }
        });
    }
  }

  ngOnDestroy() {
    console.log("component destroyed - toast notification");
    this.subscriptions.unsubscribe();
  }

  setNotificationModal(notificationData) {
    let data: any = [];
    data = this.ELEMENT_DATA;
    if (data.length == 5) {
      data.pop();
    }

    const dateTime = separateDateAndTime(notificationData.createdTime);
    let localDate = formatDateToString(dateTime.date, "/", "DD-MM-YYYY");
    let localTime = dateTime.time;

    if (
      notificationData.actionStatus !== "AEDSTATE3_INACCESSIBLE" &&
      notificationData.actionStatus !== "AEDSTATE30_ACCESSIBLE"
    ) {
      data.unshift({
        action: "PENDING",
        notification: notificationData.message,
        priority: "High",
        createdTime: `${localDate}, ${localTime}`,
        actionStatus: notificationData.actionStatus,
        close: this.close,
      });

      this.ELEMENT_DATA = data;
      this.getIcon(notificationData.actionStatus);
    }
  }

  notificationMessage(data) {
    console.log("data", data);
    if (data.actionStatus === "AEDSTATE1_READY") {
      this.toastrIdentify = this.toastr.customToastr(
        '<img src="../assets/map-icons/C4l_Ready.svg" alt="" /> <span class="notificationToaster aed-success">' +
          data.message +
          '<p class="displayClass">' +
          "AED SERIALNO :" +
          data.aedSerialNumber +
          "</p>" +
          "</span>",
        null,
        { enableHTML: true }
      );
    } else if (data.actionStatus === "AEDSTATE2_NOT_READY") {
      this.toastrIdentify = this.toastr.customToastr(
        '<img src="../assets/map-icons/Not_Ready.svg" alt="" /> <span class="notificationToaster aed-danger">' +
          data.message +
          '<p class="displayClass">' +
          "AED SERIALNO :" +
          data.aedSerialNumber +
          "</p>" +
          "</span>",
        null,
        { enableHTML: true }
      );
    }
    // else if (data.actionStatus === "AEDSTATE3_INACCESSIBLE") {
    //   this.toastrIdentify = this.toastr.customToastr(
    //     '<img src="../assets/map-icons/In_Accessible.svg" alt="" /> <span class="notificationToaster aed-warning">' +
    //       data.message +
    //       '<p class="displayClass">' +
    //       "AED SERIALNO :" +
    //       data.aedSerialNumber +
    //       "</p>" +
    //       "</span>",
    //     null,
    //     { enableHTML: true }
    //   );
    // }
    else if (data.actionStatus === "AEDSTATE4_WARNING") {
      this.toastrIdentify = this.toastr.customToastr(
        '<img src="../assets/map-icons/warningIcon.svg" alt="" /> <span class="notificationToaster aed-warning">' +
          data.message +
          '<p class="displayClass">' +
          "AED SERIALNO :" +
          data.aedSerialNumber +
          "</p>" +
          "</span>",
        null,
        { enableHTML: true }
      );
    } else if (data.actionStatus === "AEDSTATE5_DOOR_OPEN") {
      this.toastrIdentify = this.toastr.customToastr(
        '<img src="../assets/map-icons/Door_Open.svg" alt="" /> <span class="notificationToaster aed-warning">' +
          data.message +
          '<p class="displayClass">' +
          "AED SERIALNO :" +
          data.aedSerialNumber +
          "</p>" +
          "</span>",
        null,
        { enableHTML: true }
      );
    } else if (data.actionStatus === "AEDSTATE6_DEACTIVATED_FOB") {
      this.toastrIdentify = this.toastr.customToastr(
        '<img src="../assets/map-icons/Qr_flag.svg" alt="" /> <span class="notificationToaster aed-success">' +
          data.message +
          '<p class="displayClass">' +
          "AED SERIALNO :" +
          data.aedSerialNumber +
          "</p>" +
          "</span>",
        null,
        { enableHTML: true }
      );
    } else if (data.actionStatus === "AEDSTATE7_NO_AED") {
      this.toastrIdentify = this.toastr.customToastr(
        '<img src="../assets/map-icons/AED_MissingIcon.svg" alt="" /> <span class="notificationToaster aed-danger">' +
          data.message +
          '<p class="displayClass">' +
          "AED SERIALNO :" +
          data.aedSerialNumber +
          "</p>" +
          "</span>",
        null,
        { enableHTML: true }
      );
    } else if (data.actionStatus === "AEDSTATE16_UNAUTHORIZED_FOB") {
      this.toastrIdentify = this.toastr.customToastr(
        '<img src="../assets/map-icons/unauth_fobkey.svg" alt="" /> <span class="notificationToaster aed-danger">' +
          data.message +
          '<p class="displayClass">' +
          "AED SERIALNO :" +
          data.aedSerialNumber +
          "</p>" +
          "</span>",
        null,
        { enableHTML: true }
      );
    } else if (data.actionStatus === "AEDSTATE9_DOOR_CLOSED") {
      this.toastr.customToastr(
        '<img src="../assets/map-icons/door_closeAED.svg" alt="" /> <span class="notificationToaster aed-success">' +
          data.message +
          '<p class="displayClass">' +
          "AED SERIALNO :" +
          data.aedSerialNumber +
          "</p>" +
          "</span>",
        null,
        { enableHTML: true }
      );
    } else if (data.actionStatus === "AEDSTATE10_AED_REPLACED") {
      this.toastr.customToastr(
        '<img src="../assets/map-icons/aed_replaced.svg" alt="" /> <span class="notificationToaster aed-success">' +
          data.message +
          '<p class="displayClass">' +
          "AED SERIALNO :" +
          data.aedSerialNumber +
          "</p>" +
          "</span>",
        null,
        { enableHTML: true }
      );
    } else if (data.actionStatus === "AEDSTATE11_UNKNOWN_FOB") {
      this.toastr.customToastr(
        '<img src="../assets/map-icons/unknown_fobkey.svg" alt="" /> <span class="notificationToaster aed-danger">' +
          data.message +
          '<p class="displayClass">' +
          "AED SERIALNO :" +
          data.aedSerialNumber +
          "</p>" +
          "</span>",
        null,
        { enableHTML: true }
      );
    } else if (data.actionStatus === "AEDSTATE12_FORCE_READY") {
      this.toastr.customToastr(
        '<img src="../assets/map-icons/force_ready.svg" alt="" /> <span class="notificationToaster aed-success">' +
          data.message +
          '<p class="displayClass">' +
          "AED SERIALNO :" +
          data.aedSerialNumber +
          "</p>" +
          "</span>",
        null,
        { enableHTML: true }
      );
    } else if (data.actionStatus === "AEDSTATE13_FORCE_NOT_READY") {
      this.toastr.customToastr(
        '<img src="../assets/map-icons/force_notready.svg" alt="" /> <span class="notificationToaster aed-danger">' +
          data.message +
          '<p class="displayClass">' +
          "AED SERIALNO :" +
          data.aedSerialNumber +
          "</p>" +
          "</span>",
        null,
        { enableHTML: true }
      );
    } else if (data.actionStatus === "AEDSTATE14_FORCE_MISSING") {
      this.toastr.customToastr(
        '<img src="../assets/map-icons/force_missing.svg" alt="" /> <span class="notificationToaster aed-danger">' +
          data.message +
          '<p class="displayClass">' +
          "AED SERIALNO :" +
          data.aedSerialNumber +
          "</p>" +
          "</span>",
        null,
        { enableHTML: true }
      );
    } else if (data.actionStatus === "AEDSTATE15_AUTHORIZED_FOB") {
      this.toastr.customToastr(
        '<img src="../assets/map-icons/auth_fobkey.svg" alt="" /> <span class="notificationToaster aed-success">' +
          data.message +
          '<p class="displayClass">' +
          "AED SERIALNO :" +
          data.aedSerialNumber +
          "</p>" +
          "</span>",
        null,
        { enableHTML: true }
      );
    } else if (data.actionStatus === "AEDSTATE16_ESCALATED_DISABLE") {
      this.toastr.customToastr(
        '<img src="../assets/map-icons/Disable_icons.svg" alt="" /> <span class="notificationToaster aed-danger">' +
          data.message +
          '<p class="displayClass">' +
          "AED SERIALNO :" +
          data.aedSerialNumber +
          "</p>" +
          "</span>",
        null,
        { enableHTML: true }
      );
    } else if (data.actionStatus === "AEDSTATE23_UPDATE") {
      this.toastr.customToastr(
        '<img src="../assets/map-icons/Force_Update.svg" alt="" /><span class="notificationToaster aed-success">' +
          data.message +
          '<p class="displayClass">' +
          "AED SERIALNO :" +
          data.aedSerialNumber +
          "</p>" +
          "</span>",
        null,
        { enableHTML: true }
      );
    } else if (data.actionStatus === "AEDSTATE17_PRIMARY_COMPONENT_EXPIRED") {
      this.toastrIdentify = this.toastr.successToastr(data.message);
    } else if (
      data.actionStatus === "AEDSTATE18_PRIMARY_COMPONENT_ABOUT_TO_EXPIRE"
    ) {
      this.toastrIdentify = this.toastr.successToastr(data.message);
    } else if (data.actionStatus === "AEDSTATE19_SECONDARY_COMPONENT_EXPIRED") {
      this.toastrIdentify = this.toastr.successToastr(data.message);
    } else if (
      data.actionStatus === "AEDSTATE20_SECONDARY_COMPONENT_ABOUT_TO_EXPIRE"
    ) {
      this.toastrIdentify = this.toastr.successToastr(data.message);
    } else if (data.actionStatus === "AEDSTATE21_COMPONENT_EXPIRED") {
      // this.toastrIdentify = this.toastr.errorToastr(data.message)
      this.toastr.customToastr(
        '<img src="../assets/map-icons/error.svg" alt="" /><span class="notificationToaster aed-danger">' +
          data.message +
          "</span>",
        null,
        { enableHTML: true }
      );
    } else if (data.actionStatus === "AEDSTATE22_COMPONENT_ABOUT_TO_EXPIRE") {
      // this.toastrIdentify = this.toastr.warningToastr(data.message)
      this.toastr.customToastr(
        '<img src="../assets/map-icons/warningIcon.svg" alt="" /><span class="notificationToaster aed-warning">' +
          data.message +
          "</span>",
        null,
        { enableHTML: true }
      );
    }
    //new
    else if (data.actionStatus === "AEDSTATE24_CABINET_DISARMED") {
      this.toastr.customToastr(
        '<img src="../assets/map-icons/disarm.svg" alt="" /><span class="notificationToaster aed-danger">' +
          data.message +
          '<p class="displayClass">' +
          "AED SERIALNO :" +
          data.aedSerialNumber +
          "</p>" +
          "</span>",
        null,
        { enableHTML: true }
      );
    } else if (data.actionStatus === "AEDSTATE25_CABINET_ARMED") {
      this.toastr.customToastr(
        '<img src="../assets/map-icons/arm.svg" alt="" /><span class="notificationToaster aed-success">' +
          data.message +
          '<p class="displayClass">' +
          "AED SERIALNO :" +
          data.aedSerialNumber +
          "</p>" +
          "</span>",
        null,
        { enableHTML: true }
      );
    } else if (data.actionStatus === "AEDSTATE26_FOB_KEY_ASSOCIATED_TO_USER") {
      this.toastr.customToastr(
        '<img src="../assets/map-icons/auth_fobkey.svg" alt="" /> <span class="notificationToaster aed-success">' +
          data.message +
          '<p class="displayClass">' +
          "AED SERIALNO :" +
          data.aedSerialNumber +
          "</p>" +
          "</span>",
        null,
        { enableHTML: true }
      );
    } else if (data.actionStatus === "AEDSTATE1_READY") {
      this.toastr.customToastr(
        '<img src="../assets/map-icons/ReadyIcon.svg" alt="" /> <span class="notificationToaster aed-success">' +
          data.message +
          '<p class="displayClass">' +
          "AED SERIALNO :" +
          data.aedSerialNumber +
          "</p>" +
          "</span>",
        null,
        { enableHTML: true }
      );
    } else if (data.actionStatus === "AEDSTATE27_AED_DISABLED") {
      this.toastr.customToastr(
        '<img src="../assets/map-icons/disable.svg" alt="" /> <span class="notificationToaster aed-danger">' +
          data.message +
          '<p class="displayClass">' +
          "AED SERIALNO :" +
          data.aedSerialNumber +
          "</p>" +
          "</span>",
        null,
        { enableHTML: true }
      );
    } else if (data.actionStatus === "AEDSTATE28_AED_ENABLED") {
      this.toastr.customToastr(
        '<img src="../assets/map-icons/enablecabinet.svg" alt="" /> <span class="notificationToaster aed-success">' +
          data.message +
          '<p class="displayClass">' +
          "AED SERIALNO :" +
          data.aedSerialNumber +
          "</p>" +
          "</span>",
        null,
        { enableHTML: true }
      );
    } else if (data.actionStatus === "MANUAL_INSPECTION_DUE") {
      this.toastr.customToastr(
        '<img src="../assets/map-icons/warningIcon.svg" alt="" /> <span class="notificationToaster aed-warning">' +
          data.message +
          '<p class="displayClass">' +
          "AED SERIALNO :" +
          data.aedSerialNumber +
          "</p>" +
          "</span>",
        null,
        { enableHTML: true }
      );
    } else if (data.actionStatus === "INSTALLATION_ABORTED") {
      let message = data.message.split("<br>");

      this.toastr.customToastr(
        '<img src="../assets/map-icons/warningIcon.svg" alt="" /> <span class="notificationToaster aed-danger">' +
          message[0] +
          '<p class="displayClass">' +
          "AED SERIALNO :" +
          data.aedSerialNumber +
          "</p>" +
          "</span>",
        null,
        { enableHTML: true }
      );
    } else if (data.actionStatus === "AEDSTATE29_AED_MOVED_TO_APPROVAL") {
      this.toastr.customToastr(
        '<img src="../assets/map-icons/Force_Update.svg" alt="" /> <span class="notificationToaster aed-success">' +
          data.message +
          '<p class="displayClass">' +
          "AED SERIALNO :" +
          data.aedSerialNumber +
          "</p>" +
          "</span>",
        null,
        { enableHTML: true }
      );
    }
  }

  getNotifcationsV2() {
    this.callPending = true;
    this.ngxLoading = true;
    this.ELEMENT_DATA = [];

    enum priorityEnum {
      HIGH = "High",
      LOW = "Low",
    }
    this.notificationsService.getAlarmsTop5().subscribe(
      (res: any) => {
        this.callPending = false;
        this.ngxLoading = false;
        this.dataLength = res.totalSize;
        const re = /Manual/gi;
        res.body.forEach((d) => {
          const dateTime = separateDateAndTime(d.createdTime);
          let localDate = formatDateToString(dateTime.date, "/", "DD-MM-YYYY");
          let localTime = dateTime.time;
          this.ELEMENT_DATA.push({
            action: d.action,
            notification: d.message,
            priority: priorityEnum[d.priority],
            createdTime: `${localDate}, ${localTime}`,
            actionStatus: d.actionStatus,
            cabinetSerialNumber: d.cabinetSerialNumber,
            alarmId: d.alarmId,
            close: this.close,
            aedSerialNumber: d.aedSerialNumber,
          });
        });
        this.getAllImages();
      },
      (error) => {
        this.ngxLoading = false;
      }
    );
  }

  getIcon(actionStatus) {
    switch (actionStatus) {
      case "AEDSTATE1_READY":
        this.ElEMENT_getImage.unshift({
          url: "../assets/map-icons/ReadyIcon.svg",
        });
        break;
      case "AEDSTATE2_NOT_READY":
        this.ElEMENT_getImage.unshift({
          url: "../assets/map-icons/Not_ReadyIcon.svg",
        });
        break;
      // case "AEDSTATE3_INACCESSIBLE":
      //   this.ElEMENT_getImage.unshift({
      //     url: "../assets/map-icons/In_Accessible.svg",
      //   });
      //   break;
      case "AEDSTATE4_WARNING":
        this.ElEMENT_getImage.unshift({
          url: "../assets/map-icons/warningIcon.svg",
        });
        break;
      case "AEDSTATE5_DOOR_OPEN":
        this.ElEMENT_getImage.unshift({
          url: "../assets/map-icons/Door_Open.svg",
        });
        break;
      case "AEDSTATE6_DEACTIVATED_FOB":
        this.ElEMENT_getImage.unshift({
          url: "../assets/map-icons/Qr_flag.svg",
        });
        break;
      case "AEDSTATE7_NO_AED":
        this.ElEMENT_getImage.unshift({
          url: "../assets/map-icons/AED_MissingIcon.svg",
        });
        break;
      case "AEDSTATE16_UNAUTHORIZED_FOB":
        this.ElEMENT_getImage.unshift({
          url: "../assets/map-icons/unauth_fobkey.svg",
        });
        break;
      case "AEDSTATE9_DOOR_CLOSED":
        this.ElEMENT_getImage.unshift({
          url: "../assets/map-icons/door_closeAED.svg",
        });
        break;
      case "AEDSTATE10_AED_REPLACED":
        this.ElEMENT_getImage.unshift({
          url: "../assets/map-icons/aed_replaced.svg",
        });
        break;
      case "AEDSTATE11_UNKNOWN_FOB":
        this.ElEMENT_getImage.unshift({
          url: "../assets/map-icons/unknown_fobkey.svg",
        });
        break;
      case "AEDSTATE12_FORCE_READY":
        this.ElEMENT_getImage.unshift({
          url: "../assets/map-icons/force_ready.svg",
        });
        break;
      case "AEDSTATE13_FORCE_NOT_READY":
        this.ElEMENT_getImage.unshift({
          url: "../assets/map-icons/force_notready.svg",
        });
        break;
      case "AEDSTATE14_FORCE_MISSING":
        this.ElEMENT_getImage.unshift({
          url: "../assets/map-icons/force_missing.svg",
        });
        break;
      case "AEDSTATE15_AUTHORIZED_FOB":
        this.ElEMENT_getImage.unshift({
          url: "../assets/map-icons/auth_fobkey.svg",
        });
        break;
      case "AEDSTATE16_ESCALATED_DISABLE":
        this.ElEMENT_getImage.unshift({
          url: "../assets/map-icons/Disable_icons.svg",
        });
        break;
      case "AEDSTATE23_UPDATE":
        this.ElEMENT_getImage.unshift({
          url: "../assets/map-icons/Force_Update.svg",
        });
        break;
      case "AEDSTATE17_PRIMARY_COMPONENT_EXPIRED":
        this.ElEMENT_getImage.unshift({ url: "" });
        break;
      case "AEDSTATE18_PRIMARY_COMPONENT_ABOUT_TO_EXPIRE":
        this.ElEMENT_getImage.unshift({ url: "" });
        break;
      case "AEDSTATE19_SECONDARY_COMPONENT_EXPIRED":
        this.ElEMENT_getImage.unshift({ url: "" });
        break;
      case "AEDSTATE20_SECONDARY_COMPONENT_ABOUT_TO_EXPIRE":
        this.ElEMENT_getImage.unshift({ url: "" });
        break;
      case "AEDSTATE21_COMPONENT_EXPIRED":
        this.ElEMENT_getImage.unshift({ url: "../assets/map-icons/error.svg" });
        break;
      case "AEDSTATE22_COMPONENT_ABOUT_TO_EXPIRE":
        this.ElEMENT_getImage.unshift({
          url: "../assets/map-icons/warningIcon.svg",
        });
        break;
      case "AEDSTATE24_CABINET_DISARMED":
        this.ElEMENT_getImage.unshift({
          url: "../assets/map-icons/disarm.svg",
        });
        break;
      case "AEDSTATE25_CABINET_ARMED":
        this.ElEMENT_getImage.unshift({ url: "../assets/map-icons/arm.svg" });
        break;
      case "AEDSTATE26_FOB_KEY_ASSOCIATED_TO_USER":
        this.ElEMENT_getImage.unshift({
          url: "../assets/map-icons/auth_fobkey.svg",
        });
        break;
      case "AEDSTATE27_AED_DISABLED":
        this.ElEMENT_getImage.unshift({
          url: "../assets/map-icons/disable.svg",
        });
        break;
      case "AEDSTATE28_AED_ENABLED":
        this.ElEMENT_getImage.unshift({
          url: "../assets/map-icons/enablecabinet.svg",
        });
        break;
      case "MANUAL_INSPECTION_DUE":
        this.ElEMENT_getImage.unshift({
          url: "../assets/map-icons/warningIcon.svg",
        });
        break;
      case "INSTALLATION_ABORTED":
        this.ElEMENT_getImage.unshift({
          url: "../assets/map-icons/warningIcon.svg",
        });
        break;
      case "AEDSTATE29_AED_MOVED_TO_APPROVAL":
        this.ElEMENT_getImage.unshift({
          url: "../assets/map-icons/Force_Update.svg",
        });
        break;
      default:
        this.ElEMENT_getImage.unshift({ url: "" });
        break;
    }
  }

  getAllImages() {
    this.ElEMENT_getImage = [];
    this.ELEMENT_DATA.forEach((d) => {
      switch (d.actionStatus) {
        case "AEDSTATE1_READY":
          this.ElEMENT_getImage.push({
            url: "../assets/map-icons/ReadyIcon.svg",
          });
          break;
        case "AEDSTATE2_NOT_READY":
          this.ElEMENT_getImage.push({
            url: "../assets/map-icons/Not_ReadyIcon.svg",
          });
          break;
        // case "AEDSTATE3_INACCESSIBLE":
        //   this.ElEMENT_getImage.push({
        //     url: "../assets/map-icons/In_Accessible.svg",
        //   });
        //   break;
        case "AEDSTATE4_WARNING":
          this.ElEMENT_getImage.push({
            url: "../assets/map-icons/warningIcon.svg",
          });
          break;
        case "AEDSTATE5_DOOR_OPEN":
          this.ElEMENT_getImage.push({
            url: "../assets/map-icons/Door_Open.svg",
          });
          break;
        case "AEDSTATE6_DEACTIVATED_FOB":
          this.ElEMENT_getImage.push({
            url: "../assets/map-icons/Qr_flag.svg",
          });
          break;
        case "AEDSTATE7_NO_AED":
          this.ElEMENT_getImage.push({
            url: "../assets/map-icons/AED_MissingIcon.svg",
          });
          break;
        case "AEDSTATE16_UNAUTHORIZED_FOB":
          this.ElEMENT_getImage.push({
            url: "../assets/map-icons/unauth_fobkey.svg",
          });
          break;
        case "AEDSTATE9_DOOR_CLOSED":
          this.ElEMENT_getImage.push({
            url: "../assets/map-icons/door_closeAED.svg",
          });
          break;
        case "AEDSTATE10_AED_REPLACED":
          this.ElEMENT_getImage.push({
            url: "../assets/map-icons/aed_replaced.svg",
          });
          break;
        case "AEDSTATE11_UNKNOWN_FOB":
          this.ElEMENT_getImage.push({
            url: "../assets/map-icons/unknown_fobkey.svg",
          });
          break;
        case "AEDSTATE12_FORCE_READY":
          this.ElEMENT_getImage.push({
            url: "../assets/map-icons/force_ready.svg",
          });
          break;
        case "AEDSTATE13_FORCE_NOT_READY":
          this.ElEMENT_getImage.push({
            url: "../assets/map-icons/force_notready.svg",
          });
          break;
        case "AEDSTATE14_FORCE_MISSING":
          this.ElEMENT_getImage.push({
            url: "../assets/map-icons/force_missing.svg",
          });
          break;
        case "AEDSTATE15_AUTHORIZED_FOB":
          this.ElEMENT_getImage.push({
            url: "../assets/map-icons/auth_fobkey.svg",
          });
          break;
        case "AEDSTATE16_ESCALATED_DISABLE":
          this.ElEMENT_getImage.push({
            url: "../assets/map-icons/Disable_icons.svg",
          });
          break;
        case "AEDSTATE23_UPDATE":
          this.ElEMENT_getImage.push({
            url: "../assets/map-icons/Force_Update.svg",
          });
          break;
        case "AEDSTATE17_PRIMARY_COMPONENT_EXPIRED":
          this.ElEMENT_getImage.push({ url: "" });
          break;
        case "AEDSTATE18_PRIMARY_COMPONENT_ABOUT_TO_EXPIRE":
          this.ElEMENT_getImage.push({ url: "" });
          break;
        case "AEDSTATE19_SECONDARY_COMPONENT_EXPIRED":
          this.ElEMENT_getImage.push({ url: "" });
          break;
        case "AEDSTATE20_SECONDARY_COMPONENT_ABOUT_TO_EXPIRE":
          this.ElEMENT_getImage.push({ url: "" });
          break;
        case "AEDSTATE21_COMPONENT_EXPIRED":
          this.ElEMENT_getImage.push({ url: "../assets/map-icons/error.svg" });
          break;
        case "AEDSTATE22_COMPONENT_ABOUT_TO_EXPIRE":
          this.ElEMENT_getImage.push({
            url: "../assets/map-icons/warningIcon.svg",
          });
          break;

        case "AEDSTATE24_CABINET_DISARMED":
          this.ElEMENT_getImage.push({ url: "../assets/map-icons/disarm.svg" });
          break;
        case "AEDSTATE25_CABINET_ARMED":
          this.ElEMENT_getImage.push({ url: "../assets/map-icons/arm.svg" });
          break;
        case "AEDSTATE26_FOB_KEY_ASSOCIATED_TO_USER":
          this.ElEMENT_getImage.push({
            url: "../assets/map-icons/auth_fobkey.svg",
          });
          break;
        case "AEDSTATE27_AED_DISABLED":
          this.ElEMENT_getImage.push({
            url: "../assets/map-icons/disable.svg",
          });
          break;
        case "AEDSTATE28_AED_ENABLED":
          this.ElEMENT_getImage.push({
            url: "../assets/map-icons/enablecabinet.svg",
          });
          break;
        case "MANUAL_INSPECTION_DUE":
          this.ElEMENT_getImage.push({
            url: "../assets/map-icons/warningIcon.svg",
          });
          break;
        case "MANUAL_INSPECTION_CLOSE":
          this.ElEMENT_getImage.push({
            url: "../assets/map-icons/warningIcon.svg",
          });
          break;

        case "INSTALLATION_ABORTED":
          this.ElEMENT_getImage.push({
            url: "../assets/map-icons/warningIcon.svg",
          });
          break;
        case "AEDSTATE29_AED_MOVED_TO_APPROVAL":
          this.ElEMENT_getImage.push({
            url: "../assets/map-icons/Force_Update.svg",
          });
          break;
        default:
          this.ElEMENT_getImage.push({ url: "" });
          break;
      }
    });
  }

  getallNotifcations() {
    this.router.navigate(["/notifications/notifications-list"], {
      queryParams: { opened: true, resetCount: true },
    });
  }

  getMarker(a) {
    let AEDSerialNumber = this.ELEMENT_DATA[a].aedSerialNumber;
    this.csc.currentMessage.subscribe((data: any) => {
      var specificLatLng = data.filter(
        (m) => m.aedSerialNumber === AEDSerialNumber
      );
      this.latSpecific = specificLatLng[0].lat;
      this.lngSpecific = specificLatLng[0].lng;
    });
    this.bounds.extend([this.latSpecific, this.lngSpecific]);
    this.SharedInitialMapService.currentMessage.subscribe((res) => {
      res.fitBounds(this.bounds);
    });
  }
  preventCloseOnClickOut() {
    this.overlayContainer
      .getContainerElement()
      .classList.add("disable-backdrop-click");
  }

  clearCount() {
    this.notificationCounter = 0;
    localStorage.setItem(
      "notificationCount",
      this.notificationCounter.toString()
    );
  }
}
