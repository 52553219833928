import { Component, OnInit } from '@angular/core';
import { AedState } from 'src/app/namings';

@Component({
  selector: 'app-information-icon-popup',
  templateUrl: './information-icon-popup.component.html',
  styleUrls: ['./information-icon-popup.component.scss']
})
export class InformationIconPopupComponent implements OnInit {

  aedStates: string[] = [AedState.ready, AedState.notready, AedState.noaed]; 

  constructor() { }

  ngOnInit(): void {
  }

}
