<div class="main text-center">
  <div class="form-top-margin">
    <div class="heading">{{ "cfl.ChangePassword" | translate }}</div>
  </div>
  <div class="form-top-margin text-center">
    <img src="../assets/images/password.png" alt="image" />
  </div>
</div>
<div class="form-side-margin">
  <form [formGroup]="changePasswordForm" class="example-form">
    <div class="form-top-margin">
      <div class="mt-3">
        <mat-form-field appearance="outline">
          <mat-label>{{
            "cfl.settingsComponent.tab.changeInfo.group.field.currentPassword.placeholder"
              | translate
          }}</mat-label>
          <input
            matInput
            placeholder="{{
              'cfl.settingsComponent.tab.changeInfo.group.field.currentPassword.placeholder'
                | translate
            }}"
            [type]="hideCurrentPassword ? 'password' : 'text'"
            id="oldPassword"
            formControlName="oldPassword"
            [maxlength]="passwordMaxLength"
          />
          <mat-icon
            style="cursor: pointer"
            matSuffix
            (click)="hideCurrentPassword = !hideCurrentPassword"
            >{{
              hideCurrentPassword ? "visibility_off" : "visibility"
            }}</mat-icon
          >
          <!-- <button type="button" mat-icon-button matSuffix (click)="hideCurrentPassword = !hideCurrentPassword"
                          [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideCurrentPassword">
                          <mat-icon>{{hideCurrentPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                          </button> -->
        </mat-form-field>
        <mat-error
          *ngIf="
            changePasswordControls.oldPassword.invalid &&
            changePasswordControls.oldPassword.touched
          "
          >{{ getOldPasswordErrorMessages() }}
        </mat-error>
      </div>
      <div class="mt-3">
        <mat-form-field appearance="outline">
          <mat-label>
            {{
              "cfl.settingsComponent.tab.changeInfo.group.field.newPassword.placeholder"
                | translate
            }}
          </mat-label>
          <input
            matInput
            placeholder="{{
              'cfl.settingsComponent.tab.changeInfo.group.field.newPassword.placeholder'
                | translate
            }}"
            [type]="hideNewPassword ? 'password' : 'text'"
            id="newPassword"
            formControlName="newPassword"
            [maxlength]="passwordMaxLength"
          />
          <mat-icon
            style="cursor: pointer"
            matSuffix
            (click)="hideNewPassword = !hideNewPassword"
            >{{ hideNewPassword ? "visibility_off" : "visibility" }}</mat-icon
          >
          <!-- <button type="button" mat-icon-button matSuffix (click)="hideNewPassword = !hideNewPassword"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideNewPassword">
                        <mat-icon>{{hideNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button> -->
        </mat-form-field>
      </div>
      <div class="mt-3">
        <mat-form-field appearance="outline">
          <mat-label>{{
            "cfl.settingsComponent.tab.changeInfo.group.field.confirmPassword.placeholder"
              | translate
          }}</mat-label>
          <input
            matInput
            placeholder="{{
              'cfl.settingsComponent.tab.changeInfo.group.field.confirmPassword.placeholder'
                | translate
            }}"
            [type]="hideConfirmPassword ? 'password' : 'text'"
            id="confirmPassword"
            formControlName="confirmPassword"
            [maxlength]="passwordMaxLength"
          />
          <mat-icon
            style="cursor: pointer"
            matSuffix
            (click)="hideConfirmPassword = !hideConfirmPassword"
            >{{ hideConfirmPassword ? "visibility_off" : "visibility" }}
          </mat-icon>
          <!-- <button type="button" mat-icon-button matSuffix (click)="hideConfirmPassword = !hideConfirmPassword"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirmPassword">
                        <mat-icon>{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button> -->
        </mat-form-field>
      </div>
      <div class="mt-3">
        <div class="errorDivFormField">
          <ng-container *ngFor="let e of errorType">
            <mat-error [ngClass]="getErrorClass(e)">
              • {{ getErrorMessage(e) }}
            </mat-error>
          </ng-container>

          <mat-error
            *ngIf="
              changePasswordControls.confirmPassword.invalid &&
              changePasswordControls.confirmPassword.touched
            "
          >
            {{ getConfirmPasswordErrorMessages() }}
          </mat-error>
        </div>
      </div>
    </div>
  </form>
  <div class="form-top-margin text-center">
    <button
      mat-raised-button
      class="primary-button center"
      [disabled]="!changePasswordForm.valid"
      (click)="submitChangePassword()"
    >
      {{ "cfl.settingsComponent.tab.changeInfo.group.btn.submit" | translate }}
    </button>
  </div>
</div>
