import { Directive, OnDestroy } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver } from '@angular/cdk/layout';
import { delay } from 'rxjs/operators';
@Directive({
  selector: 'mat-sidenav[appResponsiveSidenav]',
})
export class ResponsiveSidenavDirective implements OnDestroy {

  constructor(
    private sidenav: MatSidenav,
    private observer: BreakpointObserver,
  ) { }

  private subscription = this.observer.observe(['(max-width:992px)']).pipe(delay(1)).subscribe((res) => {

    if (res.matches) {

      this.sidenav.mode = 'over';
      this.sidenav.close();

    } else {

      this.sidenav.mode = 'side';
      this.sidenav.open();

    }

  });

  ngOnDestroy() {

    this.subscription.unsubscribe();

  }

}
