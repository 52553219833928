<div class="filterbutton" *ngIf="filterService?.selectedFilter?.length > 0 ">
  <div class="row m-0">
    <div class="col-sm-10 p-0" @rowsAnimation>
      <div>

        <span class="selectedFilterButton" *ngFor="let value of filterService?.selectedFilter">
          {{ value.name }}
          <button (click)="removeSingleFilter(value)">x</button></span>


      </div>
    </div>
    <div class="col-sm-2 p-0 pt-2">
      <div class="text-right">
        <span class="clearAll" (click)="removeAllFilters()">Clear All</span>
      </div>
    </div>
  </div>
</div>