import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log("auth");
    const accessToken = localStorage.getItem("currentAccessToken");
    if (state.url === "/auth/signin" && accessToken) {
      this.router.navigate(["/dashboard"]);
    } else if (!accessToken && state.url !== "/auth/signin") {
      this.router.navigate(["/auth/signin"]);
    } else {
      return true;
    }
  }
}
