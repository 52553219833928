// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  csc_api_key: "OGhWa0RWNFFoeHJnVEdUNjhCYkpTMGlmMHpRTkJSbU1xeXB0ZUoxSg==",
  firebase: {
    apiKey: "AIzaSyCj2W-6MksLdJe1MtHpnatIYYYbzDdOv6k",
    authDomain: "c4life-prod.firebaseapp.com",
    databaseURL: "https://c4life-prod-default-rtdb.firebaseio.com",
    projectId: "c4life-prod",
    storageBucket: "c4life-prod.appspot.com",
    messagingSenderId: "564431093983",
    appId: "1:564431093983:web:76954d3e3027d5fc25e96a",
    //measurementId: "G-QV7QWP7VJF"

    // apiKey: "AIzaSyCWO3UW45R_6DrXO32QGyHbGyXsm2YlK_0",
    // authDomain: "c4life-d78e6.firebaseapp.com",
    // databaseURL: "https://c4life-d78e6.firebaseio.com",
    // projectId: "c4life-d78e6",
    // storageBucket: "c4life-d78e6.appspot.com",
    // messagingSenderId: "882180780577",
    // appId: "1:882180780577:web:798cf8ab63512232e4e960"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
