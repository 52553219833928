


<!-- <button *ngIf="cancelButton" mat-flat-button color="primary" style="color: black;outline: none;" id="floating-panel"
    (click)="cancel()" type=button value="Show All Markers">Close</button> -->


<!-- <div #mapContainer id="map" style="max-height: 100px;"></div> -->
<div  class="map-container">
    <div class="map" leaflet [leafletOptions]="options" [leafletLayers]="layers" (leafletMapReady)="onMapReady($event)">
    </div>
    
    <br>
    <div id=" floating-panel">
        <div>
            <button *ngIf="buttonVisible && !cancelButton" mat-flat-button class="secondary-button" (click)="cancel()"
                type=button value="Show All Markers">Cancel</button> &nbsp;
            <button *ngIf="buttonVisible !cancelButton" mat-raised-button class="primary-button" (click)="createCabinet()"
                type=button value="Show All Markers">Done</button>
        </div>
    </div>
    </div>