import { Component, OnInit } from "@angular/core";
import { InternetConnectivityService } from "../../../core/services/internet-connectivity.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-toast-notification-internet-connectivity",
  templateUrl: "./toast-notification-internet-connectivity.component.html",
  styleUrls: ["./toast-notification-internet-connectivity.component.css"],
})
export class ToastNotificationInternetConnectivityComponent implements OnInit {
  isOnline$: Observable<boolean>;

  constructor(private internetService: InternetConnectivityService) {
    this.isOnline$ = this.internetService.isOnline();
  }

  ngOnInit(): void {}
}
