import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxLoadingModule } from "ngx-loading";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { TranslateModule } from "@ngx-translate/core";
import { FileUploadModule } from "ng2-file-upload";
import { MaterialModule } from "../material";
import { SafeHtmlPipe } from "../safe.html.pipe";
import { DashboardFilterComponent } from "./modal/dashboard-filter/dashboard-filter.component";
import { FilterChildComponent } from "../dashboard/filter-child/filter-child.component";
import { MatChipsModule } from "@angular/material/chips";
import { MatGridListModule } from "@angular/material/grid-list";
import { ToastNotificationInternetConnectivityComponent } from "./components/toast-notification-internet-connectivity/toast-notification-internet-connectivity.component";

@NgModule({
  declarations: [
    SafeHtmlPipe,
    DashboardFilterComponent,
    FilterChildComponent,
    ToastNotificationInternetConnectivityComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatTableModule,
    MatPaginatorModule,
    // MatTableDataSource,
    // GooglePlacesDirective,
    TranslateModule,
    FileUploadModule,
    NgxLoadingModule.forRoot({
      backdropBackgroundColour: "rgba(0,0,0,0.5)",
      backdropBorderRadius: "3px",
      primaryColour: "#fffff",
      secondaryColour: "#fffff",
      tertiaryColour: "#fffff",
    }),
    MatChipsModule,
    MatGridListModule,
  ],
  exports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxLoadingModule,
    TranslateModule,
    FileUploadModule,
    SafeHtmlPipe,
    FilterChildComponent,
    ToastNotificationInternetConnectivityComponent,
  ],
})
export class SharedModule {}
