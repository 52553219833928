import { Component, OnInit , Output , EventEmitter} from '@angular/core';
import { DashboardService } from '.././dashboard.service';
import { AlertService } from '../../core/services/alert.service';
import { Router } from '@angular/router';
import {CabinetStates,AedState} from '../../namings';
@Component({
  selector: 'app-filter-menu',
  templateUrl: './filter-menu.component.html',
  styleUrls: ['./filter-menu.component.scss']
})
export class FilterMenuComponent implements OnInit {



  ShowAll: string;
  AedStates: string[] = [AedState.all, AedState.ready, AedState.notready, AedState.noaed];
  CabinetStates: string[] = [CabinetStates.all, CabinetStates.enabled, CabinetStates.disabled, CabinetStates.temporary_disabled, CabinetStates.decomissioned];
  Warning: string[] = ['All', 'true', 'false'];
  Accessible: string[] = ['All', 'true', 'false'];
  Collection=[];
  CheckAedState = [];
  CheckCabinetState = [];
  CheckWarning = [];
  CheckAccessible = [];
  ngxLoading = false;
  filterCabLocationsArray =[];
  

  constructor(private alertService: AlertService,private router: Router,public dashboardService: DashboardService,) { 
  }

  @Output() event = new EventEmitter<any>()

  ngOnInit(): void {
   
  }

  //radio button mapping for accessible
  accessibleGroup(e) {

    this.CheckAccessible.push(e.value)
    if(this.CheckAccessible.length === 1){
      this.Collection.push(this.CheckAccessible)
    }
    else{
      this.CheckAccessible.shift()
    }
  }
   //radio button mapping for warning
  warningGroup(e) { 
    this.CheckWarning.push(e.value)
    if(this.CheckWarning.length === 1){
      this.Collection.push(this.CheckWarning)
    }
    else{
      this.CheckWarning.shift()
    }
  }
 //radio button mapping for aedstate
  aedStateGroup(e) { 
    this.CheckAedState.push(e.value)
    if(this.CheckAedState.length === 1){
      this.Collection.push(this.CheckAedState)
    }
    else{
      this.CheckAedState.shift()
    }
  }
   //radio button mapping for cabinet
  cabinetGroup(e) { 
    this.CheckCabinetState.push(e.value)
    if(this.CheckCabinetState.length === 1){
      this.Collection.push(this.CheckCabinetState)
    }
    else{
      this.CheckCabinetState.shift()
    }
  }
 

  getFitleredAed(){
    this.ngxLoading = true;
    const body: any = {};
    body.agencyId = localStorage.getItem('agencyId');
    if(this.CheckAedState[0] === AedState.all){
      body.aedStatus = null
    }
    else{
      body.aedStatus = this.CheckAedState[0];
    }
    if(this.CheckCabinetState[0] === CabinetStates.all){
      body.cabinetState = null
    }
    else{
      body.cabinetState = this.CheckCabinetState[0];
    }
    if(this.CheckAccessible[0] === 'All'){
      body.publiclyAccessible = null
    }
    else{
      body.publiclyAccessible = this.CheckAccessible[0];
    }
    if(this.CheckWarning[0] === 'All'){
      body.warning = null
    }
    else{
      body.warning = this.CheckWarning[0];
    }
    this.dashboardService.getCabinetAed(body).subscribe(
      (res : any)=>{
      this.ngxLoading = false;
      res.body.forEach((d) => {
        if (d.cabinetState !== CabinetStates.installation) {
          this.filterCabLocationsArray.push({
            name: d.name,
            lat: d.latitude,
            lng: d.longitude,
            aedStatus: d.aedStatus,
            aedSerialNumber: d.aedSerialNumber,
            CabinetSerialNumber : d.cabinetSerialNumber, 
            cabinetType: d.cabinetType,
            aedState: d.aedState,
            cabStatus: d.cabinetState,
            warningStatus: d.warning,
            inaccessibilityStatus: d.inAccessible,
            doorStatus: d.doorStatus,
            activationStatus: d.activationStatus,
            idNew: d.installerEmail,
            primaryAdultPadExpiry: d.primaryAdultPadExpiry,
            primaryBatteryExpiry: d.primaryBatteryExpiry,
            primaryChildPadExpiry: d.primaryChildPadExpiry,
            secondaryAdultPadExpiry: d.secondaryAdultPadExpiry,
            secondaryBatteryExpiry: d.secondaryBatteryExpiry,
            secondaryChildPadExpiry: d.secondaryChildPadExpiry,
            aedImage: d.aedImage,
            picOne: d.picOne,
            picTwo: d.picTwo,
            picThree: d.picThree,
            vicinityType : d.vicinityType,
            qrCodeNumber : d.qrCodeNumber,
            othernotes : d.othernotes
          });
          
          
        }
        return this.filterCabLocationsArray;
       
      })
    },(err)=>{
      this.ngxLoading = false;
      this.alertService.showFailure('No Result Found');
    })
  }

}