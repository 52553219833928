{{ "cfl.SelectedAED" | translate }}: {{ data.aed.name }}
<form style="margin-top: 1%">
  <mat-form-field appearance="outline">
    <mat-label>{{ "cfl.Select" | translate }}</mat-label>
    <input
      type="text"
      aria-label="Number"
      matInput
      [formControl]="myControl"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="onSelectionChange($event)"
    >
      <mat-option
        *ngFor="let option of filteredOptions | async"
        [value]="option"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <div style="margin-top: 10px">
    <span class="mat-form-field-width" *ngIf="CheckSwap == true">
      <mat-label>{{
        "cfl.monitoredmodal.modalform.value.inspectiondate" | translate
      }}</mat-label>
      <input
        matInput
        [formControl]="myControl2"
        readonly
        [matDatepicker]="picker9"
      />
      <mat-datepicker-toggle matSuffix id="lastInspectionDate" [for]="picker9">
      </mat-datepicker-toggle>
      <mat-datepicker #picker9></mat-datepicker>
    </span>
    <button
      class="primary-button right"
      [disabled]="CheckSwap && myControl2.value == ''"
      style="margin-left: 2%; margin-right: 5%"
      mat-raised-button
      (click)="swapClicked()"
    >
      {{ "cfl.Swap" | translate }}
    </button>
    <button class="secondary-button right" mat-stroked-button mat-dialog-close>
      {{ "cfl.notifications.component.action.btn.close" | translate }}
    </button>
  </div>
</form>
