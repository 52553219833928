import { Component, OnInit, ChangeDetectorRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BreakpointObserver } from "@angular/cdk/layout";
import { MatSidenav } from "@angular/material/sidenav";
import { AuthService } from "../core/services/auth.service";
import { MatTabChangeEvent, MatTabGroup } from "@angular/material/tabs";
import { MatDialog } from "@angular/material/dialog";
@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.css"],
})
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["tabChanged"]}] */
export class SettingsComponent implements OnInit {
  currentTabIndex = 0;
  EPUCheck: any = localStorage.getItem("EPU");
  EPU1 = false;
  EPU2 = false;
  selectedIndex;
  icon = "clear";
  timer: any;
  autoUpdateEnable: any;
  opened: boolean;
  showSubMenu = false;
  configurationOpened = false;

  @ViewChild(MatSidenav) sidenav!: MatSidenav;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    let currentRoute = this.router.url;
    this.setselectedTab(currentRoute);
    this.dialog.closeAll();
    this.selectedIndex = localStorage.getItem("selectedSettingsTab");
    this.autoUpdateEnable = localStorage.getItem("update");
    if (this.autoUpdateEnable === "yes") {
      this.icon = "done";
      this.autoUpdate();
    } else {
      this.icon = "clear";
    }
  }
  getSelectedIndex(): number {
    return this.currentTabIndex == 0
      ? (this.currentTabIndex = this.selectedIndex)
      : this.currentTabIndex;
  }
  setselectedTab(url) {
    if (url == "/settings/calendar") {
      this.selectedIndex = 0;
    } else if (url == "/settings/fob") {
      this.selectedIndex = 1;
    } else if (url == "/settings/inspectionInterval") {
      this.selectedIndex = 2;
    } else if (url == "/settings/remoteUpdate") {
      this.selectedIndex = 3;
    } else if (url == "/settings/admin") {
      this.selectedIndex = 4;
    } else if (url == "/settings/users") {
      this.selectedIndex = 5;
    } else if (url == "/settings/EscalationConfiguration") {
      this.selectedIndex = 5;
    } else {
      this.selectedIndex = 0;
    }
    this.currentTabIndex == 0
      ? (this.currentTabIndex = this.selectedIndex)
      : this.currentTabIndex;
  }
  onTabChange(event: MatTabChangeEvent) {
    if (
      localStorage.getItem("currentAccessToken") == "" ||
      localStorage.getItem("currentAccessToken") == null
    ) {
      return;
    }
    switch (event.tab.textLabel) {
      case "Calendar":
        this.router.navigate(["settings/calendar"]);
        break;
      case "FOB List":
        this.router.navigate(["settings/fob"]);
        break;
      case "Inspection Interval":
        this.router.navigate(["settings/inspectionInterval"]);
        break;
      // case "Auto Update" :
      // this.router.navigate(['settings/autoUpdate']);
      // break;
      case "Remote Update":
        this.router.navigate(["settings/remoteUpdate"]);
        break;
      case "Admin":
        this.router.navigate(["settings/admin"]);
        break;
      case "Users":
        this.router.navigate(["settings/users"]);
        break;
    }
  }
  escalationSelected() {
    this.selectedIndex = 6;
  }

  tabChanged(selectedTab) {
    localStorage.setItem("selectedSettingsTab", selectedTab.index);
  }

  toggleIcon() {
    if (this.icon === "clear") {
      this.icon = "done";
      localStorage.setItem("update", "yes");
      this.autoUpdate();
    } else {
      this.icon = "clear";
      localStorage.setItem("update", "no");
      this.autoUpdate();
    }
  }

  autoUpdate() {
    this.autoUpdateEnable = localStorage.getItem("update");
  }

  ngOnDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  ngAfterViewChecked() {
    this.configurationOpened =
      this.router.url.endsWith("scaConfiguration") ||
      this.router.url.endsWith("EscalationConfiguration");
    // your code to update the model
    this.cdr.detectChanges();
  }

  hasPrivilege(privileges: string[]): boolean {
    return this.authService.hasPrivilege(privileges);
  }
  changeSelectedTab() {
    this.router.navigate(["settings/EscalationConfiguration"]);
  }
}
