import {
  Component, Inject, OnInit, Optional,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.css'],
})
export class DeleteModalComponent implements OnInit {

  deleteRuleId:string;

  deleteSca:string;

  deleteFobId:string;

  deleteAgencyId:string;

  deleteUserId:string;

  showScaDelete:boolean;

  showRuleDelete:boolean;

  showFobDelete:boolean;

  showAgencyDelete:boolean;

  showUserDelete:boolean;

  deleteIbu: string;

  showIbuDelete: boolean;

  constructor(
    public dialogRef:MatDialogRef<DeleteModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

  }

  ngOnInit() {
    this.deleteRuleId = this.data.deleteRuleId;
    this.deleteSca = this.data.deleteSca;
    this.deleteFobId = this.data.deleteFobId;
    this.deleteAgencyId = this.data.deleteAgencyId;
    this.deleteUserId = this.data.deleteUserId;

    if (this.deleteRuleId != null) {

      this.showRuleDelete = true;

    } else if (this.deleteSca != null) {

      this.showScaDelete = true;

    } else if (this.deleteFobId != null) {

      this.showFobDelete = true;

    } else if (this.deleteAgencyId != null) {

      this.showAgencyDelete = true;

    } else if (this.deleteUserId != null) {

      this.showUserDelete = true;

    }
    this.deleteIbu = this.data.deleteIbuId;
    if (this.deleteIbu != null) {

      this.showIbuDelete = true;

    }

  }

}
