<div style="overflow-x: hidden;">
<img  *ngIf="PlayVedio===false; else elseBlock" style="object-fit: scale-down; max-height: 85vh;max-width: 90vw;" src={{aedPic}} alt="Italian Trulli">
<ng-template #elseBlock>
  <video autoplay loop muted  style="object-fit: scale-down; max-height: 85vh;max-width: 90vw;">
    <source src={{aedPic}} type="video/mp4" >
  </video>

</ng-template>

  <button  style="outline:none;"  mat-raised-button mat-dialog-close  class="floating-button" >
  <mat-icon>close</mat-icon>
  </button>
</div>

