<div class="notificationDashboardWrapper mat-elevation-z8">
  <button
    (click)="getNotifcationsV2()"
    mat-icon-button
    [matMenuTriggerFor]="notificationMenuDashboard"
    [matBadge]="notificationCounter == 0 ? '' : notificationCounter"
    aria-label="Example icon-button with a menu"
    [disabled]="ngxLoading"
  >
    <span class="notificationIcon">&nbsp;</span>
  </button>
  <mat-menu
    #notificationMenuDashboard="matMenu"
    [overlapTrigger]="false"
    class="notificationsMatMenu"
  >
    <header class="notificationHeader">
      <table role="presentation">
        <tr>
          <td>
            <h3>Notifications</h3>
          </td>
          <td>
            <ngx-loading
              class="full-screen-overlay"
              [show]="ngxLoading"
              [config]="{ fullScreenBackdrop: false }"
            ></ngx-loading>
          </td>
          <td>
            <a
              class="blackTxt"
              (click)="getallNotifcations()"
              style="cursor: pointer; font-size: smaller"
              >Go to all notifications
            </a>
          </td>
        </tr>
      </table>
    </header>
    <ng-container *ngIf="!ELEMENT_DATA.length; else dataPresent">
      <div class="d-flex justify-content-center p-3 text-muted">
        <h3>No new notifications</h3>
      </div>
    </ng-container>
    <ng-template #dataPresent>
      <div>
        <div class="card">
          <div class="card-body p-0">
            <table class="toasterNotificationTable">
              <tr
                *ngFor="let noti of ELEMENT_DATA; let i = index"
                [attr.data-index]="i"
              >
                <td><img [src]="ElEMENT_getImage[i].url" alt="image" /></td>
                <td>
                  <span [innerHtml]="noti.notification"></span>
                  <div class="text-right">
                    <span
                      style="float: left; font-size: 10px; font-weight: 600"
                      >{{ noti.createdTime }}</span
                    >
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </ng-template>
    <mat-paginator
      [length]="dataLength"
      style="display: none"
      [pageSize]="5"
      [pageSizeOptions]="[5, 10, 25, 100]"
      aria-label="Select page"
    >
    </mat-paginator>
  </mat-menu>
</div>
