import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class ConfirmPasswordGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(): boolean {
    console.log("confirm-password");
    const agencyStatus = localStorage.getItem("agencyStatus");
    if (agencyStatus === "ACTIVE") {
      this.router.navigate(["/dashboard"]);
    } else if (agencyStatus === "PENDING_SIGNUP") {
      this.router.navigate(["/agency/agency-registeration"]);
    } else {
      return true;
    }
  }
}
