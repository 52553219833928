import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { SidebarComponent } from '../../sidebar.component';
import { SidebarModuleRoutingModule } from './sidebar-module-routing.module';


@NgModule({
  declarations: [
    SidebarComponent,
   
  ],
  exports : [
    SidebarComponent
  ],
  entryComponents : [
    SidebarComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SidebarModuleRoutingModule
  ]
})
export class SidebarModule { }
