<div [ngClass]="{ agencyPopupSingleContainer: isHome }">
  <div class="singleAgencyBox">
    <h2 class="heading text-center">
      {{ "cfl.settingsComponent.tab.agency.text.select" | translate }}
    </h2>
    <div class="componentContent">
      <div *ngIf="agencyList.length > 0 || ngxLoading" class="form-top-margin">
        <div class="text-center">
          <mat-form-field
            class="example-full-width"
            appearance="fill"
            id="search-agency"
          >
            <mat-label>Search Agency</mat-label>
            <input
              type="text"
              placeholder="Pick one"
              matInput
              [formControl]="selectAgency"
              [matAutocomplete]="auto"
              id="search-agency"
            />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                *ngFor="let agency of agencyList$ | async"
                [value]="agency.agencyName"
              >
                {{ agency.agencyName }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div class="text-center form-top-margin">
            <button
              (click)="setAgency()"
              id="setAgency"
              mat-raised-button
              class="primary-button"
              [disabled]="selectAgency.invalid"
            >
              {{ "cfl.settingsComponent.tab.language.btn.save" | translate }}
            </button>
          </div>
        </div>
      </div>
      <div
        *ngIf="agencyList.length === 0 && customLoading"
        class="form-top-margin"
      >
        <div class="text-center">Please Invite an agency to continue.</div>
        <div class="text-center">
          <button
            (click)="inviteAgency()"
            id="inviteAgency"
            mat-raised-button
            class="primary-button form-top-margin"
            mat-dialog-close
          >
            {{ "cfl.adminComponent.header.button.inviteAgency" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
