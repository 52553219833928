import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FlexLayoutModule } from "@angular/flex-layout";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatSliderModule } from "@angular/material/slider";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSelectModule } from "@angular/material/select";
import { MatRadioModule } from "@angular/material/radio";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatTabsModule } from "@angular/material/tabs";
import { MatDialogModule } from "@angular/material/dialog";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatMenuModule } from "@angular/material/menu";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule, MatRippleModule } from "@angular/material/core";
import { MatStepperModule } from "@angular/material/stepper";
import { MatListModule } from "@angular/material/list";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { TimePickerModule } from "@syncfusion/ej2-angular-calendars";
import { DatePickerModule } from "@syncfusion/ej2-angular-calendars";
import { MatCardModule } from "@angular/material/card";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularSvgIconModule } from "angular-svg-icon";
import { AngularFireModule } from "@angular/fire";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ConfirmPasswordComponent } from "./confirm-password/confirm-password.component";
import { DialogExampleComponent } from "./dialog-example/dialog-example.component";
import { environment } from "../environments/environment";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { AuthService } from "./core/services/auth.service";
import { ModalAedComponent } from "./modal-aed/modal-aed.component";
import { SetPasswordComponent } from "./set-password/set-password.component";
import { SidebarModule } from "./sidebar/module/sidebar/sidebar.module";
import { ForgotPasswordModalComponent } from "./forgot-password-modal/forgot-password-modal.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { DeleteModalComponent } from "./delete-modal/delete-modal.component";
import { InspectionIntervalModalComponent } from "./inspection-interval-modal/inspection-interval-modal.component";
import { MapModalComponent } from "./map-modal/map-modal.component";
import { ConfirmDialogComponent } from "./core/components/confirm-dialog/confirm-dialog.component";
import { AppGlobals } from "./app.global";
import { AuthGuard } from "./auth.guard";
import { SharedModule } from "./shared/shared.module";
import { CoreModule } from "./core/core.module";
import { MyprofileModule } from "./myprofile/myprofile.module";
import { DeviceDetailComponent } from "./device-detail/device-detail.component";
import { WifiSettingStatusComponent } from "./aed/wifi-setting-status/wifi-setting-status.component";
import { ResponsiveSidenavDirective } from "./directives/responsive-sidenav.directive";
import { ToastrModule } from "ng6-toastr-notifications";
import { AuthComponent } from "../app/layouts/auth/auth.component";
import { DashboardComponent } from "./layouts/dashboard/dashboard.component";
import { ToastNotificationComponent } from "./toast-notification/toast-notification.component";

@NgModule({
  declarations: [
    AppComponent,
    ConfirmPasswordComponent,
    DialogExampleComponent,
    ModalAedComponent,
    SetPasswordComponent,
    ForgotPasswordModalComponent,
    ForgotPasswordComponent,
    DeleteModalComponent,
    InspectionIntervalModalComponent,
    ConfirmDialogComponent,
    MapModalComponent,
    DeviceDetailComponent,
    WifiSettingStatusComponent,
    ResponsiveSidenavDirective,
    AuthComponent,
    DashboardComponent,
    ToastNotificationComponent,
  ],
  entryComponents: [
    DialogExampleComponent,
    ModalAedComponent,
    ForgotPasswordModalComponent,
    DeleteModalComponent,
    InspectionIntervalModalComponent,
    ConfirmDialogComponent,
    MapModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    MatSliderModule,
    MatToolbarModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatSidenavModule,
    MatSelectModule,
    MatRadioModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatTabsModule,
    MatDialogModule,
    FlexLayoutModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatStepperModule,
    MatListModule,
    MyprofileModule,
    NgxMaterialTimepickerModule,
    TimePickerModule,
    DatePickerModule,
    MatCardModule,
    MatRippleModule,
    AngularFireMessagingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    SidebarModule,
    CoreModule,
    SharedModule,
    AngularSvgIconModule.forRoot(),
    LeafletModule,
  ],
  providers: [AuthGuard, AuthService, DatePipe, AppGlobals],
  bootstrap: [AppComponent],
})
export class AppModule {}
