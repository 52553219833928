<div class="logoImgContainer">
  <img
    class="logoImg"
    src="../assets/revamp-images/c4l-logo.png"
    alt="C4Life Logo"
  />
</div>
<div
  class="agencySelectMenu"
  (click)="showAgencyListPopup()"
  id="agencySelectMenu"
  id="agencySelectMenu"
  *ngIf="hasPrivilege(['ROOT_ADMIN']); else elseblock"
>
  <p><small>AGENCY NAME</small></p>
  <div class="agencyBox">
    <div class="agencySelectBox">
      <table>
        <tr>
          <td>
            <img
              src="../../assets/revamp-images/icon/agency-icon.svg"
              class="defaultIconSize"
              alt="Agency Box"
            />
          </td>
          <td>
            <span>{{ selectedAgency }}</span>
            <!-- <span>{{ sharedDataService?.agencySelectedData }}</span> -->
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
<ng-template #elseblock>
  <div class="agencySelectMenu" id="agencySelectMenu">
    <p><small>AGENCY NAME</small></p>
    <div class="agencyBox">
      <div class="agencySelectBoxplain">
        <img
          src="../../assets/revamp-images/icon/agency-icon.svg"
          class="defaultIconSize"
          alt="Agency Box"
        />
        <span>{{ selectedAgency }}</span>
        <!-- <span class="p-2">{{ sharedDataService?.agencySelectedData }}</span> -->
      </div>
    </div>
  </div>
</ng-template>
<div class="navListContainer">
  <mat-nav-list>
    <mat-list-item role="listitem" id="dashboardLink">
      <div
        class="navigationMenu"
        [ngClass]="
          tabselected == 'dashboard'
            ? 'navigationMenu active'
            : 'navigationMenu'
        "
      >
        <a routerLink="/dashboard"
          ><img
            class="defaultIconSize"
            src="../../assets/revamp-images/icon/dashboard-icon.svg"
            alt="Dashboard"
          />
          <span>{{
            "cfl.settingsComponent.header.sideNav.item.dashboard" | translate
          }}</span></a
        >
      </div>
    </mat-list-item>
    <mat-list-item role="listitem" id="devicesLink">
      <div
        [ngClass]="
          tabselected == 'devices' ? 'navigationMenu active' : 'navigationMenu'
        "
      >
        <a (click)="changeRoute('/aed/monitoring')" md-tab-link
          ><img
            class="defaultIconSize"
            src="../../assets/revamp-images/icon/device-icon.svg"
            alt="Device"
          /><span>{{
            "cfl.settingsComponent.header.sideNav.item.devices" | translate
          }}</span></a
        >
      </div>
    </mat-list-item>
    <!-- [routerLink]="['/settings/calendar']" -->
    <mat-list-item role="listitem" id="settingsLinks">
      <div
        class="navigationMenu"
        [ngClass]="
          tabselected == 'setting' ? 'navigationMenu active' : 'navigationMenu'
        "
      >
        <a (click)="changeRoute('/settings/calendar')"
          ><img
            class="defaultIconSize"
            src="../../assets/revamp-images/icon/settings-icon.svg"
            alt="Settings"
          /><span>{{
            "cfl.settingsComponent.header.sideNav.item.settings" | translate
          }}</span></a
        >
      </div>
    </mat-list-item>
  </mat-nav-list>
  <div class="monitorNonMonitor">
    <table aria-hidden="true" role="presentation">
      <tr>
        <td>
          <span class="monitorAed"
            ><img
              src="../../assets/revamp-images/icon/monitored-aed.svg"
              alt="monitored aed"
            /><br />
            <span class="monitorNumber"
              >Monitored<br />{{ countC4l }}</span
            ></span
          >
        </td>
        <td>
          <span class="monitorAed nonMonitor"
            ><img
              src="../../assets/revamp-images/icon/non-monitored-aed.svg"
              alt="non monitored aed"
            /><br />
            <span class="monitorNumber"
              >UNMonitored<br />{{ countNonC4l }}</span
            ></span
          >
        </td>
      </tr>
    </table>
  </div>
</div>
<footer class="footerContainer">
  <div class="detailedMap">
    <table aria-hidden="true" role="presentation">
      <tr>
        <td>{{ "cfl.DetailedMap" | translate }}</td>
        <td>
          <mat-slide-toggle (change)="onChange($event)" id="detailedMap"
            >&nbsp;</mat-slide-toggle
          >
        </td>
      </tr>
    </table>
  </div>
  <div class="languageTranslator">
    <mat-form-field>
      <mat-select [(value)]="selected">
        <mat-option value="english" (click)="onen()" id="englishLang"
          >English</mat-option
        >
        <mat-option value="french" (click)="onfr()" id="frenchLang"
          >French</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div class="profileBlockContainer">
    <table aria-hidden="true" role="presentation">
      <tr>
        <td>
          <div class="userProfileImage">
            <img
              id="agencyPicture"
              class="isPicture"
              [src]="agencyPicture"
              alt="image"
            />
          </div>
        </td>
        <td>
          <div class="usernameDesignation">
            <p
              *ngIf="
                userService?.userData?.firstName &&
                userService?.userData?.lastName
              "
            >
              <strong>{{
                userService?.userData?.firstName +
                  " " +
                  userService?.userData?.lastName
              }}</strong>
              <small *ngIf="userService?.userData?.role">{{
                userService?.userData?.role | titlecase
              }}</small>
            </p>
          </div>
        </td>
        <td>
          <div class="profileDetail">
            <button
              mat-icon-button
              [matMenuTriggerFor]="profileDetail"
              aria-label="Example icon-button with a menu"
              id="profileDetail"
            >
              <mat-icon>more_horiz</mat-icon>
            </button>
          </div>
        </td>
      </tr>
    </table>

    <mat-menu #profileDetail="matMenu" yPosition="above">
      <div class="userProfileMenu">
        <ul>
          <li>
            <span mat-menu-item (click)="onMyProfile()" id="onMyProfile">
              <img
                src="../../assets/revamp-images/icon/user-icon.svg"
                class="defaultIconSize"
                alt="Profile Icon"
              />
              <span>My Profile</span>
            </span>
          </li>
          <li>
            <span mat-menu-item (click)="onLogout()" id="onLogout">
              <img
                src="../../assets/revamp-images/icon/logout-icon.svg"
                class="defaultIconSize"
                alt="Logout"
              />
              <span>Logout</span>
            </span>
          </li>
        </ul>
      </div>
    </mat-menu>
    <span
      style="margin: 13%; font-weight: 700; margin-top: 5%; font-size: smaller"
    >
      FE : {{ buildNo }} || BE : {{ BEBuidlNo }}</span
    >
  </div>
</footer>

<!-- Dashboard Information Icon Popup -->
<!-- <app-information-icon-popup class="dashboardFooter"></app-information-icon-popup> -->
