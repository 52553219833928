import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { AppGlobals } from "../../app.global";

@Injectable({
  providedIn: "root",
})
export class AlertService {
  constructor(
    private snackBar: MatSnackBar,
    private appGlobal: AppGlobals,
    public translation: TranslateService
  ) {}

  showRequiredFieldMessage(fieldName: string): string {
    return this.translation.instant("cfl.error.required", { value: fieldName });
  }

  showRequiredDaysMessage(fieldName: string): string {
    return this.translation.instant("cfl.error.daysrequired", {
      value: fieldName,
    });
  }
  showRequiredMonthsMessage(fieldName: string): string {
    return this.translation.instant("cfl.error.monthrequired", {
      value: fieldName,
    });
  }

  showMaxLimitMessage(maxLimit?: string): string {
    const returnString = `Max Length${
      maxLimit ? `(${maxLimit})` : ""
    } Exceeds!`;
    return returnString;
  }

  showMinLimitMessage(minLimit?: string): string {
    const returnString = `Minimum characters must be ${
      minLimit ? `${+minLimit}` : ""
    }  !`;
    return returnString;
  }

  showSpecialCharactersMessage(): string {
    const returnString = this.translation.instant("cfl.error.specialChars");
    return returnString;
  }

  showInvalidEmailMessage(): string {
    return this.translation.instant("cfl.error.valid", {
      value: this.translation.instant("cfl.email.placeholder"),
    });
  }

  showAlphanumericMessage(): string {
    return this.translation.instant("cfl.error.NotonlyNumbers");
  }

  minLengthFieldMessage(fieldName: string): string {
    return this.translation.instant("cfl.error.minLength", {
      value: fieldName,
    });
  }

  showSuccess(
    message: string,
    duration?: number,
    horizontalPosition?: any,
    verticalPosition?: any
  ) {
    const config: MatSnackBarConfig = {
      duration: duration || this.appGlobal.alertMsgTimeout,
      horizontalPosition: horizontalPosition || "center",
      verticalPosition: verticalPosition || "bottom",
      panelClass: "successSnackbar",
    };
    this.snackBar.open(message, "", config);
  }

  showFailure(
    message: string,
    duration?: number,
    horizontalPosition?: any,
    verticalPosition?: any
  ) {
    const config: MatSnackBarConfig = {
      duration: duration || this.appGlobal.alertMsgTimeout,
      horizontalPosition: horizontalPosition || "center",
      verticalPosition: verticalPosition || "bottom",
      panelClass: "errorSnackbar",
    };
    this.snackBar.open(message, "", config);
  }

  dismissPopup() {
    this.snackBar.dismiss();
  }
}
