<div class="filterMenuDashboard">
  <button
    mat-icon-button
    [matMenuTriggerFor]="filterMenuDashboard"
    aria-label="Example icon-button with a menu"
  >
    <span class="filterIcon">&nbsp;</span>
  </button>
  <mat-menu
    #filterMenuDashboard="matMenu"
    [overlapTrigger]="false"
    class="filterMatMenu"
  >
    <form>
      <header class="filterHeader">
        <table aria-hidden="true" role="presentation">
          <tr>
            <td>
              <h3>Filters</h3>
            </td>
            <td><a href="#">Clear All</a></td>
          </tr>
        </table>
      </header>
      <div class="searchBox" (click)="$event.stopPropagation()">
        <mat-form-field>
          <input type="text" matInput placeholder="Search by ID" />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div class="filterbutton" (click)="$event.stopPropagation()">
        <div>
          <span
            class="selectedFilterButton"
            *ngFor="let selectedOptions of Collection"
            >{{ selectedOptions }} <button>x</button></span
          >
        </div>
      </div>
      <div class="filterCheckList">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> AED State </mat-panel-title>
            </mat-expansion-panel-header>
            <section class="filterOptions">
              <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                [(ngModel)]="ShowAll"
              >
                <mat-radio-button
                  class="example-radio-button"
                  (change)="aedStateGroup($event)"
                  *ngFor="let aedState of AedStates"
                  [value]="aedState"
                >
                  {{ aedState }}
                </mat-radio-button>
              </mat-radio-group>
            </section>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Cabinet Status </mat-panel-title>
            </mat-expansion-panel-header>
            <section class="filterOptions">
              <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                [(ngModel)]="ShowAll"
              >
                <mat-radio-button
                  class="example-radio-button"
                  (change)="cabinetGroup($event)"
                  *ngFor="let cabinetState of CabinetStates"
                  [value]="cabinetState"
                >
                  {{ cabinetState }}
                </mat-radio-button>
              </mat-radio-group>
            </section>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Warning </mat-panel-title>
            </mat-expansion-panel-header>
            <section class="filterOptions">
              <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                [(ngModel)]="ShowAll"
              >
                <mat-radio-button
                  class="example-radio-button"
                  (change)="warningGroup($event)"
                  *ngFor="let warning of Warning"
                  [value]="warning"
                >
                  {{ warning }}
                </mat-radio-button>
              </mat-radio-group>
            </section>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Accessible </mat-panel-title>
            </mat-expansion-panel-header>
            <section class="filterOptions">
              <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                [(ngModel)]="ShowAll"
              >
                <mat-radio-button
                  class="example-radio-button"
                  (change)="accessibleGroup($event)"
                  *ngFor="let accessible of Accessible"
                  [value]="accessible"
                >
                  {{ accessible }}
                </mat-radio-button>
              </mat-radio-group>
            </section>
          </mat-expansion-panel>
        </mat-accordion>
        <div class="form-group">
          <button type="submit" class="primaryBtn" (click)="getFitleredAed()">
            {{ "cfl.dashboard.Filter" | translate }}
          </button>
        </div>
      </div>
    </form>
  </mat-menu>
</div>
