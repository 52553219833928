import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  private loaderState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private disableLoader: boolean = false;

  constructor() {}

  showLoader() {
    this.loaderState.next(true);
  }

  hideLoader() {
    this.loaderState.next(false);
  }

  getLoaderState() {
    return this.loaderState.asObservable();
  }

  setDisableLoader(disableLoader: boolean) {
    this.disableLoader = disableLoader;
  }

  getDisableLoader() {
    return this.disableLoader;
  }
}
