<div class="form main">
  <div class="form-top-margin">
    <header class="contentHeader">
      <div class="customHeaderTitle">
        <div class="heading">{{ "cfl.monitoring" | translate }}</div>
      </div>
      <div class="customHeaderFilter">
        <button
          mat-raised-button
          class="primary-button"
          id="addAED"
          (click)="addAedClicked()"
          *ngIf="hasPrivilege(['ACCOUNT_ADMIN', 'ROOT_ADMIN'])"
        >
          {{ "cfl.monitoring.table.header.addaed" | translate }}
        </button>
        <mat-form-field appearance="fill" floatLabel="never">
          <mat-label>{{
            "cfl.settingsComponent.tab.fobList.field.label.filter" | translate
          }}</mat-label>
          <input
            matInput
            [ngModel]="globalFilter"
            id="globalFilter"
            (keyup)="applyFilter($event)"
            placeholder="Enter AED"
          />
        </mat-form-field>
      </div>
      <div class="clearfix">&nbsp;</div>
    </header>
  </div>

  <div class="mat-elevation-z6 c4LifeResponsiveWrapper mt-3">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      class="monitoryTable responsiveC4LifeTable"
      aria-describedby="mydesc"
    >
      <!-- do not change until necessary -->
      <ng-container matColumnDef="chips">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="10">
          <mat-chip-list aria-label="filter selection">
            <mat-chip
              [removable]="true"
              id="cabinetChip"
              (removed)="filterChanged('', 'cabinetType')"
              [selectable]="false"
              *ngIf="filteredValues['cabinetType']"
            >
              Type : {{ filteredValues["cabinetType"] }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <mat-chip
              [removable]="true"
              id="aedStateChip"
              (removed)="filterChanged('', 'aedState')"
              [selectable]="false"
              *ngIf="filteredValues['aedState']"
            >
              AED : {{ filteredValues["aedState"] }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <mat-chip
              [removable]="true"
              id="cabinetStatusChip"
              (removed)="filterChanged('', 'cabinetStatus')"
              [selectable]="false"
              *ngIf="filteredValues['cabinetStatus']"
            >
              Cabinet : {{ filteredValues["cabinetStatus"] }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <mat-chip
              [removable]="true"
              id="warningChip"
              (removed)="filterChanged('', 'warning')"
              [selectable]="false"
              *ngIf="filteredValues['warning']"
            >
              Alert :
              {{ filteredValues["warning"] === "true" ? "Yes" : "No" }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <mat-chip
              [removable]="true"
              id="inAccessibleChip"
              (removed)="filterChanged('', 'inAccessible')"
              [selectable]="false"
              *ngIf="filteredValues['inAccessible']"
            >
              In Accessiblity :
              {{ filteredValues["inAccessible"] === "true" ? "Yes" : "No" }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <mat-chip
              [removable]="true"
              id="doorStatusChip"
              (removed)="filterChanged('', 'doorStatus')"
              [selectable]="false"
              *ngIf="filteredValues['doorStatus']"
            >
              Door : {{ filteredValues["doorStatus"] }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <mat-chip
              [removable]="true"
              id="activationStatusChip"
              (removed)="filterChanged('', 'activationStatus')"
              [selectable]="false"
              *ngIf="filteredValues['activationStatus']"
            >
              Activation : {{ filteredValues["activationStatus"] }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </th>
        <td class="hidden-column" mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <!-- Position Column -->
      <ng-container matColumnDef="cabinetType">
        <th
          mat-header-cell
          *matHeaderCellDef
          matTooltip="Cabinet Type"
          mat-sort-header
        >
          <span>{{ "cfl.monitoring.table.value.type" | translate }} </span>
          <mat-select
            id="cabinet_type_montioring"
            [(ngModel)]="filteredValues['cabinetType']"
            (ngModelChange)="filterChanged($event, 'cabinetType')"
          >
            <mat-option value="" id="all">{{
              "cfl.deviceComponent.table.opt.all" | translate
            }}</mat-option>
            <!-- <mat-option [value]="cabinettype.monitored" id="c4l">
              {{
                "cfl.deviceComponent.cabinet.table.opt.monitored" | translate
              }}</mat-option
            >
            <mat-option [value]="cabinetType1" id="non_c4l">
              {{
                "cfl.deviceComponent.cabinet.table.opt.nonMonitored" | translate
              }}</mat-option
            > -->
            <mat-option
              *ngFor="let cabinet of cabinettypes"
              [value]="cabinet.value"
            >
              {{
                "cfl.deviceComponent.cabinet.table.opt." + cabinet.id
                  | translate
              }}
            </mat-option>
          </mat-select>
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.cabinetType === 'Monitored'; else elseBlock">
            {{ "cfl.deviceComponent.cabinet.table.opt.monitored" | translate }}
          </span>
          <mat-icon
            *ngIf="element.qrCode"
            matTooltip="This is not the Parent Cabinet."
            style="min-width: 15px; min-height: 15px"
          >
            <img src="\assets\images\monitoring_warning.svg" alt="image" />
          </mat-icon>
          <ng-template #elseBlock>
            {{
              "cfl.deviceComponent.cabinet.table.opt.nonMonitored" | translate
            }}
          </ng-template>
        </td>
        <td
          mat-footer-cell
          *matFooterCellDef
          [attr.colspan]="displayedColumns.length"
          style="text-align: center"
        >
          No Records Found!
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="friendlyName">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          matTooltip="{{
            'cfl.monitoredmodal.modalform.value.username' | translate
          }}"
        >
          {{ "cfl.monitoring.table.value.username" | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          matTooltip="{{ element.friendlyName }}"
        >
          {{ element.friendlyName }}
        </td>
        <td class="hidden-column" mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="aedState">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          matTooltip=" {{ 'cfl.monitoring.table.value.aedstate' | translate }}"
        >
          {{ "cfl.monitoring.table.value.aedstate" | translate }}
          <mat-select
            id="aed_state_monitoring"
            [(ngModel)]="filteredValues['aedState']"
            (ngModelChange)="filterChanged($event, 'aedState')"
          >
            <mat-option
              *ngFor="let aedstate of aedstate"
              [value]="aedstate.value"
            >
              {{
                "cfl.deviceComponent.cabinet.table.opt." + aedstate.id
                  | translate
              }}
            </mat-option>
            <!-- <mat-option value="" id="all">{{
              "cfl.deviceComponent.table.opt.all" | translate
            }}</mat-option>
            <mat-option value="AedState1" id="ready"
              >{{ "cfl.deviceComponent.cabinet.table.opt.ready" | translate }}
            </mat-option>
            <mat-option value="AedState2" id="not_ready">
              {{
                "cfl.deviceComponent.cabinet.table.opt.notReady" | translate
              }}</mat-option
            >
            <mat-option value="AedState3" id="na">
              {{
                "cfl.deviceComponent.cabinet.table.opt.missing" | translate
              }}</mat-option
            > -->
          </mat-select>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.aedStatus | titlecase }}
          <mat-icon
            *ngIf="
              element.cabinetStatus != cabinetstate.decomissioned ||
              element.cabinetStatus != cabinetstate.temporary_disabledView
            "
            matTooltip="{{ element.aedStatus }}"
            style="width: 10px; display: none"
          >
          </mat-icon>
          <mat-icon
            *ngIf="
              element.cabinetStatus === cabinetstate.decomissioned ||
              element.cabinetStatus === cabinetstate.temporary_disabledView
            "
            matTooltip="This is the last known State of the AED."
            style="min-width: 15px; min-height: 15px"
          >
            <img src="\assets\images\monitoring_warning.svg" alt="image" />
          </mat-icon>
        </td>
        <td class="hidden-column" mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="cabinetStatus">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          matTooltip="{{
            'cfl.monitoredmodal.modalform.value.cabinetstate' | translate
          }}"
        >
          {{ "cfl.monitoring.table.value.cabinetstate" | translate }}
          <mat-select
            id="cabinet_state_monitoring"
            [(ngModel)]="filteredValues['cabinetStatus']"
            (ngModelChange)="filterChanged($event, 'cabinetStatus')"
          >
            <!-- <mat-option
          *ngFor="let cabinetstate of cabinetstates"
        
          [value]="cabinetstate.value"
        >
        
       {{ "cfl.deviceComponent.table.opt." + cabinetstate.id | translate }}
        </mat-option> -->

            <mat-option value="" id="all">
              {{ "cfl.deviceComponent.table.opt.all" | translate }}
            </mat-option>
            <mat-option [value]="cabinetstate.enabled" id="enable">
              {{ "cfl.users.component.tableHeader.title.enabled" | translate }}
            </mat-option>
            <mat-option [value]="cabinetstate.decomissioned" id="malfunctioned">
              {{ "cfl.Malfunctioned" | translate }}
            </mat-option>
            <mat-option
              [value]="cabinetstate.temporary_disabledView"
              id="temporary_replacement"
            >
              {{ "cfl.TemporaryReplacement" | translate }}
            </mat-option>
          </mat-select>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          matTooltip="{{ element.cabinetStatus }}"
        >
          {{ element.cabinetStatus | titlecase }}
        </td>
        <td class="hidden-column" mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="warning">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          matTooltip="{{ 'cfl.monitoring.table.value.warning' | translate }}"
        >
          {{ "cfl.monitoring.table.value.warning" | translate }}
          <mat-select
            id="warning_monitoring"
            [(ngModel)]="filteredValues['warning']"
            (ngModelChange)="filterChanged($event, 'warning')"
          >
            <mat-option value="">All</mat-option>
            <mat-option value="true">Yes</mat-option>
            <mat-option value="false">No</mat-option>
          </mat-select>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          matTooltip="{{ element.warning }}"
        >
          {{ element.warningStatus ? "Yes" : "No" }}
        </td>
        <td class="hidden-column" mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="inAccessible">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          matTooltip="{{
            'cfl.monitoring.table.value.accessiblity' | translate
          }}"
        >
          {{ "cfl.monitoring.table.value.accessiblity" | translate }}
          <mat-select
            id="inacc_montioring"
            [(ngModel)]="filteredValues['inAccessible']"
            (ngModelChange)="filterChanged($event, 'inAccessible')"
          >
            <mat-option value="">All</mat-option>
            <mat-option value="true">Yes</mat-option>
            <mat-option value="false">No</mat-option>
          </mat-select>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          matTooltip="{{ element.accessibility }}"
        >
          {{ element.accessibility ? "Location Open" : "Location Closed" }}
        </td>
        <td class="hidden-column" mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="doorStatus">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          matTooltip="{{ 'cfl.monitoring.table.value.door' | translate }}"
        >
          {{ "cfl.monitoring.table.value.door" | translate }}
          <mat-select
            id="door_monitoring"
            [(ngModel)]="filteredValues['doorStatus']"
            (ngModelChange)="filterChanged($event, 'doorStatus')"
          >
            <mat-option
              *ngFor="let doorstate of doorstate"
              [value]="doorstate.value"
            >
              {{
                "cfl.deviceComponent.cabinet.table.opt." + doorstate.id
                  | translate
              }}
            </mat-option>

            <!-- <mat-option value="" id="all">All</mat-option>
            <mat-option value="doorState3" id="close">Close</mat-option>
            <mat-option value="doorState2" id="open">Open</mat-option>
            <mat-option value="N/A" id="na">N/a</mat-option> -->
          </mat-select>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          matTooltip="{{ element.doorStatus }}"
        >
          {{ element.doorStatus | titlecase }}
        </td>
        <td class="hidden-column" mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="activationStatus">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          matTooltip="{{
            'cfl.monitoredmodal.modalform.value.activationstatus' | translate
          }}"
        >
          {{ "cfl.monitoring.table.value.activation" | translate }}
          <mat-select
            id="activation_status_monitoring"
            [(ngModel)]="filteredValues['activationStatus']"
            (ngModelChange)="filterChanged($event, 'activationStatus')"
          >
            <mat-option value="" id="all">All</mat-option>
            <mat-option value="ARMED" id="armed">Armed</mat-option>
            <mat-option value="DISARMED" id="disarmed">DisArmed</mat-option>
          </mat-select>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          matTooltip="{{ element.activationStatus }}"
        >
          {{ element.activationStatus | titlecase }}
        </td>
        <td class="hidden-column" mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="installerEmail">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          matTooltip="{{ 'cfl.monitoring.table.value.installer' | translate }}"
        >
          {{ "cfl.monitoring.table.value.installer" | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          matTooltip="{{ element.installerEmail }}"
        >
          {{ element.installerEmail !== "-" ? element.installerEmail : "N/A" }}
        </td>
        <td class="hidden-column" mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            id="more_menu_monitoring"
            [matMenuTriggerData]="{ element: element }"
          >
            <em class="material-icons">more_vert</em>
          </button>
        </td>
        <td class="hidden-column" mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <!-- <tr mat-row *matHeaderRowDef="['chips']; sticky: true"></tr> -->
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr
        mat-footer-row
        *matFooterRowDef="displayedColumns"
        [hidden]="dataSource?.data?.length > 0"
      ></tr>
    </table>
    <!-- <table>
      <tr *ngIf="dataSource?.data?.length == 0">
        <td [attr.colspan]="displayedColumns.length" style="text-align: center">
          No Records Found!
        </td>
      </tr>
    </table> -->
  </div>

  <mat-menu #menu="matMenu">
    <ng-template matMenuContent let-element="element">
      <button
        mat-menu-item
        class="buttonMin340"
        id="triggeredDetection"
        *ngIf="
          element.cabinetStatus === cabinetstate.enabled &&
          element.cabinetType === cabinettype.monitored &&
          !hasPrivilege(['ACCOUNT_STANDARD'])
        "
        (click)="triggerDetection(element)"
      >
        <mat-icon>dialpad</mat-icon>
        <span>{{ "cfl.TriggeredDetection" | translate }}</span>
      </button>
      <!-- <button
        mat-menu-item
        class="buttonMin340"
        id="aedSwap"
        (click)="aedSwap(element)"
        *ngIf="!hasPrivilege(['ACCOUNT_STANDARD'])"
      >
        <mat-icon>swap_vert</mat-icon>
        <span>{{ "cfl.Swap" | translate }}</span>
      </button> -->
      <button
        mat-menu-item
        class="buttonMin340"
        id="changeStatu_sMalfunctioned_monitoring"
        (click)="aedDisabled(element)"
        *ngIf="
          hasPrivilege(['ROOT_ADMIN', 'ACCOUNT_ADMIN']) &&
          element.cabinetStatus === cabinetstate.enabled
        "
      >
        <mat-icon>not_accessible</mat-icon>
        <span>{{
          "cfl.users.component.action.btn.disenable" | translate
        }}</span>
      </button>
      <button
        mat-menu-item
        class="buttonMin340"
        id="changeStatus_Enable_monitoring"
        (click)="changeStatus(element, cabinetstate.enabled)"
        *ngIf="
          element.cabinetStatus !== cabinetstate.enabled &&
          hasPrivilege(['ROOT_ADMIN', 'ACCOUNT_ADMIN'])
        "
      >
        <mat-icon>done</mat-icon>
        <span>{{ "cfl.users.component.action.btn.enable" | translate }}</span>
      </button>
      <button
        mat-menu-item
        class="buttonMin340"
        id="rowClicked_monitoring"
        (click)="rowClicked(element)"
      >
        <mat-icon>source</mat-icon>
        <span>{{ "cfl.More" | translate }}</span>
      </button>
    </ng-template>
  </mat-menu>
  <div class="mt-3">
    <mat-paginator
      [length]="resultsLength"
      [showFirstLastButtons]="true"
      [pageSizeOptions]="[10, 25, 100]"
    >
    </mat-paginator>
  </div>
</div>
