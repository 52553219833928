<div class="main">
  <div class="form-top-margin">
    <div class="heading">{{ "cfl.ChangeUserInfo" | translate }}</div>
  </div>
  <div class="form-top-margin text-center">
    <img class="circleImage" [src]="agencyPicture" alt="image" />
  </div>
</div>
<div class="form-side-margin">
  <form [formGroup]="changeUserNameForm" class="example-form">
    <div class="row">
      <div class="col-md-6 mt-4">
        <span class="form-field-width">
          <mat-form-field appearance="outline" class="width500">
            <mat-label>{{
              "cfl.settingsComponent.tab.changeInfo.group.field.changeUsernameOrEmail.placeholder"
                | translate
            }}</mat-label>
            <input
              matInput
              formControlName="userName"
              id="username"
              autocomplete="off"
              readonly
              required
            />
          </mat-form-field>
        </span>
      </div>
      <div class="col-md-6 mt-4">
        <span class="form-field-width">
          <mat-form-field appearance="outline" class="width500">
            <mat-label>
              {{
                "cfl.settingsComponent.tab.changeInfo.group.field.changeFirstname.placeholder"
                  | translate
              }}
            </mat-label>
            <input
              matInput
              placeholder="{{
                'cfl.settingsComponent.tab.changeInfo.group.field.changeFirstname.placeholder'
                  | translate
              }}"
              formControlName="firstName"
              id="firstname"
              autocomplete="off"
              required
              maxlength="20"
            />
          </mat-form-field>
          <mat-error
            *ngIf="
              changeUserNameForm.controls.firstName.invalid &&
              changeUserNameForm.controls.firstName.touched
            "
            >{{ FirstNameError() }}
          </mat-error>
        </span>
      </div>
      <div class="col-md-6 mt-4">
        <span class="form-field-width form-top-margin">
          <mat-form-field appearance="outline" class="width500">
            <mat-label>{{
              "cfl.settingsComponent.tab.changeInfo.group.field.changeLastname.placeholder"
                | translate
            }}</mat-label>
            <input
              matInput
              placeholder="{{
                'cfl.settingsComponent.tab.changeInfo.group.field.changeLastname.placeholder'
                  | translate
              }}"
              formControlName="lastName"
              id="lastname"
              autocomplete="off"
              required
              maxlength="20"
            />
          </mat-form-field>
          <mat-error
            *ngIf="
              changeUserNameForm.controls.lastName.invalid &&
              changeUserNameForm.controls.lastName.touched
            "
            >{{ LastNameError() }}
          </mat-error>
        </span>
      </div>
      <div class="col-md-6"></div>
    </div>
  </form>
  <div class="d-flex justify-content-center mt-5">
    <button
      (click)="submitChangeUsername()"
      mat-raised-button
      class="primary-button"
      [disabled]="saveDisable || !changeUserNameForm.valid"
    >
      {{ "cfl.settingsComponent.tab.changeInfo.group.btn.submit" | translate }}
    </button>
  </div>
</div>
