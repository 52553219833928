import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class SetPasswordGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate() {
    console.log("set password");
    let isNavigate = false;
    let IssetPassEnable = localStorage.getItem("IssetPassEnable");
    if (IssetPassEnable) {
      isNavigate = true;
      return isNavigate;
    }
    // this.router.navigate(["/auth/signin"]);

    return isNavigate;
  }
}
