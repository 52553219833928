import { Component, HostListener, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, NavigationEnd } from "@angular/router";
import { AuthService } from "./core/services/auth.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { HandleEmitterService } from "./core/services/handle-emitter.service";
import { LoaderService } from "./core/services/loader.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  state$: Observable<boolean>;
  notificationCounter = 0;
  title = "ositech";
  message;
  isSetPassowrd = false;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(
    private authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    private emitter: HandleEmitterService,
    private messagingService: AuthService,
    private translation: TranslateService,
    private router: Router,
    private loaderService: LoaderService
  ) {
    this.state$ = this.loaderService.getLoaderState();
  }

  @HostListener("window:storage", ["$event"])
  onStorageChange(event) {
    if (
      event.key === "currentAccessToken" &&
      event.oldValue &&
      !event.newValue
    ) {
      this.router.navigate(["/auth/signin"]);
    }
  }
  //run when component loads
  ngOnInit() {
    let loginAgency = localStorage.getItem("agencyId");
    if (localStorage.getItem("currentAccessToken")) {
      if (localStorage.getItem("agencyId")) {
        this.authService.getRealTimeUpdate().subscribe((data: any) => {
          let notificationData = data.payload.data();
          if (
            notificationData.message &&
            notificationData.agencyId == loginAgency
          ) {
            this.updateNotificationCount(notificationData);
          }
        });
      }
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isSetPassowrd =
          event.url.includes("setPassword") ||
          event.url.includes("confirmPassword") ||
          event.url.includes("agencyRegistration") ||
          event.url.includes("signin");
      }
    });

    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage;
    let language = localStorage.getItem("preferredLanguage");
    if (language !== "fr") {
      language = "en";
    }
    if (language) this.translation.use(language);
  }

  updateNotificationCount(data) {
    this.emitter.sendNotification(data);
  }
}
