import { CabinetStates } from "src/app/namings";

// export function convertLocalTimezone(date: Date): Date {
//   const timeOffset = new Date().getTimezoneOffset();
//   const localDate = new Date(date.getTime() - timeOffset * 60 * 1000);
//   return localDate;
// }

export function sortByAscending(array, key) {
  let arr = array.data ? array.data : array;
  return arr.sort((a, b) => (a[key] < b[key] ? -1 : 1));
}
export function sortByDescending(array, key) {
  let arr = array.data ? array.data : array;
  return arr.sort((a, b) => (a[key] < b[key] ? -1 : 1));
}


export function formatDateToString(dateTime, separator, format = 'YYYY-MM-DD') {
  const dateObject = new Date(dateTime);
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  const day = String(dateObject.getDate()).padStart(2, "0");

  let formattedDate;

  switch (format) {
    case 'YYYY-MM-DD':
      formattedDate = `${year}${separator}${month}${separator}${day}`;
      break;
    case 'DD-MM-YYYY':
      formattedDate = `${day}${separator}${month}${separator}${year}`;
      break;
      case 'MM-DD-YYYY':
        formattedDate = `${month}${separator}${day}${separator}${year}`;
        break;
    default:
      formattedDate = `${year}${separator}${month}${separator}${day}`;
      break;
    }

  return formattedDate;
}

export function separateDateAndTime(dateTimeString) {
  const [datePart, timePart] = dateTimeString.split(' ');

  return { date: datePart, time: timePart };
}


export function convertToTemporaryDisabled(status){
  if (
    status == CabinetStates.temporary_disabled    ) {
      return CabinetStates.temporary_disabledView;
  }
  else{  return status;
  }
}