import { Component, EventEmitter, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { HandleEmitterService } from "src/app/core/services/handle-emitter.service";
import { FilterService } from "../../../core/services/filter.service";
import { IFilter } from "../../Interface/IFilter";
import { Filter } from "../../model/Filter";
import { fadeOut, rowsAnimation } from "../../../shared/animation/matrow";
import {
  CabinetTypes,
  CabinetStates,
  AedState,
  doorStates,
  Accessibility,
} from "src/app/namings";
@Component({
  selector: "app-dashboard-filter",
  templateUrl: "./dashboard-filter.component.html",
  styleUrls: ["./dashboard-filter.component.scss"],
  animations: [rowsAnimation, fadeOut],
})
export class DashboardFilterComponent implements OnInit {
  searchForm: FormGroup;
  categoriesValues: any[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public filterService: FilterService,
    private fb: FormBuilder
  ) {}
  AedStates: Array<any> = [
    { name: AedState.ready, value: AedState.ready, id: "02" },
    { name: AedState.notready, value: AedState.notready, id: "03" },
    { name: AedState.noaed, value: AedState.noaed, id: "04" },
  ];
  Alerts: Array<any> = [
    { name: "Yes", value: "yes", id: "1" },
    { name: "No", value: "no", id: "2" },
  ];
  CabinetTypes: Array<any> = [
    { name: "Monitored", value: CabinetTypes.monitored, id: "1" },
    { name: "Unmonitored", value: CabinetTypes.Unmonitored, id: "2" },
  ];
  CabinetStates: Array<any> = [
    { name: CabinetStates.enabled, value: CabinetStates.enabled, id: "05" },
    // { name: "DISABLE", value: "DISABLE", id: "06" },
    {
      name: CabinetStates.temporary_disabledView,
      value: CabinetStates.temporary_disabled,
      id: "07",
    },
    {
      name: CabinetStates.decomissioned,
      value: CabinetStates.decomissioned,
      id: "08",
    },
  ];
  Warning: Array<any> = [
    { name: "Yes", value: true, id: "10" },
    { name: "No", value: false, id: "11" },
  ];
  Accessible: Array<any> = [
    { name: Accessibility.accessible, value: true, id: "13" },
    { name: Accessibility.inaccessible, value: false, id: "14" },
  ];
  doorStates: any = [
    { id: 0, name: doorStates.all, friendlyName: doorStates.all },
    { id: 1, name: doorStates.open, friendlyName: doorStates.open },
    { id: 2, name: doorStates.closed, friendlyName: doorStates.closed },
  ];
  activationStates: any = [
    { id: 0, name: "All" },
    { id: 1, name: "ARMED" },
    { id: 2, name: "DISARMED" },
  ];
  allCategories: any = [
    { id: 1, lbl: "Cabinet Type", name: "cabinetType" },
    { id: 2, lbl: "Cabinet Status", name: "cabStatus" },
    { id: 3, lbl: "AED State", name: "aedStatus" },
    { id: 4, lbl: "Alert", name: "warningStatus" },
    { id: 5, lbl: "Accessible", name: "accessibilityStatus" },
  ];
  selectedValues: any = [];
  dashboardFilter: IFilter = new Filter();
  filter = new Filter();
  tempAedData: any[];

  ngOnInit(): void {
    console.log("----------------data-----------", this.data);
    // this.allProfiles = this.empService.getProfiles();
    this.createForm();
    this.setDefault();
    this.filterService.removeFilter.subscribe((res) => {
      if (res.removeFilter) {
        if (res.isClearAll) {
          this.searchForm.reset();
          this.clearFilter();
        }
        if (res.isClearSerach) {
          this.searchForm.controls.textsearch.reset();
        }
        let filteredData = this.filterArray(
          this.data.aedData,
          this.filterService.dashboardFilters
        );
        this.sendData({ filter: this.filter, filteredData: filteredData });
      }
    });

    if (this.filterService.dashboardFilters.searchText != "") {
      let formValue = {
        textsearch: this.filterService.dashboardFilters.searchText,
        category: "",
      };
      this.searchForm.setValue(formValue);
    }
  }
  createForm() {
    this.searchForm = this.fb.group({
      textsearch: [""],
      category: ["Select Category"],
    });
  }
  applyFilter(type, value, searchText?) {
    this.tempAedData = this.data.aedData;
    this.filter = this.filterService.dashboardFilters;
    this.filter.searchText =
      searchText != undefined ? searchText.trim() : this.filter.searchText;
    Object.keys(this.filter).forEach((f) => {
      if (f == type) {
        let index = this.filter[f].findIndex((i) => i == value);
        if (index < 0) {
          this.filter[f].push(value);
        } else {
          this.filter[f].splice(index, 1);
        }
      }
    });
    let filteredData = this.filterArray(this.tempAedData, this.filter);
    let defaultfilter = new Filter();
    if (
      Object.values(this.filter).join() == Object.values(defaultfilter).join()
    ) {
      this.sendData({ filter: this.filter, filteredData: filteredData });
    }

    this.filterService.dashboardFilters = this.filter;

    this.sendData({ filter: this.filter, filteredData: filteredData });
  }

  filterArray(array, filters) {
    console.log("-----filters------", filters);
    console.log("-----array------", array);
    let aedData = array;
    if (filters.searchText != "") {
      aedData = array.filter((obj) => {
        let result =
          this.isInclude(obj.friendlyName, filters) ||
          this.isInclude(obj.aedSerialNumber, filters) ||
          this.isInclude(obj.cabinetSerial, filters);
        return result;
      });
    }
    return aedData.filter((x) =>
      Object.keys(filters).every((f) =>
        filters[f].length == 0 || !Array.isArray(filters[f])
          ? []
          : filters[f].some((z) => z == x[f])
      )
    );
  }
  clearFilter() {
    this.selectedValues = [];
    this.categoriesValues = [];
    this.data.aedData = this.tempAedData ? this.tempAedData : this.data.aedData;
    this.filter = new Filter();
    this.searchForm.reset();
    this.applyFilter("", "", "");

    // this.setDefault();
  }

  isInclude(key, filters) {
    return key.toLowerCase().indexOf(filters.searchText.toLowerCase()) >= 0;
  }

  sendData(data) {
    this.filterService.filteredData = data.filteredData;
    this.filterService.filterEmitter.emit(data);
  }
  changeCategory(event) {
    this.categoriesValues = [];

    if (event.value.id == 1) {
      this.categoriesValues = this.CabinetTypes;
    } else if (event.value.id == 2) {
      this.categoriesValues = this.CabinetStates;
    } else if (event.value.id == 3) {
      this.categoriesValues = this.AedStates;
    } else if (event.value.id == 4) {
      this.categoriesValues = this.Warning;
    } else if (event.value.id == 5) {
      this.categoriesValues = this.Accessible;
    }
  }
  setDefault() {
    let formValue = {
      textsearch: "",
      category: "",
    };
    this.searchForm.setValue(formValue);
  }
  onSelectValue(value, category) {
    if (category == "textsearch") {
      let textIndex = this.selectedValues.findIndex((i) => i.id == 0);
      let selectedTextIndex = this.filterService.selectedFilter.findIndex(
        (i) => i.id == 0
      );

      if (textIndex != -1) {
        this.selectedValues.splice(textIndex, 1);
      }
      if (selectedTextIndex != -1) {
        this.filterService.selectedFilter.splice(selectedTextIndex, 1);
      }
      this.selectedValues.push({
        category: category,
        name: value,
        value: value,
        id: 0,
      });
      this.filterService.selectedFilter.push({
        category: category,
        name: value,
        value: value,
        id: 0,
      });
      this.applyFilter("", "", value);

      return;
    }

    this.selectedValues = JSON.parse(
      JSON.stringify(this.filterService.selectedFilter)
    );
    let index = this.selectedValues.findIndex((i) => i.id == value.id);
    if (index == -1) {
      this.selectedValues.push({ ...value, category: category.name });

      this.filterService.selectedFilter = JSON.parse(
        JSON.stringify(this.selectedValues)
      );
      this.applyFilter(category.name, value.value, this.filter.searchText);
    }
  }

  setActiveCategory(category) {
    let index = this.filterService.selectedFilter.findIndex(
      (filter) => filter.value == category.value && category.name == filter.name
    );
    return index < 0 ? false : true;
  }
}
