import { Component, OnInit, Inject, Optional } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AuthService } from "../core/services/auth.service";

@Component({
  selector: "app-modal-aed",
  templateUrl: "./modal-aed.component.html",
  styleUrls: ["./modal-aed.component.css"],
})
export class ModalAedComponent implements OnInit {
  labelPosition;

  aedStatus;

  serialNumber;

  t2;

  aedPic;

  currentAccessToken: string;

  httpHeaders: any;

  PlayVedio = false;

  showRadio = false;

  images: any[];

  constructor(
    public dialogRef: MatDialogRef<ModalAedComponent>,
    private authService: AuthService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.aedPic = this.data.pic;
    let check = this.aedPic.split(".");
    let CheckValue = check.indexOf("mp4");

    if (CheckValue >= 0) {
      this.PlayVedio = true;
    }
  }

  hasPrivilege(privileges: string[]): boolean {
    return this.authService.hasPrivilege(privileges);
  }
}
