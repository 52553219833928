import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, TimeoutError, throwError } from "rxjs";
import { catchError, finalize, timeout } from "rxjs/operators";
import { InternetConnectivityService } from "../services/internet-connectivity.service";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "../services";
import { Router } from "@angular/router";
import { LoaderService } from "../services/loader.service";

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private alertService: AlertService,
    private translationService: TranslateService,
    private dialog: MatDialog,
    private InternetConnectivityService: InternetConnectivityService,
    private loaderService: LoaderService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        this.loaderService.hideLoader();
        if (error instanceof TimeoutError) {
          this.InternetConnectivityService.onlineStatus.next(
            window.navigator.onLine
          );
          return throwError("Request timed out. Please try again later.");
        } else if (
          error.error.errors &&
          (error.error.errors[0].errorCode === "AUTH-0004" ||
            error.error.errors[0].errorCode === "AUTH-0001" ||
            error.error.errors[0].errorCode === "AUTH-0005")
        ) {
          this.dialog.closeAll();
          localStorage.clear();
          this.router.navigate(["/auth/signin"]);
          this.alertService.showSuccess(
            this.translationService.instant("cfl.SessionExpired")
          );
        }
        this.InternetConnectivityService.onlineStatus.next(
          window.navigator.onLine
        );
        return throwError(error);
      })
    );
  }
}
