import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { AppGlobals } from "../app.global";
// import { IAed } from "../shared/Interface/IAed";
import { ISwapAed } from "../shared/Interface/ISwapAed";
import { CabinetTypes,AedState,doorStates,CabinetStates } from "../namings";
@Injectable({
  providedIn: "root",
})
export class AedService {
  baseURLc4life: string;
  disableNotes: "";

  notes = [
    'If the Readiness detection is not affected whether inside or outside the case then only the model number is displayed.  The Sentinel Admin has  the " in case/no case" models so I am wondering if there was a reason why they did that because they also use the camera for recognition.  Need to think about this.',
    "Aviation models may be removed if no implication on readiness detection.  A note can be made in the notes field.",
    "These models have been discontnued but may have to be supported",
    "We have these models at Ositech. Zoll AED plus is a trainer and The LifePak CR Plus also is a trainer",
  ];

  manufacturers = [
    { id: "1", value: "Cardiac Science" },
    { id: "2", value: "Defibtech" },
    { id: "3", value: "HeartSine" },
    { id: "4", value: "Philips" },
    { id: "5", value: "Stryker" },
    { id: "6", value: "Zoll" },
    { id: "7", value: "others" },
  ];
  cabinettypes = [
    { id: "monitored", value: CabinetTypes.monitored },
    { id: "nonMonitored", value: CabinetTypes.Unmonitored },
  ];
  cabinetstates=[
    { id: "all", value:CabinetStates.all},
    { id: "enable", value: CabinetStates.enabled },
    { id: "malfunctioned", value: CabinetStates.decomissioned },
    { id: "temporary_replacement", value: CabinetStates.temporary_disabledView },
  ];
  aedstatus=[
    {
         id:"all"  ,value:""
    },
     {
          id: "ready",value :AedState.ready
     },
     { 
           id:"notReady" , value:AedState.notready
     },
     {
      id:"missing",value:AedState.noaed
     }
  ];
  doorstatus=[
    {
         id:"all"  ,value:doorStates.all
    },
     {
          id: "closed",value :doorStates.closed
     },
     { 
           id:"open" , value:doorStates.open
     }
    
  ];
  modelList = [
    {
      id: 1,
      manufacturerId: 1,
      value: "Powerheart G3 Plus AED",
      notes: this.notes[3],
    },
    {
      id: 2,
      manufacturerId: 1,
      value: "Powerheart G5 AED",
      notes: "",
    },
    {
      id: 3,
      manufacturerId: 1,
      value: "Powerheart G3 Pro",
      notes: "",
    },
    {
      id: 4,
      manufacturerId: 1,
      value: "Powerheart G3 Plus AED (Aviation)",
      notes: this.notes[2],
    },
    {
      id: 5,
      manufacturerId: 1,
      value: "Firstsave G3  AED",
      notes: this.notes[3],
    },
    {
      id: 6,
      manufacturerId: 2,
      value: "Lifeline AED (in case)",
      notes: "",
    },
    {
      id: 7,
      manufacturerId: 2,
      value: "Lifeline AED (no case)",
      notes: this.notes[2],
    },
    {
      id: 8,
      manufacturerId: 2,
      value: "Lifeline AUTO AED (in case)",
      notes: this.notes[3],
    },
    {
      id: 9,
      manufacturerId: 2,
      value: "Lifeline AUTO AED (no case)",
      notes: this.notes[1],
    },
    {
      id: 10,
      manufacturerId: 2,
      value: "Lifeline View AED (in case)",
      notes: this.notes[1],
    },
    {
      id: 11,
      manufacturerId: 2,
      value: "Lifeline View AED (no case)",
      notes: this.notes[1],
    },
    {
      id: 12,
      manufacturerId: 2,
      value: "Lifeline View AED (no case) (Aviation)",
      notes: this.notes[2],
    },
    {
      id: 13,
      manufacturerId: 3,
      value: "Samiritan AED",
      notes: this.notes[3],
    },
    {
      id: 14,
      manufacturerId: 3,
      value: "Samaritan PAD 300P",
      notes: this.notes[3],
    },
    {
      id: 15,
      manufacturerId: 3,
      value: "Samaritan PAD 350P",
      notes: "",
    },
    {
      id: 16,
      manufacturerId: 3,
      value: "Samaritan PAD 360P",
      notes: "",
    },
    {
      id: 17,
      manufacturerId: 3,
      value: "Samaritan PAD 450P",
      notes: "",
    },
    {
      id: 18,
      manufacturerId: 3,
      value: "Samaritan PAD 500P",
      notes: "",
    },
    {
      id: 19,
      manufacturerId: 4,
      value: "HeartStart FRx (in case)",
      notes: this.notes[1],
    },
    {
      id: 20,
      manufacturerId: 4,
      value: "HeartStart FRx (no case)",
      notes: this.notes[1],
    },
    {
      id: 21,
      manufacturerId: 4,
      value: "HeartStart FRx (no case) (Aviation)",
      notes: this.notes[2],
    },
    {
      id: 22,
      manufacturerId: 4,
      value: "HeartStart OnSite (in case)",
      notes: this.notes[1],
    },
    {
      id: 23,
      manufacturerId: 4,
      value: "HeartStart OnSite (no case)",
      notes: this.notes[1],
    },
    {
      id: 24,
      manufacturerId: 4,
      value: "HeartStart FR3 (no case)",
      notes: this.notes[1],
    },
    {
      id: 25,
      manufacturerId: 4,
      value: "HeartStart FR2+ (no case)",
      notes: this.notes[1],
    },
    {
      id: 26,
      manufacturerId: 5,
      value: "LIFEPAK CR Plus AED",
      notes: this.notes[3],
    },
    {
      id: 27,
      manufacturerId: 5,
      value: "LIFEPAK CR2 (in case)",
      notes: this.notes[1],
    },
    {
      id: 28,
      manufacturerId: 5,
      value: "LIFEPAK CR2 (no case)",
      notes: this.notes[1],
    },
    {
      id: 29,
      manufacturerId: 5,
      value: "LIFEPAK Express AED",
      notes: "",
    },
    {
      id: 30,
      manufacturerId: 5,
      value: "LIFEPAK 1000 AED",
      notes: "",
    },
    {
      id: 31,
      manufacturerId: 5,
      value: "LIFEPAK 500 AED",
      notes: this.notes[3],
    },
    {
      id: 32,
      manufacturerId: 6,
      value: "AED Plus",
      notes: this.notes[3],
    },
    {
      id: 33,
      manufacturerId: 6,
      value: "AED Plus (Aviation)",
      notes: this.notes[2],
    },
    {
      id: 34,
      manufacturerId: 6,
      value: "AED Pro",
      notes: "",
    },
    {
      id: 35,
      manufacturerId: 6,
      value: "AED 3",
      notes: "",
    },
    {
      id: 36,
      manufacturerId: 7,
      value: "others",
      notes: "",
    },
  ];

  constructor(protected http: HttpClient, private appGlobal: AppGlobals) {
    this.baseURLc4life = this.appGlobal.baseApiUrl;
  }

  addAED(body) {
    const agencyId = localStorage.getItem("agencyId");
    return this.http.post<any>(
      `${this.baseURLc4life}aed/v2/add?agencyId=${agencyId}`,
      body
    );
  }

  manualInspectionReminder(body) {
    const agencyId = localStorage.getItem("agencyId");
    return this.http.post(
      `${this.baseURLc4life}agency/manual-inspection-reminder?agencyId=${agencyId}`,
      body,
      {
        observe: "response",
      }
    );
  }

  updateAED(body) {
    const agencyId = localStorage.getItem("agencyId");
    return this.http.post<any>(
      `${this.baseURLc4life}aed/v2/update?agencyId=${agencyId}`,
      body
    );
  }

  getRegisteredAed(id) {
    const agencyId = localStorage.getItem("agencyId");
    return this.http.get<any>(
      `${this.baseURLc4life}aed/v2/get/${id}?agencyId=${agencyId}`
    );
  }

  getAeds() {
    return this.http.get<any>(`${this.baseURLc4life}aed/v2/get-all`);
  }

  getAgencyAeds(page: number, size: number): Observable<any> {
    const agencyId = localStorage.getItem("agencyId");
    return this.http.get(
      `${this.baseURLc4life}aed/v2/get-aed-monitoring-tab-info?agencyID=${agencyId}&page=${page}&size=${size}`
    );
  }

  getallAeds(page: number, size: number) {
    const agencyId = localStorage.getItem("agencyId");
    return this.http.get(
      `${this.baseURLc4life}aed-v-2-controller/c4life/v1/aed/v2/get-all-agency-aeds-v2?agencyID=${agencyId}&page=${page}&size=${size}`
    );
  }

  getExpiredAeds(page: number, size: number): Observable<any> {
    const agencyId = localStorage.getItem("agencyId");
    return this.http.get(
      `${this.baseURLc4life}aed/v2/get-aed-expiry-tab-info?agencyID=${agencyId}&page=${page}&size=${size}`
    );
  }

  getAedsByAgencyID(AgencyId) {
    return this.http.get<any>(
      `${this.baseURLc4life}aed/v2/get-all-available-aed-by-agency-id?agencyId=${AgencyId}`
    );
  }

  getAedsInformationByID(aedSerialNumber) {
    return this.http.get<any>(
      `${this.baseURLc4life}aed/v2/get-aed-modal-info?aedSerialNumber=${aedSerialNumber}`
    );
  }

  getPendingAedsForInstallation(
    pageIndex: number,
    pageSize: number
  ): Observable<any> {
    const agencyId = localStorage.getItem("agencyId");
    return this.http.get(
      `${this.baseURLc4life}aed/v2/get-aed-installation-tab-info?agencyID=${agencyId}&page=${pageIndex}&size=${pageSize}`
    );
  }

  getPendingAedsForApproval(
    pageIndex: number,
    pageSize: number
  ): Observable<any> {
    const agencyId = localStorage.getItem("agencyId");
    return this.http.get(
      `${this.baseURLc4life}aed/v2/get-aed-approval-tab-info?agencyID=${agencyId}&page=${pageIndex}&size=${pageSize}`
    );
  }

  updateCabinetStatus(aedSerialNumber, state, notes) {
    const body = { aedSerialNumber, state, notes };
    return this.http.put<any>(
      `${this.baseURLc4life}aed/v2/update/cabinet/state`,
      body
    );
  }

  getInsatallers() {
    const agencyId = localStorage.getItem("agencyId");
    return this.http.get(
      `${this.baseURLc4life}aed/v2/installer/list?agencyId=${agencyId}`
    );
  }

  getCabinetDetails() {
    return this.http.get(`${this.baseURLc4life}cabinet/get-all`);
  }

  getAgencySpecificCabinets() {
    return this.http.get(`${this.baseURLc4life}cabinet/get-all-by-agency`);
  }

  triggerDetection(aedSerialNumber) {
    const agencyId = localStorage.getItem("agencyId");
    return this.http.get(
      `${this.baseURLc4life}aed/v2/triggerDetection/${aedSerialNumber}?agencyId=${agencyId}`
    );
  }

  swapAeds(data: ISwapAed) {
    const agencyId = localStorage.getItem("agencyId");
    return this.http.post<any>(
      `${this.baseURLc4life}aed/v2/swap?agencyId=${agencyId}`,
      data
    );
  }

  approve(cabinetSerialNumber, approve, name) {
    const agencyId = localStorage.getItem("agencyId");
    return this.http.post<any>(
      `${this.baseURLc4life}install/approve/session/${cabinetSerialNumber}?approve=${approve}&name=${name}&agencyId=${agencyId}`,
      {}
    );
  }

  upload(deviceSerialNumber, pics) {
    const urlencoded = new FormData();
    if (pics.picOne) {
      urlencoded.append("picOne", pics.picOne);
    }
    if (pics.picTwo) {
      urlencoded.append("picTwo", pics.picTwo);
    }
    if (pics.picThree) {
      urlencoded.append("picThree", pics.picThree);
    }
    return this.http.post<any>(
      `${this.baseURLc4life}/install/upload/pictures/session/${deviceSerialNumber}`,
      urlencoded
    );
  }

  getSearchById(Id, Page, Size) {
    const agencyId = localStorage.getItem("agencyId");
    return this.http.get<any>(
      `${this.baseURLc4life}aed/v2/search-aed?serialNumber=${Id}&agencyId=${agencyId}&page=${Page}&size=${Size}`
    );
  }

  getInstallationCabinetsByEmail(email: string): Observable<any> {
    return this.http.get(
      `${this.baseURLc4life}aed/v2/get-installation-cabinets?userEmail=${email}`
    );
  }

  unAssignInstallersFromAeds(
    payLoad: any[],
    prevEmail: string,
    type: string
  ): Observable<any> {
    return this.http.post(
      `${this.baseURLc4life}aed/v2/unassign-cabinets?previousInstallerEmail=${prevEmail}&type=${type}`,
      payLoad
    );
  }

  enableMonitoredFields = [
    "agencyCalendarId",
    "city",
    "contactName",
    "country",
    "email",
    "location",

    "locationDetails",
    "locationType",
    "phoneNumber",
    "state",
    "streetAddress",
    "vicinityType",
    "zipCode",
    "lastInspectionDate",
    "name",
    "primaryDateGroup",
    // "primaryAdultPadExpiry",
    // "primaryBatteryExpiry",
    // "primaryChildPadExpiry",
    "purchasedDate",
    "secondaryAdultPadExpiry",
    "secondaryChildPadExpiry",
    "secondaryBatteryExpiry",
    "aedStatus",
    "selectedOption",
  ];
  enableNonMonitoredFields = [
    "agencyCalendarId",
    "city",
    "contactName",
    "country",
    "email",
    "location",
    "locationDetails",
    "locationType",
    "phoneNumber",
    "state",
    "streetAddress",
    "vicinityType",
    "zipCode",
    "lastInspectionDate",
    "name",
    "primaryDateGroup",

    "purchasedDate",
    "secondaryAdultPadExpiry",
    "secondaryChildPadExpiry",
    "secondaryBatteryExpiry",
    "aedStatus",
    "selectedOption",
  ];
  enablePendingForInstallationFields = [
    // "cabinetType",
    "agencyCalendarId",
    "city",
    "contactName",
    "country",
    "email",
    "location",
    "locationDetails",
    "locationType",
    "phoneNumber",
    // "manufacturer",
    // "model",
    "state",
    "streetAddress",
    "vicinityType",
    "zipCode",
    "lastInspectionDate",
    "name",
    "primaryDateGroup",

    "purchasedDate",
    "secondaryAdultPadExpiry",
    "secondaryChildPadExpiry",
    "secondaryBatteryExpiry",
    "aedStatus",
    "installerId",
    "agencyCalendarId",
    "otherNotes",
    "selectedOption",
  ];
  enablePendingForApprovalFields = [
    "agencyCalendarId",
    "lastInspectionDate",
    "primaryDateGroup",
    // "primaryAdultPadExpiry",
    // "primaryBatteryExpiry",
    // "primaryChildPadExpiry",
    "purchasedDate",
    "secondaryAdultPadExpiry",
    "secondaryChildPadExpiry",
    "secondaryBatteryExpiry",
    "agencyCalendarId",
    "otherNotes",
    "name",
    "selectedOption",
  ];

  enableAedformFields(
    form: FormGroup,
    installationStatus: String,
    cabinetType: string
  ) {
    let fieldsArr = [];
    if (installationStatus == "APPROVED" || installationStatus == null) {
      if (cabinetType == "Monitored") {
        fieldsArr = this.enableMonitoredFields;
      } else {
        fieldsArr = this.enableNonMonitoredFields;
      }
    } else if (installationStatus == "INSTALLATION") {
      fieldsArr = this.enablePendingForInstallationFields;
    } else if (installationStatus == "PENDING_APPROVAL") {
      fieldsArr = this.enablePendingForApprovalFields;
    }
    fieldsArr.forEach((field) => {
      if (field == "primaryDateGroup") {
        let group = form.controls[field] as FormGroup;
        group.controls["primaryAdultPadExpiry"].enable();
        group.controls["primaryBatteryExpiry"].enable();
        group.controls["primaryChildPadExpiry"].enable();
      }
      form.controls[field].enable();
      if (form.value.locationType == "others") {
        form.controls["locationTypeDetails"].enable();
      }
    });
    return form;
  }
}
