import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireMessaging } from "@angular/fire/messaging";
import * as jwtDecode from "jwt-decode";
import { saveAs } from "file-saver";
import { AppGlobals } from "../../app.global";
import { promise } from "protractor"; //eslint-disable-line
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import { UserService } from "src/app/settings/users/user.service";
import { SharedMapService } from "./shared-map.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  baseURLc4life: string;
  baseURLkeycloak = "http://192.168.100.16:8080";
  currentMessage = new BehaviorSubject(null);
  private user2 = new BehaviorSubject<string>("john");
  accessWebUsers = ["ROOT_ADMIN", "ACCOUNT_ADMIN", "ACCOUNT_STANDARD"];
  constructor(
    private http: HttpClient,
    private firestore: AngularFirestore,
    private angularFireMessaging: AngularFireMessaging,
    private appGlobal: AppGlobals,
    private userService: UserService,
    private router: Router,
    private translation: TranslateService,
    private sharedMapService: SharedMapService,
    private dialog: MatDialog
  ) {
    this.baseURLc4life = this.appGlobal.baseApiUrl;
  }

  loadingStatus(newUser) {
    this.user2.next(newUser);
  }

  setDeviceToken(token, body): any {
    return this.http.post(
      `${this.baseURLc4life}user-device-token/map-device-token/${token}`,
      body
    );
  }

  getCustomToken(headers) {
    return this.http.get<any>(`${this.baseURLc4life}user/customToken`, {
      headers,
    });
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.setDeviceToken(token, "").subscribe(() => {
          // some code here
        });
      },
      () => {
        // somecode here
      }
    );
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      this.currentMessage.next(payload);
    });
  }

  showCustomNotification(payload: any) {
    const notifyData = payload.notification;
    const { title } = notifyData;
    const options = {
      body: notifyData.body,
    };
    const notify: Notification = new Notification(title, options);
    notify.onclick = (event) => {
      event.preventDefault();
      window.location.href = "https://c4life-d78e6.web.app";
    };
  }

  getUserDetailsSignup(myHeaders, urlencoded, agencyName) {
    return fetch(
      `${this.baseURLkeycloak}/auth/realms/${agencyName}/protocol/openid-connect/token`,
      {
        method: "POST",
        // mode: 'no-cors',
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      }
    );
  }

  getCabinetPolicies() {
    const temp = `events/${localStorage.getItem("agencyId")}`;
    return this.firestore.doc(temp).valueChanges();
  }
  getCabinetPolicies2() {
    const temp = `events/${localStorage.getItem("agencyId")}`;
    return this.firestore.doc(temp).snapshotChanges();
  }
  getNotificationPolicies() {
    const temp = `notifications/${localStorage.getItem("agencyId")}`;
    return this.firestore.doc(temp).snapshotChanges();
  }

  getScaPolicies() {
    return this.firestore.doc("scaEvents/sca").snapshotChanges();
  }

  getRealTimeUpdate() {
    const temp = `notifications/agencydata`;
    return this.firestore.doc(temp).snapshotChanges();
  }

  updateAedStatus(headers, body3) {
    return this.http.put(
      `${this.baseURLc4life}cabinet/update-aed-status/${body3.serialNumber}?aedStatus=${body3.aedStatus}`,
      body3,
      { headers }
    );
  }

  printCSV(data, fileName) {
    const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    const csv = data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    csv.unshift(header.join(","));
    const csvArray = csv.join("\r\n");

    const blob = new Blob([csvArray], { type: "text/csv" });
    saveAs(blob, fileName);
  }

  hasPrivilege(privileges: string[]): boolean {
    let hasPrivilege = false;
    if (localStorage.getItem("currentAccessToken")) {
      const decoded = jwtDecode(localStorage.getItem("currentAccessToken"));
      privileges.forEach((privilege) => {
        if (decoded.Privileges.includes(privilege)) {
          hasPrivilege = true;
        }
      });
    }
    return hasPrivilege;
  }

  checkWebUser(): boolean {
    let isWebUser = false;
    if (localStorage.getItem("currentAccessToken")) {
      const decodeToken = jwtDecode(localStorage.getItem("currentAccessToken"));
      this.accessWebUsers.forEach((role) => {
        if (decodeToken.Privileges.includes(role)) {
          isWebUser = true;
        }
      });
    }
    return isWebUser;
  }

  isLogin(): boolean {
    if (localStorage.getItem("currentAccessToken")) {
      return true;
    }
    return false;
  }

  onLogout() {
    this.userService.logout().subscribe(() => {
      this.router.navigate(["/auth/signin"]);
      const language = localStorage.getItem("preferredLanguage");
      localStorage.removeItem("currentAccessToken");
      localStorage.clear();
      this.sharedMapService.getMap(false);
      if (language) {
        localStorage.setItem("preferredLanguage", language);
        this.translation.use(language);
      }
      this.userService.clearUserData();
      this.dialog.closeAll();
    });
  }
}
