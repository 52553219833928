import { Injectable, EventEmitter } from "@angular/core";
import { Subject } from "rxjs";
import { INotification } from "src/app/notifications/INotification";

@Injectable({
  providedIn: "root",
})
export class HandleEmitterService {
  ee: EventEmitter<number> = new EventEmitter<number>();

  notification: Subject<INotification> = new Subject<INotification>();

  constructor() {}

  sendNotification(data) {
    this.notification.next(data);
  }

  returnNotification() {
    return this.notification.asObservable();
  }
}
