<div
  class="filterMenuDashboard"
  style="position: absolute; top: 50px; right: 0px; z-index: 999"
>
  <button
    [matBadge]="
      filterService.selectedFilter.length == 0
        ? ''
        : filterService.selectedFilter.length
    "
    mat-icon-button
    style="border: 0; background: transparent"
    (click)="openDialog()"
  >
    <span
      [ngClass]="
        filterService.selectedFilter.length > 0
          ? 'activeFilter filterIcon'
          : 'filterIcon'
      "
      >&nbsp;</span
    >
  </button>
  <br />
  <button
    *ngIf="filterService.selectedFilter.length > 0"
    mat-icon-button
    class="secondaryFilterButton"
    [matMenuTriggerFor]="menu"
    aria-label="filter Menu"
    [attr.aria-expanded]="isExpanded ? 'true' : 'false'"
  >
    <span class="filterIcon secondary"> &nbsp; </span>
  </button>
  <mat-menu #menu="matMenu" class="secondaryFilterMenu">
    <div class="side_menu_filter">
      <app-filter-child></app-filter-child>
    </div>
  </mat-menu>
</div>
<app-information-icon-popup
  class="dashboardFooter"
></app-information-icon-popup>
<div id="map" style="position: relative; top: -19px"></div>
