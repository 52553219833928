import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";

import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "../core/services/index";
import { CustomValidators } from "../shared/validators";
import { AuthService } from "../core/services/auth.service";
import { UserService } from "../settings/users/user.service";
@Component({
  selector: "app-forgot-password-modal",
  templateUrl: "./forgot-password-modal.component.html",
  styleUrls: ["./forgot-password-modal.component.css"],
})
export class ForgotPasswordModalComponent implements OnInit {
  emailForm: FormGroup;

  email: string;

  check = 0;

  isLoading = false;

  constructor(
    private fb: FormBuilder,
    private Auth: AuthService,
    private router: Router,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ForgotPasswordModalComponent>,
    public alertService: AlertService,
    public translation: TranslateService,
    public userService: UserService
  ) {}

  ngOnInit() {
    this.emailForm = this.fb.group({
      email: [
        "",
        [Validators.required, Validators.email, CustomValidators.emailPattern],
      ],
    });
  }

  // send user input data to api, if response is successful than navigate to
  // forgot password page
  onSubmit() {
    this.isLoading = true;
    this.email = this.emailForm.get("email").value;
    return this.userService.sendVerificationToken(this.email).subscribe(
      () => {
        setTimeout(() => {
          this.alertService.showSuccess(
            this.translation.instant("cfl.form.forgot.modal.success.message")
          );
        }, 1000);

        localStorage.setItem("IsforgetEnable", "1");
        this.isLoading = false;
        localStorage.setItem("userName", this.email);
        this.dialogRef.close();
        this.Auth.loadingStatus("paki");
        this.router.navigate(["/forgot-password"]);
      },
      (err: HttpErrorResponse) => {
        this.isLoading = false;
        this.Auth.loadingStatus("paki");
        if (err.error.errors[0].errorCode === "US-0017") {
          this.alertService.showFailure(
            this.translation.instant("cfl.form.signin.error.disabledAccount")
          );
        } else {
          this.alertService.showFailure(err.error.errors[0].errorMessage);
        }
      }
    );
  }
}
