<div class="popupAEDComponent">
  <header>
    <h3>
      Search results test
      <span mat-dialog-close class="closeButton pull-right">x</span>
    </h3>
  </header>
  <form [formGroup]="form">
    <div class="popupAEDContent">
      <!-- Row 1 -->
      <div class="myBtn searchFilterForm">
        <mat-form-field appearance="fill" style="margin-right: 3%">
          <mat-label>AED Serial</mat-label>
          <input
            #input
            id="name"
            formControlName="name"
            autocomplete="off"
            (keyup)="onkey(input.value)"
            matInput
          />
          <mat-icon matSuffix (click)="clearFilters()" style="cursor: pointer"
            >cancel</mat-icon
          >
        </mat-form-field>
        <button
          mat-button
          class="custom-btn"
          (click)="SearchById()"
          [disabled]="btn"
        >
          Search
        </button>
      </div>
    </div>
    <div class="searchFilterTableContent popupAEDComponent">
      <table
        class="popupAEDTable"
        aria-hidden="true"
        role="presentation"
        *ngFor="let aeds of dataSource; let i = index"
      >
        <tbody (click)="getMarker(i)">
          <tr>
            <td>
              <strong style="text-align: center">{{
                aeds.aedSerialNumber
              }}</strong>
              <img class="aedImage" src="{{ aeds.pic }}" alt="AED-Image" />
            </td>
            <td>
              <div class="monitorNonMonitor">
                <table aria-hidden="true" role="presentation">
                  <tr>
                    <td>
                      <span
                        class="monitorAed"
                        [ngClass]="{
                          nonRedMonitor: aeds.status === 'NOT READY'
                        }"
                      >
                        <span class="monitorNumber">{{
                          aeds.status
                        }}</span></span
                      >
                    </td>
                    <td>
                      <span class="monitorAed nonRedMonitor">
                        <span class="monitorNumber">{{
                          aeds.accessbility ? "Accessible" : "In-Accessible"
                        }}</span></span
                      >
                    </td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <mat-paginator
      [length]="count"
      [pageSizeOptions]="[5, 10, 25, 100]"
    ></mat-paginator>
  </form>
  <div></div>
</div>
