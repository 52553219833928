<div class="container">
  <div class="verticalDiv">
    <div class="main">
      <h2 class="heading text-center">Forgot Password</h2>
    </div>

    <div class="signInForm">
      <form
        [formGroup]="forgotPasswordForm"
        class="example-form"
        autocomplete="off"
      >
        <div class="text-center">
          <img
            src="../assets/images/password.png"
            class="headingIcon"
            alt="Password"
          />
        </div>
        <div class="formField">
          <mat-form-field appearance="outline" style="width: 100%">
            <input
              matInput
              placeholder="{{
                'cfl.form.forgot.component.verificationCode.placeholder'
                  | translate
              }}"
              id="vCode"
              formControlName="vCode"
            />
          </mat-form-field>
          <div class="errorDivFormField">
            <mat-error
              *ngIf="
                setPasswordControls.vCode.invalid &&
                setPasswordControls.vCode.touched
              "
            >
              {{ getvCodeErrorMessages() }}</mat-error
            >
          </div>
        </div>
        <div class="formField">
          <mat-form-field appearance="outline" style="width: 100%">
            <input
              matInput
              placeholder="{{
                'cfl.new.placeholder'
                  | translate
                    : {
                        value:
                          'cfl.form.forgot.component.password.placeholder'
                          | translate
                      }
              }}"
              [type]="hide2 ? 'password' : 'text'"
              id="newPassword"
              formControlName="newPassword"
              autocomplete="new-password"
              [maxlength]="passwordMaxLength"
            />
            <mat-icon
              style="cursor: pointer"
              matSuffix
              (click)="hide2 = !hide2"
            >
              {{ hide2 ? "visibility_off" : "visibility" }}
            </mat-icon>
          </mat-form-field>
        </div>
        <div class="formField">
          <mat-form-field appearance="outline" style="width: 100%">
            <input
              matInput
              placeholder="{{
                'cfl.confirm.placeholder'
                  | translate
                    : {
                        value:
                          'cfl.form.forgot.component.password.placeholder'
                          | translate
                      }
              }}"
              [type]="hide3 ? 'password' : 'text'"
              id="confirmPassword"
              formControlName="confirmPassword"
              [maxlength]="passwordMaxLength"
            />
            <mat-icon
              style="cursor: pointer"
              matSuffix
              (click)="hide3 = !hide3"
            >
              {{ hide3 ? "visibility_off" : "visibility" }}
            </mat-icon>
          </mat-form-field>
          <div class="errorDivFormField">
            <ng-container *ngFor="let errorType of errorType">
              <mat-error [ngClass]="getErrorClass(errorType)">
                • {{ getErrorMessage(errorType) }}
              </mat-error>
            </ng-container>

            <mat-error
              *ngIf="
                setPasswordControls.confirmPassword.invalid &&
                setPasswordControls.confirmPassword.touched
              "
            >
              {{ getconfirmPasswordErrorMessages() }}
            </mat-error>
          </div>
        </div>
      </form>
    </div>
    <br />
    <div class="formField text-center">
      <button
        class="form-top-margin"
        mat-raised-button
        [disabled]="!forgotPasswordForm.valid"
        (click)="verifyToken()"
        class="primary-button"
      >
        {{ "cfl.form.forgot.component.btn.submit" | translate }}
      </button>
    </div>
  </div>
</div>
