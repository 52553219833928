import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AgencyService } from "../../agency/agency.service";
import { AlertService } from "../../core/services";
import { Observable } from "rxjs";
import { map, startWith, switchMap } from "rxjs/operators";
import { FormControl, Validators } from "@angular/forms";
import { HandleEmitterService } from "../../core/services/handle-emitter.service";
import { NotificationsService } from "src/app/notifications/notifications.service";
import { FilterService } from "src/app/core/services/filter.service";
import { Filter } from "src/app/shared/model/Filter";
import { DialogExampleComponent } from "src/app/dialog-example/dialog-example.component";

@Component({
  selector: "app-agency-popup",
  templateUrl: "./agency-popup.component.html",
  styleUrls: ["./agency-popup.component.scss"],
})
export class AgencyPopupComponent implements OnInit {
  agencyList: any = [];
  isHome: boolean = false;
  ngxLoading = false;
  customLoading = true;
  selectAgency = new FormControl("", Validators.required);
  agencyList$: Observable<any[]>;

  constructor(
    private agencyService: AgencyService,
    private alertService: AlertService,
    private router: Router,
    private dialog: MatDialog,
    private HandleEmitterService: HandleEmitterService,
    public notificationService: NotificationsService,
    public filterService: FilterService
  ) {}

  // get all agency from the api to populate in dropdown
  getAllAgencys() {
    this.ngxLoading = true;
    this.agencyService.getAllAgency().subscribe(
      (data: any) => {
        this.ngxLoading = false;
        this.agencyList = data.body;
        this.agencyList.sort((a, b) =>
          a.agencyName.toLowerCase() > b.agencyName.toLowerCase() ? 1 : -1
        );
        this.loadObservable();
      },
      (error) => {
        this.customLoading = false;
        this.ngxLoading = false;
      }
    );
  }

  ngOnInit() {
    if (this.router.url === "/home") {
      this.isHome = true;
    }
    this.getAllAgencys();
  }

  setAgency() {
    let selectedobj = this.agencyList.filter(
      (a) => a.agencyName == this.selectAgency.value
    );
    if (selectedobj[0]) {
      selectedobj = selectedobj[0];
      localStorage.setItem("agencyId", selectedobj.agencyId);
      localStorage.setItem("agencyStatus", selectedobj.agencyStatus);
      localStorage.setItem("currentLat", selectedobj.jurisdictionLatitude);
      localStorage.setItem("currentLng", selectedobj.jurisdictionLongitude);
      this.agencyService.setAgencyPicture(selectedobj.agencyLogo);
      this.agencyService.setAgencyName(selectedobj.agencyName);
      this.getSideBarData(selectedobj.agencyId);
      this.getUnseenNotificationCount(selectedobj.agencyId);

      this.notificationService.setAgencychange(true);
      this.filterService.selectedFilter = [];
      this.filterService.dashboardFilters = new Filter();
      this.filterService.removeFilter.emit({
        removeFilter: true,
        isClearAll: true,
        isClearSerach: true,
      });
    } else {
      this.alertService.showFailure("Invalid Agency");
    }
  }

  loadObservable() {
    this.agencyList$ = this.selectAgency.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
  }

  inviteAgency() {
    // this.router.navigate(["/settings/admin"]);
    const dialogRef = this.dialog.open(DialogExampleComponent, {
      height: "350px",
      width: "500px",
      autoFocus: true,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res === "update") {
        // this.getAllAgency();
      }
    });
  }

  private _filter(value: any) {
    const filterValue = value.toLowerCase();
    return this.agencyList.filter((option) =>
      option.agencyName.toLowerCase().includes(filterValue)
    );
  }

  getSideBarData(agencyId) {
    this.ngxLoading = true;
    this.agencyService.getSidebar(agencyId).subscribe(
      (res: any) => {
        this.ngxLoading = false;
        this.agencyService.setMonitoredAed(res.body.monitoredAedCount);
        this.agencyService.setNonMonitoredAed(res.body.nonMonitoredAedCount);
        this.agencyService.setAgencyPicture(res.body.logo);
        if (this.router.url == "/dashboard") {
          this.HandleEmitterService.ee.emit();
          this.dialog.closeAll();
          return;
        }

        this.router.navigate(["/dashboard"]);
        this.dialog.closeAll();
      },
      (error) => {
        this.ngxLoading = false;
      }
    );
  }
  //////// Get Unseen Notification count

  getUnseenNotificationCount(agencyId) {
    this.ngxLoading = true;
    this.notificationService.getUnseenNotificationCount(agencyId).subscribe(
      (data: any) => {
        console.log(
          "Unseen notification count",
          data.body.unseenNotificationCount
        );
        this.notificationService.setAgencyNotificationCount(
          data.body.unseenNotificationCount
        );
      },
      (error) => {
        this.ngxLoading = false;
      }
    );
  }
}
