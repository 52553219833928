export const AppConstants = {
  passwordMaxLength: 64,

  errorType: [
    "minLength8",
    "hasUpperCase",
    "hasLowerCase",
    "hasNumber",
    "hasInvalidCharacter",
  ],
};
