import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import "@angular/compiler";
import { ConfirmPasswordComponent } from "./confirm-password/confirm-password.component";
import { SetPasswordComponent } from "./set-password/set-password.component";
import { ForgotPasswordModalComponent } from "./forgot-password-modal/forgot-password-modal.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { AuthGuard } from "./auth.guard";
import { AuthComponent } from "./layouts/auth/auth.component";
import { DashboardComponent } from "./layouts/dashboard/dashboard.component";
import { HomeGuard } from "./home.guard";
import { DashboardGuard } from "./dashboard.guard";
import { ConfirmPasswordGuard } from "./core/guards/confirm-password/confirm-password.guard";
import { ForgetPasswordGuard } from "./forget-password.guard";
import { SetPasswordGuard } from "./set-password.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/dashboard",
    pathMatch: "full",
  },
  {
    path: "",
    component: AuthComponent,
    children: [
      {
        path: "",
        redirectTo: "/auth/signin",
        pathMatch: "full",
      },
      {
        path: "auth",
        loadChildren: () =>
          import("./signin/signin.module").then((m) => m.SigninModule),
        data: {
          preload: true,
          delay: true,
        },
        canActivate: [AuthGuard],
      },
      {
        path: "set-password",
        component: SetPasswordComponent,
        canActivate: [SetPasswordGuard],
      },
      { path: "forgotPasswordModal", component: ForgotPasswordModalComponent },

      {
        path: "forgot-password",
        component: ForgotPasswordComponent,
        canActivate: [ForgetPasswordGuard],
      },
      {
        path: "confirm-password",
        component: ConfirmPasswordComponent,
        canActivate: [AuthGuard, ConfirmPasswordGuard],
      },
      {
        path: "agency",
        loadChildren: () =>
          import("./agency/agency.module").then((m) => m.AgencyModule),
        canActivate: [AuthGuard],
        data: {
          preload: true,
          delay: true,
        },
      },
    ],
  },
  {
    path: "",
    component: DashboardComponent,
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
        canActivate: [DashboardGuard],
      },

      {
        path: "settings",
        loadChildren: () =>
          import("./settings/settings.module").then((m) => m.SettingsModule),
        canActivate: [DashboardGuard],
        data: {
          preload: true,
          delay: true,
        },
      },
      {
        path: "myprofile",
        loadChildren: () =>
          import("./myprofile/myprofile.module").then((m) => m.MyprofileModule),
        canActivate: [DashboardGuard],
        data: {
          preload: true,
          delay: true,
        },
      },
      {
        path: "guardian/angel",
        loadChildren: () =>
          import("./guardian-angel/guardian.angel.module").then(
            (m) => m.GuardianAngelModule
          ),
        canActivate: [DashboardGuard],
        data: {
          preload: true,
          delay: true,
        },
      },
      {
        path: "aed",
        loadChildren: () => import("./aed/aed.module").then((m) => m.AedModule),
        canActivate: [DashboardGuard],
        data: {
          preload: true,
          delay: true,
        },
      },

      {
        path: "notifications",
        loadChildren: () =>
          import("./notifications/notifications.module").then(
            (m) => m.NotificationsModule
          ),
        canActivate: [DashboardGuard],
        data: {
          preload: true,
          delay: true,
        },
      },
    ],
  },
  {
    path: "home",
    loadChildren: () =>
      import("./blank/blank.module").then((m) => m.BlankModule),
    canActivate: [AuthGuard, HomeGuard],
  },
  { path: "**", redirectTo: "/dashboard" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
