import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ForgetPasswordGuard implements CanActivate {

  constructor(private router : Router){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log("ForgetPasswordGuard");
    let isNavigate = false;
    let IsforgetEnable =  localStorage.getItem("IsforgetEnable")
    if(IsforgetEnable){
      isNavigate = true
      return isNavigate;
    }
    this.router.navigate(["/auth/signin"]);
    
    return isNavigate;
  }
  
}
