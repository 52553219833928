import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const currentAccessToken: string =
      localStorage.getItem("currentAccessToken");
    if (!currentAccessToken) {
      return next.handle(req);
    }
    req = req.clone({
      headers: req.headers.set("Authorization", `Bearer ${currentAccessToken}`),
    });
    return next.handle(req);
  }
}
