import { BehaviorSubject , Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SharedInitialMapService {

  // eslint-disable-next-line
  emailValidationRegex: any = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  specialCharactersValid: any =/^[A-Za-z0-9 ]+$/;

  //passwordRegex: any = /^(?=.*[A-Z])(?=.*?[0-9]).{8,30}$/; //Minimum 8 characters 1 upper case 1 numeric

  passwordRegex : any = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
  
  phoneValidationRegex: any = /^\D?(\d{3})|\D?\D?(\d{3})\D?(\d{4})$/

  extValidationRegex: any = new RegExp('^[0-9]{1,5}$');

  numbersRegex:any= /.*[a-zA-Z]+.*/;

  onlyNumbersRegex:any= /^[0-9]*$/;

  inputMaxlength = 32;

  countriesSubject = new BehaviorSubject<[]>([]);

  countries$ = this.countriesSubject.asObservable();

  statesSubject = new BehaviorSubject<[]>([]);

  states$ = this.statesSubject.asObservable();

  citiesSubject = new BehaviorSubject<[]>([]);

  cities$ = this.citiesSubject.asObservable();

  public editDataDetails: any = [];
  public subject = new Subject<any>();
  private messageSource = new  BehaviorSubject(this.editDataDetails);
  currentMessage = this.messageSource.asObservable();

  constructor(
    private http: HttpClient,

  ) {

    

  }

    getInitialMap(message){
      this.messageSource.next(message)
    }


}
