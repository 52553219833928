import { Component, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { AedService } from "../../aedservice";
import { AlertService } from "../../../core/services";
import { AedSwapComponent } from "../../swap/aed.swap.component";
import { AuthService } from "../../../core/services/auth.service";
import {
  CabinetTypes,
  AedState,
  CabinetStates,
  doorStates,
} from "src/app/namings";
import { merge, of as observableOf, Subject, Subscription } from "rxjs";
import {
  catchError,
  map,
  startWith,
  switchMap,
  debounceTime,
  distinctUntilChanged,
} from "rxjs/operators";
import { AEDDisableComponent } from "../../aeddisable/aeddisable.component";
import { HandleEmitterService } from "src/app/core/services/handle-emitter.service";
import { convertToTemporaryDisabled } from "src/app/core/utilities/utils";

@Component({
  selector: "app-monitoring",
  styleUrls: ["./monitoring.component.css"],
  templateUrl: "./monitoring.component.html",
})
export class MonitoringComponent {
  cabinettype = CabinetTypes;
  cabinetstate = CabinetStates;
  isHidden = true;
  cabinetstates: Array<object> = [];
  cabinettypes: Array<object> = [];
  aedstate: Array<object> = [];
  doorstate: Array<object> = [];
  displayedColumns: string[] = [
    "cabinetType",
    "friendlyName",
    "aedState",
    "cabinetStatus",
    "warning",
    "inAccessible",
    "doorStatus",
    "activationStatus",
    "installerEmail",
    "action",
  ];
  resultsLength = 0;
  globalFilter: string = "";
  filteredValues = {
    cabinetType: "",
    aedState: "",
    cabinetStatus: "",
    warning: "",
    inAccessible: "",
    doorStatus: "",
    activationStatus: "",
  };
  colName: string;
  modelChanged: Subject<any> = new Subject<any>();
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  private timer: NodeJS.Timeout;
  subscription: Subscription;

  constructor(
    private aedService: AedService,
    private router: Router,
    private alertService: AlertService,
    private dialog: MatDialog,
    private authService: AuthService,
    private translation: TranslateService,
    private emitter: HandleEmitterService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.cabinetstates = this.aedService.cabinetstates;
    this.cabinettypes = this.aedService.cabinettypes;
    this.aedstate = this.aedService.aedstatus;
    this.doorstate = this.aedService.doorstatus;
    if (localStorage.getItem("agencyId")) {
      const temp = `notifications/agencydata`;

      this.subscription = this.authService
        .getRealTimeUpdate()
        .subscribe((data: any) => {
          let loginAgency = localStorage.getItem("agencyId");
          let notificationData = data.payload.data();
          if (
            notificationData?.message &&
            notificationData.agencyId == loginAgency
          ) {
            // alert(notificationData.message)
            this.aedrealTimeReflection(notificationData);
          }
        });
    }
    this.getAllAeds();
    if (localStorage.getItem("auto_update") === "true") {
      this.timer = setInterval(() => {
        this.getAllAeds();
      }, +localStorage.getItem("auto_update_time") * 1000);
    } else {
      clearInterval(this.timer);
    }
  }

  ngAfterViewInit() {
    if (this.hasPrivilege(["ACCOUNT_STANDARD"])) {
      this.displayedColumns = [
        "cabinetType",
        "friendlyName",
        "aedState",
        "cabinetStatus",
        "warning",
        "inAccessible",
        "doorStatus",
        "activationStatus",
        "installerEmail",
        "action",
      ];
    }
    this.modelChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.getAllAeds();
      });
    this.sort.sortChange.subscribe(() => {
      this.dataSource.sort = this.sort;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  addAedClicked() {
    this.router.navigate(["/aed/addaed"]);
  }

  rowClicked(element) {
    this.route.url.subscribe((route) => {
      this.router.navigate(["/aed/modal"], {
        queryParams: {
          aedSerialNo: element.aedSerialNumber,
          tab: "monitoring",
        },
      });
    });
  }

  changeStatus(element, status) {
    this.aedService
      .updateCabinetStatus(element.aedSerialNumber, status, null)
      .subscribe(
        () => {
          this.getAllAeds();
        },
        () => {
          this.alertService.showFailure(
            this.translation.instant(
              "cfl.monitoredmodal.modalform.value.unableToUpdateAedState"
            )
          );
        }
      );
  }

  applyFilter(event: Event) {
    this.globalFilter = (event.target as HTMLInputElement).value;
    this.dataSource.filter = this.globalFilter.trim().toLowerCase();
  }

  customFilterPredicate() {
    const myFilterPredicate = (data: any, filter: string): boolean => {
      if (this.globalFilter !== "") {
        const nameCompare = data.friendlyName.toLowerCase();
        const emailCompare = data.installerEmail.toLowerCase();
        if (
          filter !== "" &&
          (nameCompare.indexOf(filter) !== -1 ||
            emailCompare.indexOf(filter) !== -1)
        ) {
          return true;
        }
        return false;
      } else {
        if (
          this.filteredValues.cabinetType !== "" &&
          data.cabinetType !== this.filteredValues.cabinetType
        ) {
          return false;
        }
        if (
          this.filteredValues.activationStatus !== "" &&
          data.activationStatus !== this.filteredValues.activationStatus
        ) {
          return false;
        }
        if (
          this.filteredValues.aedState !== "" &&
          data.aedStatus !== this.filteredValues.aedState
        ) {
          return false;
        }
        if (
          this.filteredValues.cabinetStatus !== "" &&
          data.cabinetStatus !== this.filteredValues.cabinetStatus
        ) {
          return false;
        }
        if (
          this.filteredValues.doorStatus !== "" &&
          data.doorStatus !== this.filteredValues.doorStatus
        ) {
          return false;
        }
        if (
          this.filteredValues.inAccessible !== "" &&
          data.accessibility.toString() !== this.filteredValues.inAccessible
        ) {
          return false;
        }
        if (
          this.filteredValues.warning !== "" &&
          data.warningStatus.toString() !== this.filteredValues.warning
        ) {
          return false;
        }
        return true;
      }
    };
    return myFilterPredicate;
  }

  filterChanged(event, field) {
    this.filteredValues[field] = event;
    this.dataSource.filter = JSON.stringify(this.filteredValues);
  }

  getAllAeds() {
    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          if (this.paginator.pageSize === undefined) {
            this.paginator.pageSize = 10;
          }

          return this.aedService.getAgencyAeds(
            this.paginator.pageIndex,
            this.paginator.pageSize
          );
        }),
        map((data: any) => {
          const transformedData = data.body.aedInfoMonitoringTabDtoList;
          this.resultsLength = data.body.totalCount;
          return transformedData;
        }),
        catchError(() => {
          return observableOf([]);
        })
      )
      .subscribe((data) => {
        data.forEach((item) => {
          item.cabinetStatus = convertToTemporaryDisabled(item.cabinetStatus);
        });

        this.dataSource = new MatTableDataSource(data);
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = this.customFilterPredicate();
      });
  }

  aedSwap(element) {
    const dialogRef = this.dialog.open(AedSwapComponent, {
      height: "340px",
      width: "600px",
      data: {
        aed: element,
      },
      autoFocus: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === "swapped") {
        this.ngOnInit();
      }
    });
  }
  aedDisabled(element) {
    const dialogRef = this.dialog.open(AEDDisableComponent, {
      height: "621px",
      width: "501px",
      data: element,
      panelClass: "custom-modalbox",
      autoFocus: true,
    });
  }

  triggerDetection(element) {
    this.aedService.triggerDetection(element.aedSerialNumber).subscribe(
      (data: any) => {
        element.aedStatus = data.body.aedStatus;
      },
      () => {}
    );
  }

  hasPrivilege(privileges: string[]): boolean {
    return this.authService.hasPrivilege(privileges);
  }

  aedrealTimeReflection(data) {
    let index = this.dataSource.data.findIndex(
      (d) => d.aedSerialNumber == data.aedSerial
    );
    const parsedStatus = JSON.parse(data.status);
    parsedStatus.cabinetStatus = convertToTemporaryDisabled(
      parsedStatus.cabinetStatus
    );

    this.mapValues(parsedStatus, index);

    // this.mapValues(JSON.parse(data.status), index);
  }

  mapValues(data, index) {
    this.dataSource.data[index].accessibility = data.accessibility;
    this.dataSource.data[index].aedStatus = data.aedStatus;
    this.dataSource.data[index].activationStatus = data.armedStatus;
    this.dataSource.data[index].cabinetStatus = data.cabinetStatus;
    this.dataSource.data[index].cabinetType = data.cabinetType;
    this.dataSource.data[index].doorStatus = data.doorStatus;
    this.dataSource.data[index].forceUpdated = data.forceUpdated;
    this.dataSource.data[index].warningStatus = data.warning;

    this.dataSource = new MatTableDataSource(this.dataSource.data);
  }
}
