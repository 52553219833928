import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { AedService } from "../aedservice";
import { AlertService } from "../../core/services/alert.service";
import { ISwapAed } from "src/app/shared/Interface/ISwapAed";

import { Namings,CabinetTypes } from "src/app/namings";
@Component({
  selector: "app-aed-swap",
  templateUrl: "./aed.swap.component.html",
  styleUrls: ["./aed.swap.component.css"],
})
export class AedSwapComponent implements OnInit {
  aeds = [];
  ngxLoading = false;
  swapButtonDisabled = true;
  CheckSwap = false;
  myControl = new FormControl();
  myControl2 = new FormControl();
  filteredOptions: Observable<string[]>;
  options: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<AedSwapComponent>,
    public aedService: AedService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this.filter(value))
    );
    this.ngxLoading = true;
    this.aedService.getAgencyAeds(0, 5000).subscribe(
      (data: any) => {
        this.ngxLoading = false;
        data.body.aedInfoMonitoringTabDtoList.forEach((d) => {

          if (d.cabinetType === CabinetTypes.monitored ) {
            d.cabinetType = CabinetTypes.monitored;
          } else if (d.cabinetType === CabinetTypes.Unmonitored) {
            d.cabinetType = CabinetTypes.Unmonitored;
          }
          if (d.aedSerialNumber != this.data.aed.aedSerialNumber) {
            this.options.push(d.aedSerialNumber);
          }
        });
        this.aeds = data.body.aedInfoMonitoringTabDtoList;
      },
      (err) => {
        this.ngxLoading = false;
        if (err.error.errors[0].errorCode === "AED-0004") {
          this.alertService.showFailure("AED not found for Agency");
        }
      }
    );
  }

  swapClicked() {
    const aed = this.aeds.find(
      (a) => a.aedSerialNumber === this.myControl.value
    );
    let aedObj: ISwapAed = {
      currentAEDId: 0,
      newAEDId: 0,
      currentAEDLastInspectionDate: "",
      newAEDLastInspectionDate: "",
    };

    //Monitored to Non Monitored
    if (
      this.data.aed.cabinetType === CabinetTypes.monitored &&
      aed.cabinetType === CabinetTypes.Unmonitored &&
      this.myControl2.value === null
    ) {
      this.alertService.showFailure(
        "Aed swap failed due to the last inspection date"
      );
      return;
    }

    //Non Monitored to Monitored
    else if (
      aed.cabinetType === CabinetTypes.monitored &&
      this.data.aed.cabinetType === CabinetTypes.Unmonitored &&
      (this.data.aed.inspectionDate === "" ||
        this.data.aed.inspectionDate === null)
    ) {
      this.alertService.showFailure(
        "Aed swap failed due to the last inspection date"
      );
      return;
    } else {
      (aedObj.currentAEDId = this.data.aed.aedId),
        (aedObj.newAEDId = aed.aedId);
      aedObj.currentAEDLastInspectionDate = this.convertDateToISO(
        this.data.aed.inspectionDate
      );
      aedObj.newAEDLastInspectionDate =
        this.myControl2.value == null ? "" : this.myControl2.value;
    }
    this.ngxLoading = true;
    this.aedService.swapAeds(aedObj).subscribe(
      () => {
        this.ngxLoading = false;
        this.alertService.showSuccess(
          `Aed ${this.data.aed.aedSerialNumber} successfully swapped with ${aed.aedSerialNumber}.`
        );
        this.dialogRef.close("swapped");
      },
      () => {
        this.alertService.showFailure("Aed swap failed");
      }
    );
  }

  private filter(value: string): string[] {
    const aed = this.aeds.find((a) => a.name === value);
    if (aed) {
      this.swapButtonDisabled = false;
    } else {
      this.swapButtonDisabled = true;
    }
    const filterValue = value.toLowerCase();

    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  onSelectionChange(event) {
    this.CheckSwap = false;
    const aed = this.aeds.find((a) => a.aedSerialNumber === event.option.value);
    if (
      aed.cabinetType === CabinetTypes.Unmonitored &&
      this.data.aed.cabinetType === CabinetTypes.monitored
    ) {
      this.CheckSwap = true;
    }
  }

  convertDateToISO(date) {
    let currentDate = new Date(date);
    return currentDate.toISOString();
  }
}
